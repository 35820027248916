import React from "react";
// eslint-disable-next-line import/no-restricted-paths
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar";
import PosTitle from "../../../../Components/Settings/Pos/PosTitle";

const PosSettingsLayout = ({ children, activeMenu }) => {
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  return (
    <div className="main protected">
      <div id="content" className="content-pos-dashboard">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="business-setion memberWalletOuter">
            <PosTitle
              history={history}
              settingsLang={languageData.settings}
              globalLang={languageData.global}
              activeMenu={activeMenu}
            />
            <div className="setting-search-outer border-bottom no-padding">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosSettingsLayout;
