import { matchPath } from "react-router";
import { SETTINGS_ROUTES } from "../../../../../consts/routes";
import { isNullish } from "../../../../../utilities/general";
import { MULTI_QUESTION_LOGIC_JUMP_TYPES } from "../../../../../api/questionnaires/config";

export function getMultiQuestionnairesPathParams() {
  const match = matchPath(window.location.pathname, {
    path: SETTINGS_ROUTES.questionnaires.multi.edit(":id"),
    exact: true,
    strict: false,
  });

  return {
    questionnaireId: match?.params?.id ? Number(match.params.id) : null,
  };
}

export function getIsSomeLogicJumpsFilled(nodes = []) {
  return nodes.some(
    (node) =>
      !isNullish(node.ifSelectedChoiceIndex) ||
      !isNullish(node.thenQuestionIndex) ||
      !isNullish(node.elseQuestionIndex),
  );
}

export function getIsAllLogicJumpsFilled(nodes) {
  const jumpNodes = nodes.filter(
    (n) => n.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.jump,
  );
  const elseNodes = nodes.filter(
    (n) => n.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.else,
  );
  const isAllJumpNodesFilled = jumpNodes.every(
    (n) =>
      !isNullish(n.ifSelectedChoiceIndex) && !isNullish(n.thenQuestionIndex),
  );
  const isAllElseNodesFilled = elseNodes.every(
    (n) => !isNullish(n.elseQuestionIndex),
  );
  return isAllJumpNodesFilled && isAllElseNodesFilled;
}
