import { useEditProductPriceListMutation } from "../../../../../../api/mutations/useEditProductPriceListMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { productPricing } from "../../../../../../store/inventory/productPricing";
import { dispatch } from "../../../../../../store/store";

const { actions } = productPricing;

export const useUpdate = (refetch) => {
  const { mutate, isLoading } = useEditProductPriceListMutation({
    onError: () => {
      uiNotification.error("Failed to update price list");
    },
    onSuccess: () => {
      dispatch(actions.setInitialized(false));
      refetch();
      uiNotification.success("Price list updated successfully");
    },
  });

  /**
   * @param {{
   *    productId: string | number;
   *    priceList: Array<object>;
   * }} param0
   */
  const initiate = ({ productId, priceList }) => {
    mutate({
      product_id: Number(productId),
      price_list: priceList || [],
    });
  };

  return {
    initiate,
    isLoading,
  };
};
