import { PATIENT_CONSENTS_ENTITY_TYPES } from "../../api/consents/patientConsents/usePatientConsentsQuery";
import { PATIENT_QUESTIONNAIRE_ENTITY_TYPES } from "../../api/questionnaires/patientQuestionnaires/usePatientQuestionnairesQuery";
import { PROVIDER_ROOM_ROUTES, ROUTES } from "../../consts/routes";

export const getProcedureStatuses = (procedure) => {
  return {
    hasPictures: Boolean(
      procedure.image || procedure.image45 || procedure.isProcedureImage,
    ),

    hasTraceability: procedure.hasTraceability,

    hasNotes: procedure.notesCount > 0,

    hasConsent: procedure.showConsentsBtn > 0,

    hasPaymentTransaction: procedure.posInvoicesCount > 0,

    hasPrescription:
      procedure.prescriptionCount > 0 ||
      procedure.scriptsurePrescription !== null,

    hasQuestionnaire: procedure.showQuestionnaireBtn > 0,
  };
};

export const getProcedureRoutes = ({ procedure, type }) => {
  const common = {
    notesRoute: PROVIDER_ROOM_ROUTES.procedureNotes({
      id: procedure.id,
      patientId: procedure.patientId,
      type,
    }),
    consentRoute: ROUTES.patientConsents.manyByEntity({
      id: procedure.id,
      type: PATIENT_CONSENTS_ENTITY_TYPES.procedure,
    }),
    questionnaireRoute: ROUTES.patientQuestionnaires.manyByEntity({
      id: procedure.id,
      type: PATIENT_QUESTIONNAIRE_ENTITY_TYPES.procedure,
    }),
    prescriptionRoute: PROVIDER_ROOM_ROUTES.procedurePrescription({
      id: procedure.id,
      type,
    }),
  };

  return {
    cosmetic: {
      ...common,

      infoRoute: PROVIDER_ROOM_ROUTES.procedureDetail({
        id: procedure.id,
        type,
      }),

      viewQuestionnaireRoute: ROUTES.createEditCosmeticProcedure({
        actionType: "clients",
        resourceType: "edit",
        procedureId: procedure.id,
        clientId: procedure.patientId,
        type: "profile",
      }),
    },
    health: {
      ...common,

      infoRoute: PROVIDER_ROOM_ROUTES.procedureHealthDetail({
        id: procedure.id,
        type,
      }),

      viewQuestionnaireRoute: ROUTES.createEditHealthProcedure({
        actionType: "clients",
        resourceType: "edit",
        procedureId: procedure.id,
        clientId: procedure.patientId,
        type: "profile",
        isPrescribed: false,
      }),
    },
  };
};
