import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  appointmentId: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  clinic: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .required(),
  provider: yup
    .object({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .required(),
  patientId: yup.number().nullable(),
  appointmentType: yup.string().required(),
  duration: yup.number().required(),
  date: yup.string().required(),
  isOutOfOffice: yup.bool().required(),
});

const composeResponse = (res) => {
  const data = res?.data?.data?.appointment_details || {};

  return {
    id: data.id,
    clinic: {
      id: data.clinic?.id,
      name: data.clinic?.clinic_name,
    },
    provider: {
      id: data.provider?.id,
      name: data.provider?.full_name,
    },
    patientId: data.patient_id || null,
    appointmentType: data.appointment_type,
    duration: data.duration,
    date: data.appointment_datetime,
    isOutOfOffice: Boolean(data.out_of_office),
  };
};

export function createAppointmentQueryKey(appointmentId) {
  return [QUERY_KEYS.appointment, appointmentId];
}

export function useAppointmentQuery({ payload, options = {} }) {
  return useQuery(
    createAppointmentQueryKey(payload.appointmentId),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.getAppointment(req.appointmentId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
