import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import DragAndDrop from "../../Consents/DragAndDrop";
import { useQuestionnairesQuery } from "../../../../../api/questionnaires/useQuestionnairesQuery";
import { Empty } from "../../../../../shared/Empty/Empty";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { history } from "../../../../../history";
import classes from "./QuestionnairesList.module.scss";
import { useDebouncedState } from "../../../../../utilities/hooks/useDebouncedState";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { QUESTIONNAIRE_TYPES } from "../../../../../consts/api";

const questionsFieldByListType = {
  [QUESTIONNAIRE_TYPES.simple]: "simple_questions",
  [QUESTIONNAIRE_TYPES.multi]: "multi_questions",
};

export function QuestionnairesList({ searchTerm, type }) {
  const { tCommon } = useAppTranslation.Common();
  const debouncedSearch = useDebouncedState(searchTerm);
  const search = debouncedSearch[1];
  const setSearch = debouncedSearch[2];

  const { data, isLoading } = useQuestionnairesQuery({
    payload: {
      type,
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      onError: () => {
        uiNotification.error(tCommon("error.fetchQuestionnaires"));
      },
    },
  });

  const handleEdit = (id) => {
    if (type === QUESTIONNAIRE_TYPES.multi) {
      history.push(`/settings/procedure-template/${id}/edit`);
    } else {
      history.push(`/settings/questionnaires/${id}/edit`);
    }
  };

  const preparedQuestionnaires = useMemo(() => {
    const filtered =
      data?.filter((i) =>
        i.title.toLowerCase().includes(search?.toLowerCase()),
      ) || [];
    return filtered.map((i) => ({
      id: i.id,
      consultationTitle: i.title,
      questionsCount: i?.[questionsFieldByListType[type]]?.length || 0,
    }));
  });

  const isEmpty = preparedQuestionnaires.length === 0 && !isLoading;
  const isVisible = !isLoading && !isEmpty;

  useEffect(() => {
    setSearch((prev) => {
      if (prev !== searchTerm) {
        return searchTerm;
      }
      return prev;
    });
  }, [searchTerm]);

  return (
    <>
      {isLoading && (
        <tr className={classes.skeletonWrap}>
          <div className={classes.skeleton}>
            <Skeleton count={15} height={51} borderRadius="0px" />
          </div>
        </tr>
      )}
      {isEmpty && (
        <tr className={classes.emptyWrap}>
          <Empty position="center" className={classes.empty} />
        </tr>
      )}
      {isVisible && (
        <DragAndDrop
          list={preparedQuestionnaires}
          module="Questionnaire"
          editUrl={handleEdit}
        />
      )}
    </>
  );
}

QuestionnairesList.propTypes = {
  searchTerm: PropTypes.string,
  type: PropTypes.oneOf(Object.values(QUESTIONNAIRE_TYPES)).isRequired,
};

QuestionnairesList.defaultProps = {
  searchTerm: null,
};
