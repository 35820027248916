import React from "react";
import PosSettingsLayout from "../PosSettingsLayout";
import { Box } from "../../../../../shared/Box/Box";
import Table from "./components/Table";
import Header from "./components/Header";
import { useReport } from "./hooks/useReport";
import ReportLayout from "./components/ReportLayout";
import { useDownloadStripeDocument } from "./hooks/useDownloadStripeDocument";

const StripeDocuments = () => {
  const { report, headerForm } = useReport();
  const { selectedDocuments, mutation } = useDownloadStripeDocument();
  return (
    <PosSettingsLayout activeMenu="stripe-documents">
      <Box>
        <ReportLayout>
          <Header
            headerForm={headerForm}
            selectedDocuments={selectedDocuments}
            download={mutation}
          />
          <Table report={report} selectedDocuments={selectedDocuments} />
        </ReportLayout>
      </Box>
    </PosSettingsLayout>
  );
};

export default StripeDocuments;
