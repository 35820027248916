import React from "react";
import cx from "clsx";
import { withRouter } from "react-router";
import { InputSearch } from "../../../../../../boxes/InputSearch/InputSearch";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../../shared/Select/Select";
import { Button } from "../../../../../../shared/Button/Button";
import styles from "../scss/stripeDocuments.module.scss";
import { DateRangePicker } from "../../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { SETTINGS_ROUTES } from "../../../../../../consts/routes";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";

const Header = ({ history, headerForm, selectedDocuments, download }) => {
  const { data: user } = useCurrentUserQuery();

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerFiltersContainer}>
        <div className={styles.inputGroup}>
          <InputLabel className={styles.inputLabel}>Name</InputLabel>
          <InputSearch
            value={headerForm.name.value}
            onChange={headerForm.name.onChange}
            placeholder="Search by Name..."
            className={cx(styles.inputSize)}
          />
        </div>
        <div className={styles.inputGroup}>
          <InputLabel className={styles.inputLabel}>Document Type:</InputLabel>
          <Select
            size="small"
            options={headerForm.type.options}
            className={cx(styles.select, styles.inputSize)}
            value={headerForm.type.value}
            onChange={headerForm.type.onChange}
          />
        </div>
        <div className={styles.inputGroup}>
          <InputLabel className={styles.inputLabel}>Clinic</InputLabel>
          <Select
            size="small"
            options={headerForm.clinic.options}
            className={cx(styles.select, styles.inputSize)}
            value={headerForm.clinic.value}
            onChange={headerForm.clinic.onChange}
            isSearchable
          />
        </div>
        <div className={styles.inputGroup}>
          <InputLabel className={styles.inputLabel}>Period</InputLabel>
          <DateRangePicker
            size="small"
            dateFormat={user?.dateFormat}
            buttonClassName={styles.inputSize}
            onChange={({ startDate, endDate }) => {
              headerForm.period.onChange({
                startDate: startDate,
                endDate: endDate,
              });
            }}
            value={{
              startDate: headerForm.period.value.startDate,
              endDate: headerForm.period.value.endDate,
            }}
          />
        </div>
      </div>
      <div className={styles.headerFiltersContainer}>
        <Button
          size={"small"}
          className={cx(styles.addFileButton, styles.inputSize)}
          onClick={() => history.push(SETTINGS_ROUTES.addPosStripeDocuments())}
        >
          Add File
        </Button>
        {Boolean(selectedDocuments.value.length) && (
          <Button
            size={"small"}
            className={cx(styles.addFileButton, styles.inputSize)}
            onClick={() =>
              selectedDocuments.value.forEach((document) => {
                download.mutate({ document_id: document });
              })
            }
            isDisabled={download.isLoading}
            leftAdornment={
              download.isLoading ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
          >
            {`Download (${selectedDocuments.value.length}) `}
          </Button>
        )}
      </div>
    </div>
  );
};

export default withRouter(Header);
