import { useFormik } from "formik";
import moment from "moment";
import { useMemo, useState } from "react";
import { useStripeDocuments } from "./useStripeDocuments";
import { API_DATE_FORMAT } from "../../../../../../consts/api";
import { useDebouncedState } from "../../../../../../utilities/hooks/useDebouncedState";
import { useClinicsQuery } from "../../../../../../api/queries/useClinicsQuery";

export const useReport = () => {
  const [period, setPeriod] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [search, debouncedSearch, setSearch] = useDebouncedState("");

  const formik = useFormik({
    initialValues: {
      type: "",
      clinic: "",
    },
  });

  const preparedParams = {
    file_type: formik.values.type.value || "",
    clinic_id: formik.values.clinic.value || "",
    from_date: moment(period.startDate).format(API_DATE_FORMAT),
    to_date: moment(period.endDate).format(API_DATE_FORMAT),
    search: debouncedSearch,
  };

  const { data, isLoading, refetch } = useStripeDocuments(preparedParams);
  const { data: clinics } = useClinicsQuery();

  const documentsList = data?.data?.data?.documents || [];
  const typeOptions = useMemo(() => {
    return Object.entries(data?.data?.data?.file_types || {}).map(
      ([value, label]) => ({
        value,
        label,
      }),
    );
  }, [data]);

  const clinicsOptions = useMemo(() => {
    return clinics?.map((clinic) => ({ value: clinic.id, label: clinic.name }));
  }, [clinics]);

  return {
    report: {
      list: documentsList,
      isLoading,
      refetch,
    },
    headerForm: {
      name: {
        value: search,
        onChange: setSearch,
      },
      type: {
        value: formik.values.type,
        onChange: (value) => formik.setFieldValue("type", value),
        options: typeOptions || [],
      },
      clinic: {
        value: formik.values.clinic,
        onChange: (value) => formik.setFieldValue("clinic", value),
        options: clinicsOptions || [],
      },
      period: {
        value: period,
        onChange: setPeriod,
      },
    },
  };
};
