import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  providerId: yup.number().required(),
});

const responseSchema = yup.object({
  clinics: yup.array().of(
    yup.object({
      id: yup.number().required(),
      name: yup.string().required(),
    }),
  ),
  defaultClinicId: yup.number().nullable(),
});

const composeResponse = (res) => {
  const clinics = res?.data?.data?.all_clinics || [];
  const defaultClinicId = res?.data?.data?.default_clinic_id || null;
  return {
    clinics: clinics.map((c) => ({
      id: c.id,
      name: c.clinic_name,
    })),
    defaultClinicId,
  };
};

export function createProviderAssociatedClinicsQueryKey({ providerId }) {
  return [QUERY_KEYS.providerAssociatedClinics, providerId];
}

export function useProviderAssociatedClinicsQuery({
  payload,
  options = {},
} = {}) {
  return useQuery(
    createProviderAssociatedClinicsQueryKey({ providerId: payload.providerId }),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.getProviderAssociatedClinics(req.providerId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
