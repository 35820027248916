import { useEffect } from "react";
import { DEFAULT_MEMBERSHIP } from "../../../../../../store/inventory/productPricing/config";
import { dispatch } from "../../../../../../store/store";
import { productPricing } from "../../../../../../store/inventory/productPricing";

const { actions } = productPricing;

function preparePrices({ clinics, pricePerClinic }) {
  return (
    clinics?.reduce((carry, clinic) => {
      const prices = pricePerClinic?.find((x) => x.clinic_id === clinic.id);

      if (prices) {
        return {
          ...carry,
          [clinic.id]: {
            perUnit: prices.price_per_unit,
            member: prices.price_per_unit_members,
            membershipId: prices.membership_tier_id,
          },
        };
      }

      return carry;
    }, {}) || []
  );
}

function prepareMemberships(tiers) {
  const options =
    tiers?.map((tier) => ({
      label: tier.tier_name,
      value: tier.id,
    })) || [];

  return [DEFAULT_MEMBERSHIP, ...options];
}

export function useInit(initData, isInitialized) {
  const { clinics, membership_tiers, product } = initData || {
    clinics: [],
    membership_tiers: [],
  };

  useEffect(() => {
    if (!isInitialized) {
      dispatch(actions.setMemberships(prepareMemberships(membership_tiers)));

      dispatch(
        actions.setPrices(
          preparePrices({
            clinics,
            pricePerClinic: product?.product_price_per_clinic,
          }),
        ),
      );

      dispatch(
        actions.setClinics(
          clinics.map((c) => ({
            id: c.id,
            name: c.clinic_name,
          })),
        ),
      );

      dispatch(actions.setInitialized(true));
    }
  }, [
    clinics,
    product?.product_price_per_clinic,
    isInitialized,
    membership_tiers,
  ]);

  return { clinics };
}
