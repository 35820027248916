import { MULTI_QUESTION_LOGIC_JUMP_TYPES } from "../../../../../api/questionnaires/config";
import { isNullish } from "../../../../../utilities/general";

export function getAlreadySelectedChoiceIndexes(nodes) {
  return nodes
    .filter((n) => n.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.jump)
    .map((n) => n.ifSelectedChoiceIndex)
    .filter((v) => !isNullish(v));
}

export function choiceToSelectOption(choice, index) {
  return {
    label: String(choice.value),
    value: index,
  };
}

export function getAlreadySelectedQuestionIndexes(nodes) {
  return nodes
    .reduce(
      (carry, node) => [
        ...carry,
        node.thenQuestionIndex,
        node.elseQuestionIndex,
      ],
      [],
    )
    .filter((v) => !isNullish(v));
}

export function questionToSelectOptions(question, index) {
  return {
    label: question.question,
    value: index,
  };
}

export function filterOptionByLabelPredicate(option) {
  return Boolean(option.label?.trim());
}
