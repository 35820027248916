import * as yup from "yup";
import { useMemo } from "react";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { getCosmeticPathParams } from "../CosmeticCreateEdit.utils";
import { useProcedureQuery } from "../../../../../../api/procedure/useProcedureQuery";
import { useChartingFilterCategoriesQuery } from "../../../../../../api/queries/useChartingFilterCategoriesQuery";
import { useExtendedFormik } from "../../../../../../hooks/useExtendedFormik";
import {
  extractDocumentIdsFromOptions,
  getNextServiceOptionOnApptChange,
  prepareProcedureDocumentIds,
  prepareSubmitValues,
  getNextDocumentsFromServiceOption,
  initDedupForProcedureDocuments,
  filterNextRequiredDocuments,
} from "../../utilities";
import { DOC_DEDUP_TYPE } from "../../../../../../store/patients/mutateProcedure/config";
import { PROCEDURE_AREA } from "../shared/Info/Info.consts";

function validateImages(formValues) {
  const images = [
    formValues.frontImage,
    formValues.image45,
    formValues.image45Left,
    formValues.image45Right,
    formValues.image90,
    formValues.image90Left,
    formValues.image90Right,
    formValues.afterFrontImage,
    formValues.afterImage45Left,
    formValues.afterImage45Right,
    formValues.afterImage90Left,
    formValues.afterImage90Right,
    formValues.backImage,
    formValues.back45LeftImage,
    formValues.back45RightImage,
    formValues.afterBackImage,
    formValues.afterBack45LeftImage,
    formValues.afterBack45RightImage,
  ];

  const procedureArea = formValues.procedureArea;

  if (
    procedureArea === PROCEDURE_AREA.laser ||
    procedureArea === PROCEDURE_AREA.coolSculpting
  ) {
    if (!images.some(Boolean)) {
      return "createEditCosmeticProcedure.error.imagesRequredCoolsculptingOrLaser";
    }
  } else if (
    ![
      formValues.frontImage,
      formValues.image45Left,
      formValues.image45Right,
    ].every(Boolean)
  ) {
    return "createEditCosmeticProcedure.error.imagesRequired";
  }
}

function useInitialValues() {
  const { tCommon } = useAppTranslation.Common();
  const { procedureId, isEditMode } = getCosmeticPathParams();

  const { data, isFetching } = useProcedureQuery({
    payload: {
      procedureId,
    },
    options: {
      enabled: isEditMode,
      onError: () => {
        uiNotification.error(tCommon("error.fetchProcedure"));
      },
    },
  });

  const chartingFilters = useChartingFilterCategoriesQuery();

  const selectedFilter = chartingFilters?.data?.find(
    (x) => x.id === data?.chartingCategoryId,
  );

  const {
    questionnaireIds,
    consentIds,
    nonDeletableConsentIds,
    nonDeletableQuestionnaireIds,
  } = useMemo(
    () =>
      prepareProcedureDocumentIds(
        data?.patientConsents,
        data?.patientQuestionnaires,
      ),
    [data?.patientConsents, data?.patientQuestionnaires],
  );

  return useMemo(
    () => ({
      data: {
        appointmentId: data?.appointment?.id || null,

        serviceId: data?.serviceId || null,

        providerId: data?.providerId || null,

        clinicId: data?.clinicId || null,

        procedureArea: data?.area || null,

        procedureName: data?.name || "",

        procedureDate: data?.date ? new Date(data.date) : null,

        frontImage: data?.images?.front || null,

        image45: data?.images?.image45 || null,

        image45Left: data?.images?.image45Left || null,

        image45Right: data?.images?.image45Right || null,

        image90: data?.images?.image90 || null,

        image90Left: data?.images?.image90Left || null,

        image90Right: data?.images?.image90Right || null,

        afterFrontImage: data?.afterImages?.front || null,

        afterImage45Left: data?.afterImages?.image45Left || null,

        afterImage45Right: data?.afterImages?.image45Right || null,

        afterImage90Left: data?.afterImages?.image90Left || null,

        afterImage90Right: data?.afterImages?.image90Right || null,

        backImage: data?.images?.back || null,

        back45LeftImage: data?.images?.back45Left || null,

        back45RightImage: data?.images?.back45Right || null,

        afterBackImage: data?.afterImages?.back || null,

        afterBack45LeftImage: data?.afterImages?.back45Left || null,

        afterBack45RightImage: data?.afterImages?.back45Right || null,

        questionnaireIds,

        consentIds,

        type: selectedFilter
          ? {
              label: selectedFilter.name,
              value: selectedFilter.id,
            }
          : null,

        nonDeletableConsentIds,

        nonDeletableQuestionnaireIds,
      },
      isLoading: isFetching,
    }),
    [data, isFetching],
  );
}

export function useForm(onSubmit) {
  const { tClients } = useAppTranslation.Clients();
  const initialValues = useInitialValues();
  const { isEditMode } = getCosmeticPathParams();

  const requiredFields = [
    "serviceId",
    "providerId",
    "clinicId",
    "procedureArea",
    "procedureName",
  ];

  const schema = yup.object({
    appointmentId: yup.number().nullable(),

    serviceId: yup
      .number()
      .when("appointmentId", {
        is: Boolean,
        then: (schema) =>
          schema.required(
            tClients("createEditCosmeticProcedure.formError.serviceRequired"),
          ),
      })
      .nullable(),

    providerId: yup
      .number()
      .nullable()
      .required(
        tClients("createEditCosmeticProcedure.formError.providerRequired"),
      ),

    clinicId: yup
      .number()
      .nullable()
      .required(
        tClients("createEditCosmeticProcedure.formError.clinicRequired"),
      ),

    procedureArea: yup
      .string()
      .nullable()
      .required(
        tClients("createEditCosmeticProcedure.formError.procedureAreaRequired"),
      ),

    procedureName: yup
      .string()
      .required(
        tClients("createEditCosmeticProcedure.formError.procedureNameRequired"),
      ),

    procedureDate: yup.date().nullable(),

    frontImage: yup.string().nullable(),

    image45: yup.string().nullable(),

    image45Left: yup.string().nullable(),

    image45Right: yup.string().nullable(),

    image90: yup.string().nullable(),

    image90Left: yup.string().nullable(),

    image90Right: yup.string().nullable(),

    afterFrontImage: yup.string().nullable(),

    afterImage45Left: yup.string().nullable(),

    afterImage45Right: yup.string().nullable(),

    afterImage90Left: yup.string().nullable(),

    afterImage90Right: yup.string().nullable(),

    backImage: yup.string().nullable(),

    back45LeftImage: yup.string().nullable(),

    back45RightImage: yup.string().nullable(),

    afterBackImage: yup.string().nullable(),

    afterBack45LeftImage: yup.string().nullable(),

    afterBack45RightImage: yup.string().nullable(),

    questionnaireIds: yup.array().of(yup.string()),

    consentIds: yup.array().of(yup.string()),

    type: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable(),

    nonDeletableConsentIds: yup.array().of(yup.string()),

    nonDeletableQuestionnaireIds: yup.array().of(yup.string()),
  });

  const form = useExtendedFormik({
    enableReinitialize: true,
    validationSchema: schema,
    initialValues: initialValues.data,
    onSubmit,
  });

  const initDedup = (ids, type) => {
    if (isEditMode) {
      initDedupForProcedureDocuments(ids, type);
    }
  };

  const setServiceId = (option) => {
    const res = getNextDocumentsFromServiceOption({
      option,
      selectedConsentIds: form.values.consentIds,
      selectedQuestionnaireIds: form.values.questionnaireIds,
      nonDeletableConsentIds: form.values.nonDeletableConsentIds,
      nonDeletableQuestionnaireIds: form.values.nonDeletableQuestionnaireIds,
      appendMode: isEditMode,
    });

    form.setFieldValue("serviceId", res.nextServiceId);
    form.setFieldValue("consentIds", res.nextConsentIds);
    form.setFieldValue("questionnaireIds", res.nextQuestionnaireIds);
    form.setFieldValue(
      "nonDeletableQuestionnaireIds",
      res.nextNonDeletableQuestionnaireIds,
    );
    form.setFieldValue(
      "nonDeletableConsentIds",
      res.nextNonDeletableConsentIds,
    );

    initDedup(res.nextQuestionnaireIds, DOC_DEDUP_TYPE.questionnaire);
    initDedup(res.nextConsentIds, DOC_DEDUP_TYPE.consent);
  };

  return useMemo(
    () => ({
      handleSubmit: async (redirectToFillQuestionnaires) => {
        form.setFormTouched();
        const errors = await form.validateForm(form.values);
        if (Object.values(errors).length === 0) {
          const imageError = validateImages(form.values);

          if (imageError) {
            return uiNotification.error(tClients(imageError));
          }

          return onSubmit(
            prepareSubmitValues(form.values),
            redirectToFillQuestionnaires,
          );
        }
      },

      isError: requiredFields.some((f) => Boolean(form.getError(f))),

      isLoading: initialValues.isLoading,

      appointmentId: {
        value: form.values.appointmentId,
        onChange: (option, serviceOptions = []) => {
          const nextServiceOption = getNextServiceOptionOnApptChange(
            option,
            serviceOptions,
          );

          form.setFieldValue("appointmentId", option?.value || null);
          setServiceId(nextServiceOption);
          if (option) {
            form.setFieldValue("providerId", option.providerId);
            form.setFieldValue("clinicId", option.clinicId);
          }
        },
      },

      serviceId: {
        value: form.values.serviceId,
        onChange: setServiceId,
        getError: () => form.getError("serviceId"),
      },

      providerId: {
        value: form.values.providerId,
        onChange: (option) => {
          form.setFieldValue("providerId", option?.value || null);
        },
        getError: () => form.getError("providerId"),
      },

      clinicId: {
        value: form.values.clinicId,
        onChange: (option) => {
          form.setFieldValue("clinicId", option?.value || null);
        },
        validateWithProviderClinics: (providerClinicOptions = []) => {
          if (
            !providerClinicOptions.find((o) => o.value === form.values.clinicId)
          ) {
            form.setFieldValue("clinicId", null);
          }
        },
        getError: () => form.getError("clinicId"),
      },

      procedureArea: {
        value: form.values.procedureArea,
        onChange: (option) => {
          form.setFieldValue("procedureArea", option?.value || null);
        },
        getError: () => form.getError("procedureArea"),
      },

      procedureName: {
        value: form.values.procedureName,
        onChange: (next) => form.setFieldValue("procedureName", next),
        getError: () => form.getError("procedureName"),
      },

      procedureDate: {
        value: form.values.procedureDate,
        onChange: (next) => form.setFieldValue("procedureDate", next),
      },

      frontImage: {
        value: form.values.frontImage,
        onChange: (next) => form.setFieldValue("frontImage", next),
      },

      image45: {
        value: form.values.image45,
        onChange: (next) => form.setFieldValue("image45", next),
      },

      image45Left: {
        value: form.values.image45Left,
        onChange: (next) => form.setFieldValue("image45Left", next),
      },

      image45Right: {
        value: form.values.image45Right,
        onChange: (next) => form.setFieldValue("image45Right", next),
      },

      image90: {
        value: form.values.image90,
        onChange: (next) => form.setFieldValue("image90", next),
      },

      image90Left: {
        value: form.values.image90Left,
        onChange: (next) => form.setFieldValue("image90Left", next),
      },

      image90Right: {
        value: form.values.image90Right,
        onChange: (next) => form.setFieldValue("image90Right", next),
      },

      afterFrontImage: {
        value: form.values.afterFrontImage,
        onChange: (next) => form.setFieldValue("afterFrontImage", next),
      },

      afterImage45Left: {
        value: form.values.afterImage45Left,
        onChange: (next) => form.setFieldValue("afterImage45Left", next),
      },

      afterImage45Right: {
        value: form.values.afterImage45Right,
        onChange: (next) => form.setFieldValue("afterImage45Right", next),
      },

      afterImage90Left: {
        value: form.values.afterImage90Left,
        onChange: (next) => form.setFieldValue("afterImage90Left", next),
      },

      afterImage90Right: {
        value: form.values.afterImage90Right,
        onChange: (next) => form.setFieldValue("afterImage90Right", next),
      },

      backImage: {
        value: form.values.backImage,
        onChange: (next) => form.setFieldValue("backImage", next),
      },

      back45LeftImage: {
        value: form.values.back45LeftImage,
        onChange: (next) => form.setFieldValue("back45LeftImage", next),
      },

      back45RightImage: {
        value: form.values.back45RightImage,
        onChange: (next) => form.setFieldValue("back45RightImage", next),
      },

      afterBackImage: {
        value: form.values.afterBackImage,
        onChange: (next) => form.setFieldValue("afterBackImage", next),
      },

      afterBack45LeftImage: {
        value: form.values.afterBack45LeftImage,
        onChange: (next) => form.setFieldValue("afterBack45LeftImage", next),
      },

      afterBack45RightImage: {
        value: form.values.afterBack45RightImage,
        onChange: (next) => form.setFieldValue("afterBack45RightImage", next),
      },

      questionnaireIds: {
        value: form.values.questionnaireIds,
        onChange: (options) => {
          form.setFieldValue(
            "questionnaireIds",
            extractDocumentIdsFromOptions(options),
          );
        },
        appendAsNonDeletable: (values) => {
          const filteredValues = filterNextRequiredDocuments(
            values,
            form.values.questionnaireIds,
          );

          const res = getNextDocumentsFromServiceOption({
            option: {
              value: form.values.serviceId,
              questionnaireIds: filteredValues.map((v) => v.templateId),
              consentIds: [],
            },
            selectedConsentIds: [],
            selectedQuestionnaireIds: form.values.questionnaireIds,
            nonDeletableConsentIds: [],
            nonDeletableQuestionnaireIds:
              form.values.nonDeletableQuestionnaireIds,
            appendMode: isEditMode,
          });

          form.setFieldValue("questionnaireIds", res.nextQuestionnaireIds);
          form.setFieldValue(
            "nonDeletableQuestionnaireIds",
            res.nextNonDeletableQuestionnaireIds,
          );

          initDedup(res.nextQuestionnaireIds, DOC_DEDUP_TYPE.questionnaire);
        },
        replaceRawIds: (ids) => {
          form.setFieldValue("questionnaireIds", ids);
        },
        isDeletable: (id) =>
          !form.values.nonDeletableQuestionnaireIds.includes(String(id)),
      },

      consentIds: {
        value: form.values.consentIds,
        onChange: (options) => {
          form.setFieldValue(
            "consentIds",
            extractDocumentIdsFromOptions(options),
          );
        },
        appendAsNonDeletable: (values) => {
          const filteredValues = filterNextRequiredDocuments(
            values,
            form.values.consentIds,
          );

          const res = getNextDocumentsFromServiceOption({
            option: {
              value: form.values.serviceId,
              questionnaireIds: [],
              consentIds: filteredValues.map((v) => v.templateId),
            },
            selectedConsentIds: form.values.consentIds,
            selectedQuestionnaireIds: [],
            nonDeletableConsentIds: form.values.nonDeletableConsentIds,
            nonDeletableQuestionnaireIds: [],
            appendMode: isEditMode,
          });

          form.setFieldValue("consentIds", res.nextConsentIds);
          form.setFieldValue(
            "nonDeletableConsentIds",
            res.nextNonDeletableConsentIds,
          );

          initDedup(res.nextConsentIds, DOC_DEDUP_TYPE.consent);
        },
        replaceRawIds: (ids) => {
          form.setFieldValue("consentIds", ids);
        },
        isDeletable: (id) =>
          !form.values.nonDeletableConsentIds.includes(String(id)),
      },

      type: {
        value: form.values.type,
        onChange: (next) => form.setFieldValue("type", next),
      },
    }),
    [form.handleSubmit, form.values, initialValues.isLoading],
  );
}
