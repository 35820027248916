import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken,
  setConfigData,
  handleInvalidToken,
  positionFooterCorrectly,
  setToken,
  resolveServerSideMessage,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";
import { queryClient } from "../../api/queryClient.js";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api.js";
import { lockAppUntilAccountAddressUpdated } from "../../utilities/localStorage.js";

const languageData = JSON.parse(localStorage.getItem("languageData"));
const settingInstance = axios.create();

settingInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

settingInstance.interceptors.response.use(
  function (response) {
    if (response.headers.access_token) {
      if (getToken() !== response.headers.access_token) {
        setToken(response.headers.access_token);
      }
    }

    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

settingInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function fetchUsers(formData) {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "users", formData ? formData : "")
      .then((response) => {
        dispatch({ type: "USERS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "USERS_LIST", payload: error.response.data });
      });
  };
}

export function getUser(userId, mode) {
  return async (dispatch) => {
    return settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "users/" +
          userId +
          "?scopes=clinics,privileges" +
          (mode == "profile" ? "&request_type=profile" : ""),
      )
      .then((response) => {
        if (mode == "profile") {
          dispatch({ type: "PROFILE_GET", payload: response.data });
        } else {
          dispatch({ type: "USER_GET", payload: response.data });
        }
        return response.data;
      })
      .catch((error) => {
        if (mode == "profile") {
          dispatch({ type: "PROFILE_GET", payload: error.response.data });
        } else {
          dispatch({ type: "USER_GET", payload: error.response.data });
        }
        return Promise.reject(error.response.data);
      });
  };
}

export function deleteUser(delUserId, formData) {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "delete-user/" + delUserId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "USER_DELETE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "USER_DELETE", payload: error.response.data });
      });
  };
}

export function userProfile(formData, userId, mode) {
  return async (dispatch) => {
    return settingInstance
      .patch(process.env.REACT_APP_API_URL + "users/" + userId, formData)
      .then((response) => {
        if (mode == "profile") {
          dispatch({ type: "PROFILE_UPDATE", payload: response.data });
        } else {
          dispatch({ type: "USER_UPDATE", payload: response.data });
        }
        return response.data;
      })
      .catch((error) => {
        if (mode == "profile") {
          dispatch({ type: "PROFILE_UPDATE", payload: error.response.data });
        } else {
          dispatch({ type: "USER_UPDATE", payload: error.response.data });
        }
        return Promise.reject(error.response.data);
      });
  };
}

export function postEmailUpdate(formData) {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL + "post-treatment-email",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "POST_TREATMENT_EMAIL_UPDATE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "POST_TREATMENT_EMAIL_UPDATE",
          payload: error.response.data,
        });
      });
  };
}

export function preEmailUpdate(formData) {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL + "pre-treatment-email",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "PRE_TREATMENT_EMAIL_UPDATE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PRE_TREATMENT_EMAIL_UPDATE",
          payload: error.response.data,
        });
      });
  };
}

export function updateSortOrder(formData, type) {
  return (dispatch) => {
    settingInstance
      .put(process.env.REACT_APP_API_URL + "update/row/order/" + type, formData)
      .then((response) => {
        dispatch({
          type: "SORT_ORDER_UPDATE",
          payload: response.data,
          sortType: type,
          sortIds: formData.object_ids ? formData.object_ids : formData,
        });
        dispatch(exportEmptyData({}));
      })
      .catch((error) => {
        dispatch({
          type: "SORT_ORDER_UPDATE",
          payload: error.response.data,
          sortType: null,
          sortIds: null,
        });
        dispatch(exportEmptyData({}));
      });
  };
}

export function fetchPosttreatmentInstructions(formData) {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "post-treatment-instructions",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function fetchPretreatmentInstructions(formData) {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pre-treatment-instructions",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function uploadImage(formData) {
  return (dispatch) => {
    settingInstance
      .post(process.env.REACT_APP_API_URL + "media/upload", formData)
      .then((response) => {
        dispatch({ type: "FILE_UPLOADED", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FILE_UPLOADED", payload: error.response.data });
      });
  };
}

export function getPostTreatmentEmail(formData) {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "post-treatment-email", formData)
      .then((response) => {
        dispatch({ type: "POST_TREATMENT_EMAIL_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POST_TREATMENT_EMAIL_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getAccountDetails(accountID, scopes) {
  return async (dispatch) => {
    return settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "accounts/" +
          accountID +
          "?scopes=" +
          scopes,
      )
      .then((response) => {
        dispatch({ type: "ACCOUNT_GET", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "ACCOUNT_GET", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function updateAccountDetails(formData, accountID) {
  return async (dispatch) => {
    return settingInstance
      .put(process.env.REACT_APP_API_URL + "accounts/" + accountID, formData)
      .then((response) => {
        dispatch({ type: "ACCOUNT_PUT", payload: response.data });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.account],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.user],
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "ACCOUNT_PUT", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function fetchRolePermissions(roleId) {
  return async (dispatch) => {
    return settingInstance
      .get(process.env.REACT_APP_API_URL + "user-role/" + roleId)
      .then((response) => {
        dispatch({ type: "PRIVILEGE_LIST", payload: response.data });
        return response;
      })
      .catch((error) => {
        dispatch({ type: "PRIVILEGE_LIST", payload: error.response.data });
      });
  };
}

export function saveRolePrivileges(formData) {
  return (dispatch) => {
    settingInstance
      .put(process.env.REACT_APP_API_URL + "user-role", formData)
      .then((response) => {
        dispatch({ type: "PRIVILEGE_UPDATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PRIVILEGE_UPDATE", payload: error.response.data });
      });
  };
}

export function getAppointment() {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "appointments/setting/email_sms")
      .then((response) => {
        dispatch({ type: "APPOINTMENT_GET", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "APPOINTMENT_GET", payload: error.response.data });
      });
  };
}

export function getPreTreatmentEmail(formData) {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "pre-treatment-email", formData)
      .then((response) => {
        dispatch({ type: "PRE_TREATMENT_EMAIL_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PRE_TREATMENT_EMAIL_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getAppointmentReminder(formData) {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "appointments/setting/appointment-reminders",
        formData,
      )
      .then((response) => {
        dispatch({ type: "APPOINTMENT_REMINDER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "APPOINTMENT_REMINDER",
          payload: error.response.data,
        });
      });
  };
}

export function deleteAppointmentReminder(userId) {
  return (dispatch) => {
    settingInstance
      .delete(
        process.env.REACT_APP_API_URL +
          "appointments/setting/appointment-reminders/" +
          userId,
      )
      .then((response) => {
        dispatch({
          type: "DELETE_APPOINTMENT_REMINDER",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_APPOINTMENT_REMINDER",
          payload: error.response.data,
        });
      });
  };
}

export function updateAppointmentReminder(formData, reminderId) {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL +
          "appointments/setting/appointment-reminders/" +
          reminderId,
        formData,
      )
      .then((response) => {
        dispatch({
          type: "UPDATE_APPOINTMENT_REMINDER",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_APPOINTMENT_REMINDER",
          payload: error.response.data,
        });
      });
  };
}

export function createAppointmentReminder(formData) {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL +
          "appointments/setting/appointment-reminders",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "CREATE_APPOINTMENT_REMINDER",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_APPOINTMENT_REMINDER",
          payload: error.response.data,
        });
      });
  };
}

export function getEditAppointmentReminder(reminderId) {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "appointments/setting/appointment-reminders/" +
          reminderId,
      )
      .then((response) => {
        dispatch({
          type: "GET_EDIT_APPOINTMENT_REMINDER",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_EDIT_APPOINTMENT_REMINDER",
          payload: error.response.data,
        });
      });
  };
}

export function uploadBase64Image(formData) {
  return (dispatch) => {
    settingInstance
      .post(process.env.REACT_APP_API_URL + "upload/signature", formData)
      .then((response) => {
        dispatch({ type: "BASE64_UPLOAD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "BASE64_UPLOAD", payload: error.response.data });
      });
  };
}

export const fetchConsents = () => {
  return async (dispatch) => {
    return settingInstance
      .get(process.env.REACT_APP_API_URL + HTTP_ENDPOINTS.consents.getAll())
      .then((response) => {
        dispatch({ type: "CONSENTS_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "CONSENTS_LIST", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
};

export const fetchSelectedConsent = (consentId) => {
  return async (dispatch) => {
    return settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          HTTP_ENDPOINTS.consents.getOne(consentId),
      )
      .then((response) => {
        dispatch({ type: "SELECTED_CONSENTS_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SELECTED_CONSENTS_LIST",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const createConsent = (requestPayload) => {
  return async () => {
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + HTTP_ENDPOINTS.consents.createEditOne(),
        requestPayload,
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  };
};

export const updateSelectedConsent = (requestPayload) => {
  return async () => {
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + HTTP_ENDPOINTS.consents.createEditOne(),
        requestPayload,
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  };
};

export const deleteConsent = (consentId) => {
  return async (dispatch) => {
    return settingInstance
      .delete(
        process.env.REACT_APP_API_URL +
          HTTP_ENDPOINTS.consents.deleteOne(consentId),
      )
      .then((response) => {
        dispatch({ type: "CONSENT_DELETED", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "CONSENT_DELETED", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
};

export function getBookingURL() {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "appointments/setting/booking-urls")
      .then((response) => {
        dispatch({ type: "GET_BOOKING_URL_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_BOOKING_URL_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function updateBookingURL(formData) {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL + "appointments/setting/booking-urls",
        formData,
      )
      .then((response) => {
        dispatch({ type: "UPDATE_BOOKING_URL_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_BOOKING_URL_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getPatientPortal(formData) {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "appointments/setting/patient-portal",
        formData,
      )
      .then((response) => {
        dispatch({ type: "GET_PATIENT_PORTAL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PATIENT_PORTAL", payload: error.response.data });
      });
  };
}

export function updatePatientPortal(formData) {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL + "appointments/setting/patient-portal",
        formData,
      )
      .then((response) => {
        dispatch({ type: "UPDATE_PATIENT_PORTAL", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_PATIENT_PORTAL",
          payload: error.response.data,
        });
      });
  };
}

export function getCancellationPolicy(formData) {
  return async (dispatch) => {
    return settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "appointments/setting/cancellation-policy",
        formData,
      )
      .then((response) => {
        dispatch({ type: "GET_CANCELLATION_POLICY", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_CANCELLATION_POLICY",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function cancellationPolicyStatus(formData) {
  return async (dispatch) => {
    return settingInstance
      .put(process.env.REACT_APP_API_URL + "enable-disable-settings", formData)
      .then((response) => {
        dispatch({
          type: "CANCELLATION_POLICY_STATUS",
          payload: response.data,
        });
        toast.success(languageData.global[response.data.message]);
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "CANCELLATION_POLICY_STATUS",
          payload: error.response.data,
        });
        toast.error(languageData.global[error.response.data.message]);
        return Promise.reject(error.response.data);
      });
  };
}

export function getWebBookingConfiguration(formData) {
  return async (dispatch) => {
    return settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "appointments/setting/web-configuration",
        formData,
      )
      .then((response) => {
        dispatch({ type: "WEB_CONFIGURATION", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "WEB_CONFIGURATION", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function webBookingConfiguration(formData) {
  return async (dispatch) => {
    return settingInstance
      .put(
        process.env.REACT_APP_API_URL +
          "appointments/setting/web-configuration",
        formData,
      )
      .then((response) => {
        dispatch({ type: "WEB_CONFIGURATION", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "WEB_CONFIGURATION", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function activatePatientPortal(formData) {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL +
          "appointments/setting/patient-portal/activate-all",
        formData,
      )
      .then((response) => {
        dispatch({ type: "ACTIVATE_PATIENT_PORTAL", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ACTIVATE_PATIENT_PORTAL",
          payload: error.response.data,
        });
      });
  };
}

export function updateCancellationPolicy(formData) {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL +
          "appointments/setting/cancellation-policy",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "UPDATE_CANCELLATION_POLICY",
          payload: response.data,
        });
        toast.success(languageData.global[response.data.message]);
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_CANCELLATION_POLICY",
          payload: error.response.data,
        });
        toast.error(languageData.global[error.response.data.message]);
      });
  };
}

export function updateAppointment(formData) {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL + "appointments/setting/email_sms",
        formData,
      )
      .then((response) => {
        dispatch({ type: "APPOINTMENT_UPDATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "APPOINTMENT_UPDATE", payload: error.response.data });
      });
  };
}

export function createUser(formData) {
  return (dispatch) => {
    settingInstance
      .post(process.env.REACT_APP_API_URL + "users", formData)
      .then((response) => {
        dispatch({ type: "USER_CREATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "USER_CREATE", payload: error.response.data });
        toast.error(languageData.global[error.response.data.message]);
      });
  };
}

export function fetchSelectMD() {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "user/get-all-mds")
      .then((response) => {
        dispatch({ type: "MDS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "MDS_LIST", payload: error.response.data });
      });
  };
}

export const fetchMDRoomData = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "md-room/get-procedures", formData)
      .then((response) => {
        dispatch({ type: "MDRoom_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "MDRoom_LIST", payload: error.response.data });
      });
  };
};

export const fetchUserRoomData = (id, formData, roomType) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + roomType + "/get-procedures/" + id,
        formData,
      )
      .then((response) => {
        dispatch({ type: "userRoomData_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "userRoomData_LIST", payload: error.response.data });
      });
  };
};

export function createPosttreatmentInstructions(formData) {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "post-treatment-instructions",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "CREATE_POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function fetchSelectedPosttreatmentInstructions(formData, postId) {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "post-treatment-instructions/" + postId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SELECTED_POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SELECTED_POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function updateSelectedPosttreatmentInstructions(formData, postId) {
  return (dispatch) => {
    settingInstance
      .patch(
        process.env.REACT_APP_API_URL + "post-treatment-instructions/" + postId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "UPDATE_POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function createPretreatmentInstructions(formData) {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pre-treatment-instructions",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "CREATE_PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function fetchSelectedPretreatmentInstructions(formData, preId) {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pre-treatment-instructions/" + preId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SELECTED_PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SELECTED_PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function updateSelectedPretreatmentInstructions(formData, preId) {
  return (dispatch) => {
    settingInstance
      .patch(
        process.env.REACT_APP_API_URL + "pre-treatment-instructions/" + preId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "UPDATE_PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function deleteSelectedPretreatmentInstructions(preId) {
  return (dispatch) => {
    settingInstance
      .delete(
        process.env.REACT_APP_API_URL + "pre-treatment-instructions/" + preId,
      )
      .then((response) => {
        dispatch({
          type: "DELETE_PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_PRE_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function deleteSelectedPosttreatmentInstructions(postId) {
  return (dispatch) => {
    settingInstance
      .delete(
        process.env.REACT_APP_API_URL + "post-treatment-instructions/" + postId,
      )
      .then((response) => {
        dispatch({
          type: "DELETE_POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_POST_TREATMENT_INSTRUCTIONS_LIST",
          payload: error.response.data,
        });
      });
  };
}

export const createQuestionnaire = (formData1) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "questionnaires",
        formData1 ? formData1 : "",
      )
      .then((response) => {
        dispatch({ type: "CREATE_QUESTIONNAIRES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_QUESTIONNAIRES",
          payload: error.response.data,
        });
      });
  };
};

export const updateQuestionnaire = (formData1) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "questionnaires",
        formData1 ? formData1 : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_QUESTIONNAIRES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_QUESTIONNAIRES",
          payload: error.response.data,
        });
      });
  };
};

export const updateQuestion = (formData, qId, question_id) => {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL +
          "questionnaires/" +
          qId +
          "/question/" +
          question_id,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_QUESTION", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_QUESTION", payload: error.response.data });
      });
  };
};

export const createQuestion = (formData, id) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "questionnaires/" + id + "/question",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CREATE_QUESTION", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_QUESTION", payload: error.response.data });
      });
  };
};

export const getQuestionnaireById = (id) => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "questionnaires/" + id)
      .then((response) => {
        dispatch({ type: "GET_QUESTIONNAIRE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_QUESTIONNAIRE", payload: error.response.data });
      });
  };
};

export const getQuestionById = (id, question_id) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          `questionnaires/${id}/question/${question_id}`,
      )
      .then((response) => {
        dispatch({ type: "GET_QUESTION", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_QUESTION", payload: error.response.data });
      });
  };
};

export const deleteQuestion = (qId, question_id) => {
  return (dispatch) => {
    settingInstance
      .delete(
        process.env.REACT_APP_API_URL +
          "questionnaires/" +
          qId +
          "/question/" +
          question_id,
      )
      .then((response) => {
        dispatch({ type: "QUESTION_DELETED", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "QUESTION_DELETED", payload: error.response.data });
      });
  };
};

export const deleteQuestionnaire = (qId) => {
  return (dispatch) => {
    settingInstance
      .delete(process.env.REACT_APP_API_URL + "questionnaires/" + qId)
      .then((response) => {
        dispatch({ type: "QUESTIONNAIRE_DELETED", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "QUESTIONNAIRE_DELETED",
          payload: error.response.data,
        });
      });
  };
};

export function saveUserPrivileges(formData) {
  return (dispatch) => {
    settingInstance
      .post(process.env.REACT_APP_API_URL + "user/edit-privilege", formData)
      .then((response) => {
        dispatch({ type: "USER_PRIVILEGE_UPDATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "USER_PRIVILEGE_UPDATE",
          payload: error.response.data,
        });
      });
  };
}

export function deleteProcedureNote(noteID) {
  return (dispatch) => {
    settingInstance
      .delete(process.env.REACT_APP_API_URL + "procedure-notes/" + noteID)
      .then((response) => {
        dispatch({
          type: "SETTING_DELETE_PROCEDURE_NOTE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SETTING_DELETE_PROCEDURE_NOTE",
          payload: error.response.data,
        });
      });
  };
}

export function get2FA(formData) {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "user/2fa", formData)
      .then((response) => {
        dispatch({ type: "USER_2FA_GET", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "USER_2FA_GET", payload: error.response.data });
      });
  };
}

export function disable2FA(formData) {
  return async (dispatch) => {
    return settingInstance
      .patch(process.env.REACT_APP_API_URL + "user/2fa", formData)
      .then((response) => {
        dispatch({ type: "USER_2FA_DISABLED", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "USER_2FA_DISABLED", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function getGoogle2FA(formData) {
  return async (dispatch) => {
    return settingInstance
      .get(process.env.REACT_APP_API_URL + "user/2fa/google", formData)
      .then((response) => {
        dispatch({ type: "GOOGLE_2FA_GET", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GOOGLE_2FA_GET", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function verifyGoogleToken(formData) {
  return async (dispatch) => {
    return settingInstance
      .put(process.env.REACT_APP_API_URL + "user/2fa/google", formData)
      .then((response) => {
        dispatch({ type: "GOOGLE_2FA_VERIFY", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GOOGLE_2FA_VERIFY", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function sendOTP(formData) {
  return async (dispatch) => {
    return settingInstance
      .post(process.env.REACT_APP_API_URL + "user/2fa/sms", formData)
      .then((response) => {
        dispatch({ type: "SEND_OTP", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SEND_OTP", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function verifyMobileOTP(formData) {
  return async (dispatch) => {
    return settingInstance
      .put(process.env.REACT_APP_API_URL + "user/2fa/sms", formData)
      .then((response) => {
        dispatch({ type: "VERIFY_OTP", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "VERIFY_OTP", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function signProcedure(
  formData,
  fetchRows,
  listData,
  roomType,
  pageType,
) {
  fetchRows = fetchRows || false;
  listData = listData || {};
  pageType = pageType || "list";

  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + roomType + "/sign-consents",
        formData,
      )
      .then((response) => {
        if (fetchRows === true) {
          let previousMessage = response.data.message;
          let previousStatus = response.data.status;

          settingInstance
            .get(
              process.env.REACT_APP_API_URL + roomType + "/get-procedures",
              listData,
            )
            .then((responseNew) => {
              responseNew.data.message = previousMessage;
              responseNew.data.status = previousStatus;
              dispatch({ type: "SIGN_PROCEDURE", payload: responseNew.data });
            })
            .catch((error) => {
              dispatch({
                type: "SIGN_PROCEDURE",
                payload: error.response.data,
              });
            });
        } else {
          if (
            roomType &&
            roomType === "provider-room" &&
            pageType === "detail"
          ) {
            response.data.status = 201;
          }
          dispatch({ type: "SIGN_PROCEDURE", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "SIGN_PROCEDURE", payload: error.response.data });
      });
  };
}

export function markUnmarkAsAfterPhotos(
  formData,
  prodeureID,
  listData,
  roomType,
  pageType,
) {
  return (dispatch) => {
    settingInstance
      .patch(
        process.env.REACT_APP_API_URL +
          "provider-room/mark-unmark-as-after-photos/" +
          prodeureID,
        formData,
      )
      .then((response) => {
        let previousMessage = response.data.message;
        let previousStatus = response.data.status;

        let finalUrl =
          pageType && pageType === "list"
            ? `/get-procedures`
            : `/get-procedures/${prodeureID}`;

        settingInstance
          .get(process.env.REACT_APP_API_URL + roomType + finalUrl, listData)
          .then((responseNew) => {
            responseNew.data.message = previousMessage;
            responseNew.data.status = previousStatus;
            dispatch({
              type: "MARK_UNMARK_AFTER_PHOTOS",
              payload: responseNew.data,
            });
          })
          .catch((error) => {
            dispatch({
              type: "MARK_UNMARK_AFTER_PHOTOS",
              payload: error.response.data,
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: "MARK_UNMARK_AFTER_PHOTOS",
          payload: error.response.data,
        });
      });
  };
}

export function hidemarkAsAfter(
  prodeureID,
  listData,
  roomType,
  pageType,
  nextProcedureID,
) {
  return (dispatch) => {
    settingInstance
      .patch(
        process.env.REACT_APP_API_URL +
          "provider-room/hide-procedure/" +
          prodeureID,
      )
      .then((response) => {
        let previousMessage = response.data.message;
        let previousStatus = response.data.status;

        let finalUrl =
          pageType && pageType === "list"
            ? `/get-procedures`
            : `/get-procedures/${nextProcedureID}`;

        settingInstance
          .get(process.env.REACT_APP_API_URL + roomType + finalUrl, listData)
          .then((responseNew) => {
            responseNew.data.message = previousMessage;
            responseNew.data.status =
              pageType && pageType === "list" ? previousStatus : 201;
            dispatch({
              type: "HIDE_MARK_AFTER_PHOTOS",
              payload: responseNew.data,
            });
          })
          .catch((error) => {
            dispatch({
              type: "HIDE_MARK_AFTER_PHOTOS",
              payload: error.response.data,
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: "HIDE_MARK_AFTER_PHOTOS",
          payload: error.response.data,
        });
      });
  };
}

export const fetchClinics = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "clinics", formData ? formData : "")
      .then((response) => {
        dispatch({ type: "CLINIC_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLINIC_LIST", payload: error.response.data });
      });
  };
};

export const fetchProcedureQuestionnaire = (procedureId, formData) => {
  return (dispatch) => {
    let apiUrl =
      process.env.REACT_APP_API_URL +
      "patient-questionnaires/procedure/" +
      procedureId;
    settingInstance
      .get(apiUrl, formData ? formData : "")
      .then((response) => {
        dispatch({ type: "PROCEDURE_QUESTIONNAIRE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PROCEDURE_QUESTIONNAIRE",
          payload: error.response.data,
        });
      });
  };
};

export const fetchProcedureConsents = (consentsId, formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "procedure-consent-details/" +
          consentsId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "PROCEDURE_CONSENTS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PROCEDURE_CONSENTS", payload: error.response.data });
      });
  };
};

export const updateProcedureQuestionnaire = (questionId, formData) => {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL +
          "procedure-questionnarie-details/" +
          questionId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "UPDATE_PROCEDURE_QUESTIONNAIRE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_PROCEDURE_QUESTIONNAIRE",
          payload: error.response.data,
        });
      });
  };
};

export const getSubscriptionInvoices = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "subscription-invoices",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SUBSCRIPTION_INVOICE_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_INVOICE_LIST",
          payload: error.response.data,
        });
      });
  };
};

export const getSubscriptionInvoiceById = (invoiceId, formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "subscription-invoice-details/" +
          invoiceId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SUBSCRIPTION_INVOICE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_INVOICE_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const payInvoiceById = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pay-invoice",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "PAY_LAST_DUE_INVOICE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PAY_LAST_DUE_INVOICE",
          payload: error.response.data,
        });
      });
  };
};

export const getSubscriptionDetails = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "subscription-details",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SUBSCRIPTION_DETAILS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_DETAILS",
          payload: error.response.data,
        });
      });
  };
};

export const subscriptionAddOn = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "add-subscription-addon",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SUBSCRIPTION_ADD_ON", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SUBSCRIPTION_ADD_ON", payload: error.response.data });
      });
  };
};

export const subscriptionCancelReactivate = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "cancel-resume-plan",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_CANCEL_REACTIVATE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_CANCEL_REACTIVATE",
          payload: error.response.data,
        });
      });
  };
};

export const subscriptionUpgradeAccount = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "upgrade-plan",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_UPGRADE_ACCOUNT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_UPGRADE_ACCOUNT",
          payload: error.response.data,
        });
      });
  };
};

export const subscriptionUpdateCard = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "change-suscription-card",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SUBSCRIPTION_UPDATE_CARD", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_UPDATE_CARD",
          payload: error.response.data,
        });
      });
  };
};

export const subscriptionAutoRefill = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "save-autorefill-settings",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SUBSCRIPTION_AUTO_REFILL", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_AUTO_REFILL",
          payload: error.response.data,
        });
      });
  };
};

export const getSubscriptionMonthlyEstimate = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "monthly-to-yearly",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_MONTHLY_TO_YEARLY_ESTIMATE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_MONTHLY_TO_YEARLY_ESTIMATE",
          payload: error.response.data,
        });
      });
  };
};

export const subscriptionUpgradeToYearly = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "monthly-to-yearly",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_MONTHLY_TO_YEARLY_UPGRADE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_MONTHLY_TO_YEARLY_UPGRADE",
          payload: error.response.data,
        });
      });
  };
};

export const getRecentlyDeleted = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "recently-deleted",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "RECENTLY_DELETED_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "RECENTLY_DELETED_LIST",
          payload: error.response.data,
        });
      });
  };
};

export const restoreRecentlyDeleted = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "restore-procedure",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "RECENTLY_DELETED_RESTORE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "RECENTLY_DELETED_RESTORE",
          payload: error.response.data,
        });
      });
  };
};

export const getPosDashboard = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-dashboard",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_DASHBOARD_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "POS_DASHBOARD_DATA", payload: error.response.data });
      });
  };
};

export const getPosDashboardFilter = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-dashboard-charges",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_DASHBOARD_FILTER_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POS_DASHBOARD_FILTER_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const getPosDashboardVerification = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-verification-fields",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "GET_POS_DASHBOARD_VERIFICATION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_POS_DASHBOARD_VERIFICATION",
          payload: error.response.data,
        });
      });
  };
};

export const savePosDashboardVerification = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-save-verification-fields",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SAVE_POS_DASHBOARD_VERIFICATION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_POS_DASHBOARD_VERIFICATION",
          payload: error.response.data,
        });
      });
  };
};

export const getPosPayment = (formData) => {
  return async (dispatch) => {
    return settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-payments",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_PAYMENT_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "POS_PAYMENT_LIST", payload: error.response.data });
      });
  };
};

export const getPosExport = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-export",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_PAYMENT_PAYOUT_EXPORT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POS_PAYMENT_PAYOUT_EXPORT",
          payload: error.response.data,
        });
      });
  };
};

export const getPosPayout = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-payouts",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_PAYOUT_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "POS_PAYOUT_LIST", payload: error.response.data });
      });
  };
};

export const getPosPayoutBank = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-add-payout",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_PAYOUT_BANK_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POS_PAYOUT_BANK_LIST",
          payload: error.response.data,
        });
      });
  };
};

export const saveNewPosPayout = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-add-payout",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_PAYOUT_NEW", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "POS_PAYOUT_NEW", payload: error.response.data });
      });
  };
};

export const getPosPayoutDetail = (payoutId) => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "pos-payout-details/" + payoutId)
      .then((response) => {
        dispatch({ type: "POS_PAYOUT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "POS_PAYOUT_DATA", payload: error.response.data });
      });
  };
};

export const getPosDispute = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-disputes",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_DISPUTE_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "POS_DISPUTE_LIST", payload: error.response.data });
      });
  };
};

export const getPosDisputeSetting = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-disputes-settings",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_DISPUTE_SETTING_GET", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POS_DISPUTE_SETTING_GET",
          payload: error.response.data,
        });
      });
  };
};

export const savePosDisputeSetting = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-disputes-settings",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_DISPUTE_SETTING_POST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POS_DISPUTE_SETTING_POST",
          payload: error.response.data,
        });
      });
  };
};

export const getPosData = (mode) => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + (mode ? `pos/${mode}` : "pos"))
      .then((response) => {
        dispatch({ type: "GET_POS_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_POS_DATA", payload: error.response.data });
      });
  };
};

export const changePosStatus = (formData) => {
  return async (dispatch) => {
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-change-status",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CHANGE_POS_STATUS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "CHANGE_POS_STATUS", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
};

export const changePosConnectionStatus = (formData) => {
  return async (dispatch) => {
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-switch-connection-type",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "CHANGE_POS_CONNECTION_STATUS",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_POS_CONNECTION_STATUS",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const getPosSetupData = () => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "pos-setup-custom-account")
      .then((response) => {
        dispatch({ type: "GET_POS_SETUP_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_POS_SETUP_DATA", payload: error.response.data });
      });
  };
};

export const savePosSetupData = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-setup-custom-account",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SAVE_POS_SETUP_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_POS_SETUP_DATA", payload: error.response.data });
      });
  };
};

export const finalizeStripePosSetup = (accountId, clinicId) => {
  return (dispatch) => {
    dispatch({ type: "FINALIZE_STRIPE_POS_SETUP_LOADING" });

    settingInstance
      .post(
        process.env.REACT_APP_API_URL +
          `pos-setup-custom-finalize/${accountId}/${clinicId}`,
      )
      .then((response) => {
        dispatch({
          type: "FINALIZE_STRIPE_POS_SETUP_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FINALIZE_STRIPE_POS_SETUP_ERROR",
          payload: error.response.data,
        });
      });
  };
};

export const checkPosStripeBalance = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-check-account-balance",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CHECK_POS_STRIPE_BALANCE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CHECK_POS_STRIPE_BALANCE",
          payload: error.response.data,
        });
      });
  };
};

export const disconnectPosStripeAcount = (formData) => {
  return async (dispatch) => {
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-disconnect-account",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "DISCONNENCT_POS_STRIPE_ACCOUNT",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "DISCONNENCT_POS_STRIPE_ACCOUNT",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const getTemplateData = (templateId) => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "questionnaires/" + templateId)
      .then((response) => {
        dispatch({ type: "PROCEDURE_TEMPLATE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PROCEDURE_TEMPLATE_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const saveTemplateData = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(process.env.REACT_APP_API_URL + "questionnaires", formData)
      .then((response) => {
        dispatch({
          type: "SAVE_PROCEDURE_TEMPLATE_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_PROCEDURE_TEMPLATE_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const deleteTemplate = (id) => {
  return (dispatch) => {
    settingInstance
      .delete(process.env.REACT_APP_API_URL + "questionnaires/" + id)
      .then((response) => {
        dispatch({ type: "DELETE_PROCEDURE_TEMPLATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_PROCEDURE_TEMPLATE",
          payload: error.response.data,
        });
      });
  };
};

export const getPosStripeUpdateFields = (stripeUserId) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "pos-update-custom-account/" +
          stripeUserId,
      )
      .then((response) => {
        dispatch({
          type: "GET_POS_STRIPE_ACCOUNT_FIELDS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_POS_STRIPE_ACCOUNT_FIELDS",
          payload: error.response.data,
        });
      });
  };
};

export const updatePosStripeAccountFields = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-update-custom-account",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_POS_STRIPE_ACCOUNT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_POS_STRIPE_ACCOUNT",
          payload: error.response.data,
        });
      });
  };
};

export const getPosPaymentSettingData = () => {
  return async (dispatch) => {
    return settingInstance
      .get(process.env.REACT_APP_API_URL + "pos-payment-settings")
      .then((response) => {
        dispatch({
          type: "GET_POS_PAYMENT_SETTING_DATA",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_POS_PAYMENT_SETTING_DATA",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const getPosBankAccountFields = (clinicId) => {
  return async (dispatch) => {
    return settingInstance
      .get(process.env.REACT_APP_API_URL + "pos-add-bank-account/" + clinicId)
      .then((response) => {
        dispatch({
          type: "GET_POS_BANK_ACCOUNT_FIELDS",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_POS_BANK_ACCOUNT_FIELDS",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const addPosBankAccount = (formData) => {
  return async (dispatch) => {
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-add-bank-account",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "ADD_POS_BANK_ACCOUNT", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "ADD_POS_BANK_ACCOUNT",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const setPosDefaultBnkAccount = (formData) => {
  return async (dispatch) => {
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-set-default-bank",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SET_POS_DEFAULT_BANK_ACCOUNT",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SET_POS_DEFAULT_BANK_ACCOUNT",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const getPosPayoutScheduleData = (clinicId) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-add-payout-schedule/" + clinicId,
      )
      .then((response) => {
        dispatch({
          type: "GET_POS_PAYOUT_SCHEDULE_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_POS_PAYOUT_SCHEDULE_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const updatePosPayoutScheduleData = (formData) => {
  return async (dispatch) => {
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-add-payout-schedule",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "UPDATE_POS_PAYOUT_SCHEDULE_DATA",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_POS_PAYOUT_SCHEDULE_DATA",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const getPosCardReaderData = (clinicId) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "pos-clinic-card-devices/" + clinicId,
      )
      .then((response) => {
        dispatch({ type: "GET_POS_CARD_READER_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_POS_CARD_READER_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const addPosCardReaderMarchent = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-add-merchant",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "ADD_POS_CARD_READER_MERCHENT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_POS_CARD_READER_MERCHENT",
          payload: error.response.data,
        });
      });
  };
};

export const changePosCardReaderStatus = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-disable-stripe-is-swipe",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "CHANGE_POS_CARD_READER_STATUS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_POS_CARD_READER_STATUS",
          payload: error.response.data,
        });
      });
  };
};

export const addPosCardReaderDevice = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-attach-card-reader",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "ADD_POS_CARD_READER_DEVICE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_POS_CARD_READER_DEVICE",
          payload: error.response.data,
        });
      });
  };
};

export const deletePosCardReaderDevice = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-detach-card-reader",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "DELETE_POS_CARD_READER_DEVICE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_POS_CARD_READER_DEVICE",
          payload: error.response.data,
        });
      });
  };
};

export const setupPosExpressAccount = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-create-express-account",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POS_SETUP_EXPRESS_ACCOUNT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POS_SETUP_EXPRESS_ACCOUNT",
          payload: error.response.data,
        });
      });
  };
};

export const getPosStripeDashboardUrl = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-account-dashboard-url",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "GET_POS_STRIPE_DASHBOARD_URL",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_POS_STRIPE_DASHBOARD_URL",
          payload: error.response.data,
        });
      });
  };
};

export const getDefaultUserData = () => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "user/add-user")
      .then((response) => {
        dispatch({ type: "DEFAULT_USERADD_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DEFAULT_USERADD_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const getPosDisputeDetails = (disputeId, isEveidence) => {
  isEveidence = isEveidence | false;

  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "pos-disputes-details/" + disputeId)
      .then((response) => {
        if (isEveidence) {
          dispatch({
            type: "GET_POS_DISPUTE_EVIDENCE",
            payload: response.data,
          });
        } else {
          dispatch({ type: "POS_DISPUTE_DETAIL", payload: response.data });
        }
      })
      .catch((error) => {
        if (isEveidence) {
          dispatch({
            type: "GET_POS_DISPUTE_EVIDENCE",
            payload: error.response.data,
          });
        } else {
          dispatch({
            type: "POS_DISPUTE_DETAIL",
            payload: error.response.data,
          });
        }
      });
  };
};

export const addPosDisputeNote = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-add-dispute-note",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "ADD_POS_DISPUTE_NOTE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_POS_DISPUTE_NOTE",
          payload: error.response.data,
        });
      });
  };
};

export const acceptPosDispute = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-accept-dispute",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "ACCEPT_POS_DISPUTE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "ACCEPT_POS_DISPUTE", payload: error.response.data });
      });
  };
};

export const submitPosDispute = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-submit-dispute-evidence",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SUBMIT_POS_DISPUTE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SUBMIT_POS_DISPUTE", payload: error.response.data });
      });
  };
};

export const saveLaterPosDispute = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-save-evidence-for-later",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SAVE_LATER_POS_DISPUTE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_LATER_POS_DISPUTE",
          payload: error.response.data,
        });
      });
  };
};

export const enableGoogleCalendarSync = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "user/sync-with-google-calendar",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "ENABLE_GOOGLE_CALENDAR_SYNC",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "ENABLE_GOOGLE_CALENDAR_SYNC",
          payload: error.response.data,
        });
      });
  };
};

export const disableGoogleCalendarSync = () => {
  return async (dispatch) => {
    return settingInstance
      .put(process.env.REACT_APP_API_URL + "user/disable-google-sync")
      .then((response) => {
        dispatch({
          type: "DISABLE_GOOGLE_CALENDAR_SYNC",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "DISABLE_GOOGLE_CALENDAR_SYNC",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const updateToggleAPI = (formData) => {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL +
          "appointments/setting/patient-portal-link-status",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "PATIENT_PORTAL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PATIENT_PORTAL", payload: error.response.data });
      });
  };
};

export const downloadTos = (tosType) => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "download-tos/" + tosType)
      .then((response) => {
        dispatch({ type: "DOWNLOAD_TOS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DOWNLOAD_TOS", payload: error.response.data });
      });
  };
};

export const downloadInvoice = (invoiceId) => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "download-invoice/" + invoiceId)
      .then((response) => {
        dispatch({ type: "DOWNLOAD_INVOICE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DOWNLOAD_INVOICE", payload: error.response.data });
      });
  };
};

export const checkFutureAppointment = (providerId) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "upcoming-provider-appointments-count/" +
          providerId,
      )
      .then((response) => {
        dispatch({ type: "CHECK_FUTURE_APPOINTMENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CHECK_FUTURE_APPOINTMENT",
          payload: error.response.data,
        });
      });
  };
};

export function resetReducerAction() {
  return (dispatch) => {
    dispatch({ type: "SETTINGS_RESET_ACTION" });
  };
}

export function userStatusUpdate(formData) {
  return (dispatch) => {
    settingInstance
      .put(
        process.env.REACT_APP_API_URL + "user/activate",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "USER_STATUS_UPDATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "USER_STATUS_UPDATE", payload: error.response.data });
      });
  };
}

export const getSubscriptionAcceleratorEstimate = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "essentials-to-accelerator",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_ACCELERATOR_ESTIMATE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_ACCELERATOR_ESTIMATE",
          payload: error.response.data,
        });
      });
  };
};

export function fetchtreatmentplantemplate(formData) {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "get-treatment-plan-templates",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "TREATMENT_PLAN_TEMPLATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "TREATMENT_PLAN_TEMPLATE",
          payload: error.response.data,
        });
      });
  };
}

export const fetchTemplate = (templateId) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "get-treatment-plan-template/" +
          templateId,
      )
      .then((response) => {
        dispatch({ type: "GET_TEMPLATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_TEMPLATE", payload: error.response.data });
      });
  };
};

export const upgradeSubscriptionToAccelerator = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "essentials-to-accelerator",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SUBSCRIPTION_ACCELERATOR_UPGRADE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SUBSCRIPTION_ACCELERATOR_UPGRADE",
          payload: error.response.data,
        });
      });
  };
};

export function fetchWidget() {
  return async (dispatch) => {
    return settingInstance
      .get(process.env.REACT_APP_API_URL + "get_dashboard_widgets")
      .then((response) => {
        dispatch({ type: "WIDGET_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "WIDGET_LIST", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export const submitWidget = (formData) => {
  return async (dispatch) => {
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + "save_dashboard_settings_by_admin",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SUBMIT_DASHBOARD_SETTINGS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SUBMIT_DASHBOARD_SETTINGS",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const saveTemplate = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "save-treatment-plan-monthly-template",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SAVE_TEMPLATE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_TEMPLATE_DATA", payload: error.response.data });
      });
  };
};

export const saveTemplatePayg = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL +
          "save-treatment-plan-pay-as-u-go-template",
        formData,
      )
      .then((response) => {
        dispatch({ type: "SAVE_TEMPLATE_DATA_PAYG", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_TEMPLATE_DATA_PAYG",
          payload: error.response.data,
        });
      });
  };
};

export function updateTemplate(formData) {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "save-treatment-plan-monthly-template",
        formData,
      )
      .then((response) => {
        dispatch({ type: "SAVE_TEMPLATE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_TEMPLATE_DATA", payload: error.response.data });
      });
  };
}

export const fetchAgreement = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "membership-get",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "AGREEMENT_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "AGREEMENT_LIST", payload: error.response.data });
      });
  };
};

export const createAgreement = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "membership-save",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CREATE_AGREEMENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_AGREEMENT", payload: error.response.data });
      });
  };
};

export const fetchSelectedAgreement = (formData, agreeId) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "membership-view?id=" + agreeId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SELECTED_AGREEMENT_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SELECTED_AGREEMENT_LIST",
          payload: error.response.data,
        });
      });
  };
};

export const updateSelectedAgreement = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "membership-save",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_SELECTED_AGREEMENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SELECTED_AGREEMENT",
          payload: error.response.data,
        });
      });
  };
};

export const deleteAgreement = (agreeId) => {
  return (dispatch) => {
    settingInstance
      .delete(process.env.REACT_APP_API_URL + "membership-delete?id=" + agreeId)
      .then((response) => {
        dispatch({ type: "AGREEMENT_DELETED", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "AGREEMENT_DELETED", payload: error.response.data });
      });
  };
};

export function deleteTreatmentTemplate(formData) {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "delete-treatment-plan-template",
        formData,
      )
      .then((response) => {
        dispatch({ type: "TEMPLATE_DELETED", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "TEMPLATE_DELETED", payload: error.response.data });
      });
  };
}

export function sendEmailOTP(formData) {
  return async (dispatch) => {
    return settingInstance
      .post(process.env.REACT_APP_API_URL + "user/2fa/email", formData)
      .then((response) => {
        dispatch({ type: "SEND_EMAIL_OTP", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SEND_EMAIL_OTP", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function verifyEmailOTP(formData) {
  return async (dispatch) => {
    return settingInstance
      .put(process.env.REACT_APP_API_URL + "user/2fa/email", formData)
      .then((response) => {
        dispatch({ type: "VERIFY_EMAIL_OTP", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "VERIFY_EMAIL_OTP", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function fetchHealthQuestionnaire(serviceID, procedureID) {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "get-service-questionnaires?service_id=" +
          serviceID +
          "&procedure_id=" +
          procedureID,
      )
      .then((response) => {
        dispatch({
          type: "FETCH_HEALTH_QUESTIONNAIRE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_HEALTH_QUESTIONNAIRE",
          payload: error.response.data,
        });
      });
  };
}

export const getCardDashboardVerification = () => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "pos-capabilty-fields")
      .then((response) => {
        dispatch({
          type: "GET_POS_DASHBOARD_VERIFICATION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_POS_DASHBOARD_VERIFICATION",
          payload: error.response.data,
        });
      });
  };
};

export const saveCardDashboardVerification = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "pos-save-capabilty-fields",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SAVE_POS_DASHBOARD_VERIFICATION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_POS_DASHBOARD_VERIFICATION",
          payload: error.response.data,
        });
      });
  };
};

export const updateClinicsData = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "update_clinics",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_CLINCS_HEADER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_CLINCS_HEADER",
          payload: error.response.data,
        });
      });
  };
};

export const saveCovidEmailSchedulling = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "add-edit-covid-schedule",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SAVE_COVID_EMAIL_SCHEDULLING",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_COVID_EMAIL_SCHEDULLING",
          payload: error.response.data,
        });
      });
  };
};

export const daleteCovidEmailSchedulling = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "delete-covid-schedule",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "DELETE_COVID_EMAIL_SCHEDULLING",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_COVID_EMAIL_SCHEDULLING",
          payload: error.response.data,
        });
      });
  };
};

export const getCovidEmailSchedulling = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "get-all-covid-schedules",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "GET_COVID_EMAIL_SCHEDULLING",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_COVID_EMAIL_SCHEDULLING",
          payload: error.response.data,
        });
      });
  };
};

export const checkEmail = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "check-email-exists",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CHECK_EMAIL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CHECK_EMAIL", payload: error.response.data });
      });
  };
};

export const switchWorkspaces = (formData) => {
  return async (dispatch) => {
    settingInstance.defaults.headers.common["access-token"] = getToken();
    return settingInstance
      .post(
        process.env.REACT_APP_API_URL + "switch-workspace",
        formData ? formData : "",
      )
      .then((response) => {
        queryClient.invalidateQueries();
        queryClient.removeQueries();

        if (response?.data?.data?.account?.update_address_flag) {
          lockAppUntilAccountAddressUpdated.set(true);
          dispatch({ type: "SWITCH_WORKSPACE", payload: response.data });
          return response.data;
        } else {
          lockAppUntilAccountAddressUpdated.remove();
          dispatch({ type: "SWITCH_WORKSPACE", payload: response.data });
          return response.data;
        }
      })
      .catch((error) => {
        dispatch({ type: "SWITCH_WORKSPACE", payload: error.response?.data });
        return Promise.reject(error.response.data);
      });
  };
};

export const refreshProfilePage = (userId) => {
  return (dispatch) => {
    dispatch({
      type: "REFRESH_PROFILE_PAGE",
      payload: { data: { user_id: userId }, status: 200, message: "" },
    });
  };
};

export const refreshDashboard = () => {
  return (dispatch) => {
    dispatch({
      type: "REFRESH_DASHBOARD",
      payload: { data: {}, status: 200, message: "" },
    });
  };
};

export const updateHeader = () => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_HEADER",
      payload: { data: {}, status: 200, message: "" },
    });
  };
};

export const getPlans = () => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "eprescription-plan-list")
      .then((response) => {
        dispatch({ type: "GET_EPRESCRIPTION_PLANS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_EPRESCRIPTION_PLANS",
          payload: error.response.data,
        });
      });
  };
};

export const getAllUsers = () => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "get-user-list?user_type=support")
      .then((response) => {
        dispatch({ type: "SUPPORT_USERS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SUPPORT_USERS_LIST", payload: error.response.data });
      });
  };
};

export const getProvidersList = () => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "get-user-list?user_type=prescriber")
      .then((response) => {
        dispatch({ type: "PROVIDERS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PROVIDERS_LIST", payload: error.response.data });
      });
  };
};

export const getPrescribers = (status, formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "prescriber-list/" + status,
        formData,
      )
      .then((response) => {
        dispatch({ type: "PRESCRIBERS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PRESCRIBERS_LIST", payload: error.response.data });
      });
  };
};

export const getSupporters = (status, formData) => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "support-list/" + status, formData)
      .then((response) => {
        dispatch({ type: "SUPPORT_AGENT_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SUPPORT_AGENT_LIST", payload: error.response.data });
      });
  };
};

export const addPrescriber = (formData) => {
  return async (dispatch) => {
    settingInstance
      .post(process.env.REACT_APP_API_URL + "new-prescriber", formData)
      .then((response) => {
        dispatch({ type: "ADD_PRESCRIBER", payload: response.data });
        response.data.status === 200
          ? toast.success(
              resolveServerSideMessage("erx", response.data, "Success"),
            )
          : toast.error(
              resolveServerSideMessage("erx", response.data, "Server error"),
            );
      })
      .catch((error) => {
        dispatch({ type: "ADD_PRESCRIBER", payload: error.response.data });
        toast.error(
          resolveServerSideMessage("erx", error.response.data, "Server error"),
        );
      });
  };
};

export const getPrescriber = (prescriberId) => {
  return async (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + `prescriber-data/${prescriberId}`)
      .then((response) => {
        dispatch({ type: "GET_PRESCRIBER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PRESCRIBER", payload: error.response.data });
      });
  };
};

export const renewPrescriber = (prescriberId, formData) => {
  return async (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + `renew-prescriber/${prescriberId}`,
        formData,
      )
      .then((response) => {
        dispatch({ type: "RENEW_PRESCRIBER", payload: response.data });
        response.data.status === 200
          ? toast.success(
              resolveServerSideMessage("erx", response.data, "Success"),
            )
          : toast.error(
              resolveServerSideMessage("erx", response.data, "Server error"),
            );
      })
      .catch((error) => {
        dispatch({ type: "RENEW_PRESCRIBER", payload: error.response.data });
        toast.error(
          resolveServerSideMessage("erx", error.response.data, "Server error"),
        );
      });
  };
};

export const addSupportStaff = (formData) => {
  return async (dispatch) => {
    settingInstance
      .post(process.env.REACT_APP_API_URL + "new-support-staff", formData)
      .then((response) => {
        dispatch({ type: "ADD_SUPPORT_STAFF", payload: response.data });
        response.data.status === 200
          ? toast.success(
              resolveServerSideMessage("erx", response.data, "Success"),
            )
          : toast.error(
              resolveServerSideMessage("erx", response.data, "Server error"),
            );
      })
      .catch((error) => {
        dispatch({ type: "ADD_SUPPORT_STAFF", payload: error.response.data });
        toast.error(
          resolveServerSideMessage("erx", error.response.data, "Server error"),
        );
      });
  };
};

export const refreshClientsData = () => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_CLIENTS",
      payload: {
        data: { updateClientsDataTime: new Date() },
        status: 200,
        message: "",
      },
    });
  };
};

export const resendInvitationEmail = (id, userType) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          "resend-script-sure-email/" +
          id +
          "?user_type=" +
          userType,
      )
      .then((response) => {
        dispatch({
          type:
            userType == "prescriber"
              ? "SEND_EMAIL_PRESCRIBERS"
              : "SEND_EMAIL_SUPPORT_AGENT",
          payload: response.data,
        });
        response.data.status === 200
          ? toast.success(
              resolveServerSideMessage("erx", response.data, "Success"),
            )
          : toast.error(
              resolveServerSideMessage("erx", response.data, "Server error"),
            );
      })
      .catch((error) => {
        dispatch({
          type:
            userType == "prescriber"
              ? "SEND_EMAIL_PRESCRIBERS"
              : "SEND_EMAIL_SUPPORT_AGENT",
          payload: error.response.data,
        });
        toast.error(
          resolveServerSideMessage("erx", error.response.data, "Server error"),
        );
      });
  };
};

export const inactivateUser = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(process.env.REACT_APP_API_URL + "inactivate-prescriber", formData)
      .then((response) => {
        dispatch({
          type:
            formData.user_type == "prescriber"
              ? "INACTIVATE_PRESCRIBER"
              : "INACTIVATE_SUPPORT_AGENT",
          payload: response.data,
        });
        response.data.status === 200
          ? toast.success(
              resolveServerSideMessage("erx", response.data, "Success"),
            )
          : toast.error(
              resolveServerSideMessage("erx", response.data, "Server error"),
            );
      })
      .catch((error) => {
        dispatch({
          type:
            formData.user_type == "prescriber"
              ? "INACTIVATE_PRESCRIBER"
              : "INACTIVATE_SUPPORT_AGENT",
          payload: error.response.data,
        });
        toast.error(
          resolveServerSideMessage("erx", error.response.data, "Server error"),
        );
      });
  };
};

export const reactivateUser = (formData) => {
  return async (dispatch) => {
    return await settingInstance
      .post(process.env.REACT_APP_API_URL + "reactivate-support-user", formData)
      .then((response) => {
        dispatch({ type: "INACTIVATE_SUPPORT_AGENT", payload: response.data });
        response.data.status === 200
          ? toast.success(
              resolveServerSideMessage("erx", response.data, "Success"),
            )
          : toast.error(
              resolveServerSideMessage("erx", response.data, "Server error"),
            );
      })
      .catch((error) => {
        dispatch({
          type: "INACTIVATE_SUPPORT_AGENT",
          payload: error.response.data,
        });
        toast.error(
          resolveServerSideMessage("erx", error.response.data, "Server error"),
        );
      });
  };
};

export const getClearentPosPayout = (formData) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + "get-merchant-bank-deposits",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CLEARENT_POS_PAYOUT_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CLEARENT_POS_PAYOUT_LIST",
          payload: error.response.data,
        });
      });
  };
};

export const getOnboardedClinics = () => {
  return (dispatch) => {
    settingInstance
      .get(process.env.REACT_APP_API_URL + "get-onboarded-clinics")
      .then((response) => {
        dispatch({ type: "ONBOARDED_CLINICS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "ONBOARDED_CLINICS", payload: error.response.data });
      });
  };
};

export const clearRecentlyDeletedPaperworkAction = () => ({
  type: "CLEAR_RECENTLY_DELETED_PAPERWORK",
});

export const clearRestorePaperworkAction = () => ({
  type: "CLEAR_RESTORE_PAPERWORK",
});

export const getRecentlyDeletedPaperworkAction = (formData, type) => {
  return (dispatch) => {
    dispatch({ type: "RECENTLY_DELETED_PAPERWORK_REQUEST" });
    settingInstance
      .get(
        process.env.REACT_APP_API_URL + `recently-deleted-paperwork/${type}`,
        formData,
      )
      .then((response) => {
        dispatch({
          type: "RECENTLY_DELETED_PAPERWORK_SUCCESS",
          data: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "RECENTLY_DELETED_PAPERWORK_FAILURE",
          error: error.response.data,
        });
      });
  };
};

export const restoreRecentlyDeletedPaperworkAction = (formData, type) => {
  return (dispatch) => {
    dispatch({ type: "RECENTLY_DELETED_PAPERWORK_REQUEST" });

    const nextType = ["simple-questionnaires", "multi-questionnaires"].includes(
      type,
    )
      ? "questionnaires"
      : type;

    settingInstance
      .patch(
        process.env.REACT_APP_API_URL + `restore-paperwork/${nextType}`,
        formData,
      )
      .then((response) => {
        dispatch({
          type: "RESTORE_PAPERWORK_SUCCESS",
          data: response.data.message,
        });
      })
      .catch((error) => {
        dispatch({
          type: "RESTORE_PAPERWORK_FAILURE",
          error: error.response.data,
        });
      });
  };
};

export const editStripeOwnerInfoAction = (id) => {
  return (dispatch) => {
    settingInstance
      .get(
        process.env.REACT_APP_API_URL +
          `get-stripe-owner-info?stripe_user_id=${id}`,
      )
      .then((response) => {
        dispatch({ type: "STRIPE_OWNER_INFO", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "STRIPE_OWNER_INFO", payload: error.response.data });
      });
  };
};

export const updateStripeOwnerInfoAction = (formData) => {
  return (dispatch) => {
    settingInstance
      .post(
        process.env.REACT_APP_API_URL + "update-stripe-owner-info",
        formData,
      )
      .then((response) => {
        dispatch({ type: "UPDATED_OWNER_INFO", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATED_OWNER_INFO", payload: error.response.data });
      });
  };
};

export const getUsersPendingInvites = () => {
  return (dispatch) => {
    settingInstance
      .get(`${process.env.REACT_APP_API_URL}users-pending-invites`)
      .then((response) => {
        dispatch({
          type: "GET_USERS_PENDING_INVITES",
          payload: response.data.data.pending_invites,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_USERS_PENDING_INVITES",
          payload: error.response.data,
        });
      });
  };
};

export const resendInvite = (userId) => {
  return (dispatch) => {
    settingInstance
      .post(`${process.env.REACT_APP_API_URL}users-resend-invite`, userId)
      .then((response) => {
        dispatch({ type: "RESEND_INVITE", payload: response.data });
        toast.success("Invitation Sent!");
      })
      .catch((error) => {
        dispatch({ type: "RESEND_INVITE", payload: error.response.data });
      });
  };
};

export const deleteInvite = (userId) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_INVITE_LOADING" });
    settingInstance
      .post(`${process.env.REACT_APP_API_URL}reject-invite`, userId)
      .then((response) => {
        dispatch({ type: "DELETE_INVITE_SUCCESS", payload: response.data });
        if (response.data.status === 400) {
          toast.error(languageData.global[response.data.message]);
        }
        if (response.data.status === 200) {
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        dispatch({ type: "DELETE_INVITE", payload: error.response.data });
      });
  };
};

export const selectedPrescribingSupportUser = (data) => {
  return (dispatch) => {
    dispatch({ type: "SELECT_PRESCRIBING_SUPPORT_USER", payload: data });
  };
};
