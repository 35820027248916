import React, { useState } from "react";
import cx from "clsx";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import classes from "./LogicJumps.module.scss";
import { ChevronLeftIcon } from "../../../../../assets/Icons/ChevronLeftIcon";
import { PlusIcon } from "../../../../../assets/Icons/PlusIcon";
import { Button } from "../../../../../shared/Button/Button";
import { Node } from "./shared/Node/Node";
import { MULTI_QUESTION_LOGIC_JUMP_TYPES } from "../../../../../api/questionnaires/config";
import { useOptions } from "./hooks/useOptions";

export function LogicJumps({
  nodes,
  onAddNode,
  onChangeNodeValue,
  onRemoveNode,
  choices,
  questions,
  preloadedQuestionIndex,
  isInitialOpen,
}) {
  const [isOpen, setIsOpen] = useState(isInitialOpen);
  const { tSettings } = useAppTranslation.Settings();

  const options = useOptions({
    nodes,
    choices,
    questions,
    preloadedQuestionIndex,
  });

  const canAddMoreNodes =
    options.choices.available?.length > 0 &&
    options.questions.available?.length > 0;

  function renderNode(node, index) {
    switch (node.type) {
      case MULTI_QUESTION_LOGIC_JUMP_TYPES.jump: {
        return (
          <Node
            key={index}
            onRemove={index !== 0 ? () => onRemoveNode(index) : undefined}
            rows={[
              {
                label: tSettings("questionnaires.logicJump.ifClause"),
                options: options.choices.available,
                value: options.choices.all.find(
                  (i) => i.value === node.ifSelectedChoiceIndex,
                ),
                onChange: (next) =>
                  onChangeNodeValue({
                    field: "ifSelectedChoiceIndex",
                    value: next?.value,
                    index,
                  }),
              },
              {
                label: tSettings("questionnaires.logicJump.thenClause"),
                options: options.questions.available,
                value: options.questions.all.find(
                  (i) => i.value === node.thenQuestionIndex,
                ),
                onChange: (next) =>
                  onChangeNodeValue({
                    field: "thenQuestionIndex",
                    value: next?.value,
                    index,
                  }),
              },
            ]}
          />
        );
      }
      case MULTI_QUESTION_LOGIC_JUMP_TYPES.else: {
        return (
          <Node
            key={index}
            rows={[
              {
                label: tSettings("questionnaires.logicJump.elseClause"),
                options: options.questions.available,
                value: options.questions.all.find(
                  (i) => i.value === node.elseQuestionIndex,
                ),
                onChange: (next) =>
                  onChangeNodeValue({
                    field: "elseQuestionIndex",
                    value: next?.value,
                    index,
                  }),
              },
            ]}
          />
        );
      }
      default:
        return null;
    }
  }

  return (
    <div>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className={cx(classes.toggle, {
          [classes.toggleOpen]: isOpen,
        })}
      >
        <div className={classes.left}>
          <i className="fa fa-share-alt" aria-hidden="true"></i>
          {tSettings("questionnaires.logicJump.title")}
        </div>
        <div
          className={cx(classes.chevron, {
            [classes.chevronOpen]: isOpen,
          })}
        >
          <ChevronLeftIcon />
        </div>
      </button>
      {isOpen && (
        <div className={classes.dropdown}>
          <div className={classes.header}>
            <h3>{tSettings("questionnaires.logicJump.dropdownLabel")}</h3>
            <Button
              size="small"
              variant="text"
              onClick={onAddNode}
              leftAdornment={<PlusIcon />}
              isDisabled={!canAddMoreNodes}
            >
              {tSettings("questionnaires.logicJump.add")}
            </Button>
          </div>
          <div className={classes.nodes}>{nodes?.map(renderNode)}</div>
        </div>
      )}
    </div>
  );
}
