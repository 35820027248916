import { useEffect, useMemo, useState } from "react";
import { useDebouncedState } from "../../../utilities/hooks/useDebouncedState";

export const DEFAULT_MATCHED_PATTERN = null;

/**
 * @typedef {{
 *   label: string;
 *   description: string | null;
 *   value: string | number;
 *}} SubstituteItem
 *
 * @typedef {{
 *    match: string;
 *    substitutes: SubstituteItem[]
 * }} Pattern
 */

/**
 * @param {string} text
 * @param {string} match
 * @returns {boolean}
 */
const isPatternPresent = (text, match) => {
  return text.lastIndexOf(match) !== -1;
};

/**
 * @param {string} text
 * @param {string} match
 * @param {string | number} valueForSubstitution
 * @returns {string}
 */
const substituteByMatch = (text, match, valueForSubstitution) => {
  if (isPatternPresent(text, match)) {
    const lastIndexOfPattern = text?.lastIndexOf(match);
    if (lastIndexOfPattern !== -1) {
      return text.slice(0, lastIndexOfPattern) + valueForSubstitution;
    }
  }
  return text;
};

/**
 * @param {string} text
 * @param {Pattern[]} patterns
 * @returns {Pattern | null}
 */
const getMatchedPattern = (text, patterns) => {
  if (patterns?.length > 0 && text) {
    const nextMatchedPatterns = patterns.filter((p) => {
      return isPatternPresent(text, p.match);
    });
    return (
      nextMatchedPatterns?.[nextMatchedPatterns.length - 1] ||
      DEFAULT_MATCHED_PATTERN
    );
  }
  return DEFAULT_MATCHED_PATTERN;
};

/**
 * @param {string} text
 * @param {string} match
 * @returns {string}
 */
const getSubstitutionSearchTerm = (text, match) => {
  const lastIndexOfPattern = text?.lastIndexOf(match);
  if (lastIndexOfPattern !== -1) {
    const splitValue = text?.slice(lastIndexOfPattern)?.split(match);
    const matchPatternSearch = splitValue?.[splitValue.length - 1];
    return matchPatternSearch?.toLowerCase();
  }
  return "";
};

/**
 * @param {SubstituteItem} substituteItem
 * @param {string} searchTerm
 * @returns {boolean}
 */
const substitutesFilterPredicate = (substituteItem, searchTerm) => {
  return (
    substituteItem.label.toLowerCase().includes(searchTerm) ||
    substituteItem?.description?.toLowerCase()?.includes(searchTerm)
  );
};

/**
 * @param {{
 *    text: string;
 *    onTextChange: (nextText: string) => void;
 *    patterns: Pattern[];
 * }} param0
 * @returns {{
 *    changeText: (nextText: string) => void;
 *    isPatternFound: boolean;
 *    availableSubstitutes: SubstituteItem[];
 *    substitutePattern: (item: SubstituteItem) => void;
 * }}
 */
export function usePatternAutocomplete({ text, onTextChange, patterns }) {
  const [matchedPattern, setMatchedPattern] = useState(DEFAULT_MATCHED_PATTERN);
  const [_, debouncedValue, setDebouncedValue] = useDebouncedState("", 300);
  const [substitutionsSearchTerm, setSubstitutionsSearchTerm] = useState("");

  const handleTextChange = (e) => {
    onTextChange(e.target.value);
    setDebouncedValue(e.target.value);
  };

  const substitutePattern = (itemForSubstitution) => {
    if (matchedPattern !== DEFAULT_MATCHED_PATTERN) {
      onTextChange(
        substituteByMatch(
          text,
          matchedPattern.match,
          itemForSubstitution.value,
        ),
      );
    }
    setDebouncedValue("");
    setMatchedPattern(DEFAULT_MATCHED_PATTERN);
  };

  const availableSubstitutes = useMemo(
    () =>
      matchedPattern?.substitutes?.filter((s) =>
        substitutesFilterPredicate(s, substitutionsSearchTerm),
      ) || [],
    [matchedPattern?.substitutes, substitutionsSearchTerm],
  );

  useEffect(() => {
    setMatchedPattern(getMatchedPattern(debouncedValue, patterns));
  }, [debouncedValue, patterns]);

  useEffect(() => {
    if (matchedPattern) {
      setSubstitutionsSearchTerm(
        getSubstitutionSearchTerm(debouncedValue, matchedPattern.match),
      );
    }
  }, [matchedPattern?.match, debouncedValue]);

  return {
    changeText: handleTextChange,
    isPatternFound: matchedPattern !== DEFAULT_MATCHED_PATTERN,
    availableSubstitutes,
    substitutePattern,
  };
}
