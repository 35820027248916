import React from "react";
import PropTypes from "prop-types";
import classes from "./PatientConsentPreview.module.scss";
import { Divider } from "../../shared/Divider/Divider";
import { Header } from "./shared/Header/Header";
import { Footer } from "./shared/Footer/Footer";

export function PatientConsentPreview({ patient, consent, noDivider }) {
  return (
    <div className={classes.root}>
      <Header patientId={patient.id} />
      {!noDivider && <Divider />}
      <h3>{consent.name}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: consent.description,
        }}
      />
      <Footer
        witness={{
          name: consent.witnessName,
          signatureUrl: consent.witnessSignatureUrl,
        }}
        patient={{
          id: patient.id,
          name: consent.patientName,
          signatureUrl: consent.patientSignatureUrl,
        }}
      />
    </div>
  );
}

PatientConsentPreview.propTypes = {
  consent: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    witnessSignatureUrl: PropTypes.string,
    witnessName: PropTypes.string,
    patientSignatureUrl: PropTypes.string,
    patientName: PropTypes.string,
  }).isRequired,
  patient: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  noDivider: PropTypes.bool,
};
