import React, { useState } from "react";
import cx from "clsx";
import { format } from "date-fns";
import styles from "./ClietLoyaltyProgram.module.scss";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { ModalInfo } from "../../../../../boxes/ModalInfo/ModalInfo";
import { getPathParams } from "../../utils/utils";

export function ClientLoyaltyProgram() {
  const { clientId } = getPathParams();
  const { data: clientData } = useClientQuery({ clientId });
  const { data: currentUser } = useCurrentUserQuery();
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  return (
    <div>
      {[clientData?.brilliantAccNumber,
        clientData?.aspireAccEmail,
        clientData?.evolus,
        clientData?.xperienceUsername,
        clientData?.patientsSkinScore].some(Boolean) && (
          <div className={cx(styles.clientWrapper, "col-xs-12")}>
            <div className={styles.title}>Loyalty programs user id</div>
            {clientData?.brilliantAccNumber && (
              <div>
                <div className={cx(styles.title, styles.boldText)}>Alle:</div>
                <div>{clientData?.brilliantAccNumber}</div>
              </div>
            )}
            {Boolean(clientData?.aspireAccEmail) && (
              <div>
                <div className={cx(styles.title, styles.boldText)}>Aspire:</div>
                <div>{clientData?.aspireAccEmail}</div>
              </div>
            )}
            {Boolean(clientData?.evolus) && (
              <div>
                <div className={cx(styles.title, styles.boldText)}>Evolus:</div>
                <div>{clientData?.evolus}</div>
              </div>
            )}
            {Boolean(clientData?.xperienceUsername) && (
              <div>
                <div className={cx(styles.title, styles.boldText)}>
                  Xperience:
                </div>
                <div>{clientData?.xperienceUsername}</div>
              </div>
            )}
            {Boolean(clientData?.patientsSkinScore?.points) && (
              <div className={styles.fitzpatrickBlock}>
                <div className={styles.title}>Fitzpatrick Skin Test</div>
                <div className={styles.testInfoBloc}>
                  <div>
                    Your Points: {clientData?.patientsSkinScore?.points}
                  </div>
                  <div>
                    Skin Type: {clientData?.patientsSkinScore?.skin_type}
                  </div>
                  <div>
                    Last Taken On:{" "}
                    {format(
                      clientData?.patientsSkinScore?.created,
                      currentUser?.previewDateFormat,
                    ) || ""}
                  </div>
                </div>
                <button
                  className={cx(styles.skinDescp, "line-btn", "pull-right")}
                  type="button"
                  onClick={onOpen}
                >
                  View Details
                </button>
              </div>
            )}
          </div>
        )}
      <ModalInfo
        onClose={onClose}
        isOpen={isOpen}
        onOk={onClose}
        titleSuffix="Skin Details"
      >
        {clientData?.patientsSkinScore?.skin_discription || ""}
      </ModalInfo>
    </div>
  );
}
