import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

export const UPLOAD_MEDIA_TYPE = {
  procedureImage: "procedure_image",
  questionnaires: "questionnaires",
};

const requestSchema = yup.object({
  type: yup.string().required(),
  file: yup
    .mixed()
    .test({
      test: (v) => v instanceof File,
    })
    .required(),
});

const responseSchema = yup.object({
  fileName: yup.string().required(),
  fullPath: yup.string().required(),
});

const composeResponse = (res) => {
  return {
    fileName: res?.data?.data?.file_name,
    fullPath: res?.data?.data?.full_path,
  };
};

export function useUploadMediaMutation(options = {}) {
  return useMutation(async (dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    const formData = new FormData();
    formData.append("file", req.file);
    const res = await http.post(HTTP_ENDPOINTS.uploadMedia(req.type), formData);
    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
