import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  patientId: yup.number().required(),
  procedureId: yup.number().nullable(),
});

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    dateTime: yup.string().required(),
    type: yup.string().required(),
    clinicId: yup.number().required(),
    endTime: yup.string().required(),
    durationMin: yup.number().required(),
    patientId: yup.number().required(),
    providerId: yup.number().nullable(),
    services: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          name: yup.string().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res) => {
  const data = res.data?.data || [];
  return data.map((i) => ({
    id: i.id,
    dateTime: i.appointment_datetime,
    type: i.appointment_type,
    clinicId: i.clinic_id,
    endTime: i.end_time,
    durationMin: i.duration,
    patientId: i.patient_id,
    providerId: i.user_id || null,
    services:
      i?.services?.map((s) => ({
        id: s.id,
        name: s.name,
      })) || [],
  }));
};

export function createPatientUpcomingAppointmentsQueryKey({
  patientId,
  procedureId,
}) {
  return [QUERY_KEYS.patientUpcomingAppointments, patientId, procedureId];
}

export function usePatientUpcomingAppointmentsQuery({ payload, options = {} }) {
  return useQuery(
    createPatientUpcomingAppointmentsQueryKey({
      patientId: payload.patientId,
      procedureId: payload.procedure,
    }),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.getPatientUpcomingAppointments(req.patientId),
        req.procedureId
          ? {
              params: {
                procedure_id: req.procedureId,
              },
            }
          : {},
      );
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    options,
  );
}
