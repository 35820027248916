import { useState } from "react";
import { useUploadMediaMutation } from "../api/mutations/useUploadMediaMutation";
import { useAppTranslation } from "../i18n/useAppTranslation";
import { fileUtil } from "../utilities/file";
import { uiNotification } from "../services/UINotificationService";

export const ALLOWED_IMAGE_UPLOADER_TYPES = [
  "image/png",
  "image/PNG",
  "image/jpg",
  "image/JPG",
  "image/jpeg",
  "image/JPEG",
  "image/gif",
  "image/GIF",
];

export function useImageUploader({ uploadType, distinguishByName } = {}) {
  const { tCommon } = useAppTranslation.Common();
  const [isLoading, setIsLoading] = useState(false);
  const upload = useUploadMediaMutation();

  const [currentName, setCurrentName] = useState(null);
  const [queue, setQueue] = useState(new Set());

  const initiate = async (file, _uploadType, name) => {
    try {
      setIsLoading(true);

      if (distinguishByName) {
        setQueue((prev) => new Set([...prev, name]));
      }

      if (file && ALLOWED_IMAGE_UPLOADER_TYPES.indexOf(file.type) > -1) {
        const fileAfterRead = await fileUtil.read(file, {
          method: "dataUrl",
        });
        const response = await upload.mutateAsync({
          file: file,
          type: _uploadType || uploadType,
        });
        return {
          uploadedFileName: response.fileName,
          uploadedFileFullPath: response.fullPath,
          originalFile: file,
          fileAfterRead,
        };
      } else {
        uiNotification.error(tCommon("error.unsupportedFileType"));
      }
    } catch {
      uiNotification.error(tCommon("error.imageUpload"));
    } finally {
      setIsLoading(false);
      if (distinguishByName) {
        setQueue((prev) => {
          prev.delete(name);
          return new Set([...prev]);
        });
        setCurrentName(null);
      }
    }
  };

  const htmlOnChange = async (e, uploadType) => {
    const file = e.target?.files?.[0];
    const name = e.target?.name;

    if (distinguishByName) {
      setCurrentName(name);
    }

    return initiate(file, uploadType, name);
  };

  return {
    isLoading: distinguishByName
      ? isLoading && queue.has(currentName)
      : isLoading,
    initiate,
    htmlOnChange,
    uploadingName: currentName,
  };
}
