import { useMemo } from "react";
import { useShortMedicalHistoryQuery } from "../../../../../../api/queries/useShortMedicalHistoryQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../services/UINotificationService";

export function useMedicalHistoryPatterns({ clientId }) {
  const { tCommon } = useAppTranslation.Common();

  const { data } = useShortMedicalHistoryQuery({
    payload: {
      patientId: clientId,
    },
    options: {
      onError: () => {
        uiNotification.error(tCommon("error.fetchMedicalHistory"));
      },
    },
  });

  const preparedData = useMemo(() => {
    return (
      data?.map((i) => ({
        label: i.title,
        value: `${i.title}: `,
      })) || []
    );
  }, [data]);

  return {
    data: preparedData,
  };
}
