import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    name: yup.string().required(),
    title: yup.string().nullable(),
    phrase: yup.string().required(),
  }),
);

const composeResponse = (res) => {
  let result = [];
  if (res && Array.isArray(res.data.data)) {
    result = res.data.data.map((i) => ({
      id: i.id,
      name: i.name,
      title: i.title || null,
      phrase: i.phrase,
    }));
  }
  return result;
};

export function createDotPhrasesQueryKey() {
  return [QUERY_KEYS.dotPhrases];
}

export function useDotPhrasesQuery(options = {}) {
  return useQuery(
    createDotPhrasesQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getDotPhrases());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
