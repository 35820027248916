import React, { Component } from "react";
import Tooltip from "react-tooltip";
import {
  checkIfPermissionAllowed,
  numberFormat,
  showFormattedDate,
  isNumber,
  getIsAcceleratorPlan,
} from "../../../Utils/services.js";
import { INVOICE_STATUSES } from "../../../consts/api.js";
import { withCurrentAccountQuery } from "../../../api/queries/useAccountQuery.js";
import defVImage from '../../../_legacy/images/no-image-vertical.png';
import LockProcedure from './LockProcedure.js';
import { getPrecision, toFixed } from '../../../utilities/general.js';
import { shortenClinicName } from "../../../helpers/general";
import ExpiredPaymentIcon from './icons/ExpiredPaymentIcon.js';
import FilterCategorizationSelect from './components/FilterCategorizationSelect.js';

class CosmeticTimeline extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const ePrescriptionSetup = localStorage.getItem("ePrescriptionSetup")
      ? JSON.parse(localStorage.getItem("ePrescriptionSetup"))
      : null;
    this.state = {
      userData: userData,
      isTouchMd: userData.account.account_preference.is_touch_md,
      plan_selected: userData.account.account_subscription.plan_code,
      ePrescriptionSetup: ePrescriptionSetup,
      isProcedureLocked: this.props.hobj.is_lock,
      languageData:
        JSON.parse(localStorage.getItem("languageData"))?.clients || {},
    };
  }

  runCarousel = (imageCount, e) => {
    e.preventDefault();
    if (this.refCarouselInner && imageCount && imageCount > 0) {
      let targetNode = this.refCarouselInner.children;
      let nodeLength = targetNode.length;

      for (let i = 0; i < nodeLength; i++) {
        if (targetNode[i].classList.contains("active")) {
          targetNode[i].classList.remove("active");
          if (i === nodeLength - 1) {
            i = -1;
          }
          targetNode[i + 1].classList.add("active");
          return;
        }
      }
    }
  };

  formatOpenGalleryArray = () => {
    let formattedArray = (this.props.hobj?.extra_images || []).map((image) => {
      return { image_id: image.id, src: image.image_url };
    });
    return formattedArray;
  };

  updateLockState = (isProcedureLocked) => {
    this.setState({ isProcedureLocked });
  };

  get invoiceStatusIcon() {
    const status = {
      check: ['paid', 'paid_by_merge', 'refunded', 'partial_refunded', 'refunded_from_merge'],
      alert: ['partial paid', 'partial_paid']
    }
    const invoiceStatus = this.props.hobj.pos_invoices?.invoice_status
    const hasInvoice = this.props.hobj.invoice_id > 0 && this.props.hobj.pos_invoices?.is_deleted === 0
    const isSessionExpired = this.props.hobj.checkout_session_expired && !hasInvoice

    let iconClass = 'fa fa-times'

    if (hasInvoice) {
      if (status.check.includes(invoiceStatus)) {
        iconClass = 'fa fa-check'
      }
      if (status.alert.includes(invoiceStatus)) {
        iconClass = 'alertIcon pull-right m-t-1'
      }
    }

    const handleClick = () => {
      if (hasInvoice && invoiceStatus.toLowerCase() !== "pending") {
        this.props.getInvoiceDetails(this.props.hobj.pos_invoices.id)
      }
    }

    return (
      <li className="action-button-li pending-payment" onClick={handleClick}> 
        Invoice {invoiceStatus === "pending" && 
          <span className="inline-block">{"(pending payment)"}</span>} 
          {isSessionExpired ? 
            <ExpiredPaymentIcon /> : 
              <i className={iconClass} aria-hidden="true"></i>}
      </li>
    )
  }

  render() {
    let carouselImages = [];
    if (
      this.props.hobj.procedure_image_data &&
      this.props.hobj.procedure_image_data.patient_image_right_45_url &&
      this.props.hobj.procedure_image_data.patient_image_right_45_thumb_url
    ) {
      carouselImages.push({
        src: this.props.hobj.procedure_image_data.patient_image_right_45_url,
        thumbnail:
          this.props.hobj.procedure_image_data.patient_image_right_45_thumb_url,
        caption: `${this.state.languageData.pro_right_image} - ${this.state.languageData.pro_45_deg}`,
      });
    } else {
      carouselImages.push({
        src: defVImage,
        thumbnail: defVImage,
        caption: `${this.state.languageData.pro_right_image} - ${this.state.languageData.pro_45_deg}`,
      });
    }

    if (
      this.props.hobj.procedure_image_data &&
      this.props.hobj.procedure_image_data.patient_image_front_url &&
      this.props.hobj.procedure_image_data.patient_image_front_thumb_url
    ) {
      carouselImages.push({
        src: this.props.hobj.procedure_image_data.patient_image_front_url,
        thumbnail:
          this.props.hobj.procedure_image_data.patient_image_front_thumb_url,
        caption: `${this.state.languageData.pro_text_front_image}`,
      });
    } else {
      carouselImages.push({
        src: defVImage,
        thumbnail: defVImage,
        caption: `${this.state.languageData.pro_text_front_image}`,
      });
    }

    if (
      this.props.hobj.procedure_image_data &&
      this.props.hobj.procedure_image_data.patient_image_left_45_url &&
      this.props.hobj.procedure_image_data.patient_image_left_45_thumb_url
    ) {
      carouselImages.push({
        src: this.props.hobj.procedure_image_data.patient_image_left_45_url,
        thumbnail:
          this.props.hobj.procedure_image_data.patient_image_left_45_thumb_url,
        caption: `${this.state.languageData.pro_left_image} - ${this.state.languageData.pro_45_deg}`,
      });
    } else {
      carouselImages.push({
        src: defVImage,
        thumbnail: defVImage,
        caption: `${this.state.languageData.pro_left_image} - ${this.state.languageData.pro_45_deg}`,
      });
    }

    if (this.props.hobj.type !== "laser") {
      if (
        this.props.hobj.procedure_image_data &&
        this.props.hobj.procedure_image_data.patient_image_right_url &&
        this.props.hobj.procedure_image_data.patient_image_right_thumb_url
      ) {
        carouselImages.push({
          src: this.props.hobj.procedure_image_data.patient_image_right_url,
          thumbnail:
            this.props.hobj.procedure_image_data.patient_image_right_thumb_url,
          caption: `${this.state.languageData.pro_right_image} - ${this.state.languageData.pro_90_deg}`,
        });
      } else {
        carouselImages.push({
          src: defVImage,
          thumbnail: defVImage,
          caption: `${this.state.languageData.pro_right_image} - ${this.state.languageData.pro_90_deg}`,
        });
      }

      if (
        this.props.hobj.procedure_image_data &&
        this.props.hobj.procedure_image_data.patient_image_left_url &&
        this.props.hobj.procedure_image_data.patient_image_left_thumb_url
      ) {
        carouselImages.push({
          src: this.props.hobj.procedure_image_data.patient_image_left_url,
          thumbnail:
            this.props.hobj.procedure_image_data.patient_image_left_thumb_url,
          caption: `${this.state.languageData.pro_left_image} - ${this.state.languageData.pro_90_deg}`,
        });
      } else {
        carouselImages.push({
          src: defVImage,
          thumbnail: defVImage,
          caption: `${this.state.languageData.pro_left_image} - ${this.state.languageData.pro_90_deg}`,
        });
      }
    }

    if (this.props.hobj.type === "coolsculpting") {
      if (
        this.props.hobj.procedure_image_data &&
        this.props.hobj.procedure_image_data.patient_image_back_right_45_url &&
        this.props.hobj.procedure_image_data
          .patient_image_back_right_45_thumb_url
      ) {
        carouselImages.push({
          src: this.props.hobj.procedure_image_data
            .patient_image_back_right_45_url,
          thumbnail:
            this.props.hobj.procedure_image_data
              .patient_image_back_right_45_thumb_url,
          caption: `${this.state.languageData.pro_back} ${this.state.languageData.pro_right_image} - ${this.state.languageData.pro_45_deg}`,
        });
      } else {
        carouselImages.push({
          src: defVImage,
          thumbnail: defVImage,
          caption: `${this.state.languageData.pro_back} ${this.state.languageData.pro_right_image} - ${this.state.languageData.pro_45_deg}`,
        });
      }

      if (
        this.props.hobj.procedure_image_data &&
        this.props.hobj.procedure_image_data.patient_image_back_url &&
        this.props.hobj.procedure_image_data.patient_image_back_thumb_url
      ) {
        carouselImages.push({
          src: this.props.hobj.procedure_image_data.patient_image_back_url,
          thumbnail:
            this.props.hobj.procedure_image_data.patient_image_back_thumb_url,
          caption: `${this.state.languageData.pro_back_image}`,
        });
      } else {
        carouselImages.push({
          src: defVImage,
          thumbnail: defVImage,
          caption: `${this.state.languageData.pro_back_image}`,
        });
      }

      if (
        this.props.hobj.procedure_image_data &&
        this.props.hobj.procedure_image_data.patient_image_back_left_45_url &&
        this.props.hobj.procedure_image_data
          .patient_image_back_left_45_thumb_url
      ) {
        carouselImages.push({
          src: this.props.hobj.procedure_image_data
            .patient_image_back_left_45_url,
          thumbnail:
            this.props.hobj.procedure_image_data
              .patient_image_back_left_45_thumb_url,
          caption: `${this.state.languageData.pro_back} ${this.state.languageData.pro_left_image} - ${this.state.languageData.pro_45_deg}`,
        });
      } else {
        carouselImages.push({
          src: defVImage,
          thumbnail: defVImage,
          caption: `${this.state.languageData.pro_back} ${this.state.languageData.pro_left_image} - ${this.state.languageData.pro_45_deg}`,
        });
      }
    }

    const totalImages = Object.keys(
      this.props.hobj?.procedure_image_data || {},
    ).reduce((carry, key) => {
      if (
        key.endsWith("_url") &&
        !key.endsWith("thumb_url") &&
        key.includes("image_") &&
        this.props.hobj?.procedure_image_data?.[key]
      ) {
        return carry + 1;
      }
      return carry;
    }, 0);

    const canSendPosInstructions =
      this.props.hobj?.pos_invoices?.invoice_status === INVOICE_STATUSES.paid &&
      Boolean(
        this.props.currentAccountQuery?.data?.globalSettings?.isPosEnabled,
      );

    return (
      <div className="col-xs-12 cosmetic-timeline">
        <div className="row">
          {this.state.isTouchMd == 1 &&
            this.state.plan_selected == "practice_booking" ? (
            <div className="col-sm-3 col-xs-12 m-b-15">
              <label className="popup-input-field-name">Date & Time</label>
              <p className="pro-time healthTimelineDetail m-b-0">
                {showFormattedDate(this.props.hobj.procedure_date, true)}
              </p>
            </div>
          ) : (
            <div className="col-sm-3 col-xs-12 m-b-15">
              {checkIfPermissionAllowed("manage-procedures") &&
                !this.state.isProcedureLocked ? (
                <a
                  onClick={() =>
                    this.props.openProcedureDetail(
                      this.props.hobj.id,
                      this.props.hobj.procedure_type,
                    )
                  }
                  className="modal-link"
                >
                  <h4 className="procedure-name">
                    {this.props.hobj.procedure_name}
                  </h4>
                </a>
              ) : (
                <a className="modal-link ">
                  <h4 className="procedure-name cursor-not-allowed">
                    {this.props.hobj.procedure_name}
                  </h4>
                </a>
              )}
              <p className="pro-time healthTimelineDetail m-b-0">
                {showFormattedDate(this.props.hobj.procedure_date, true)}
              </p>
            </div>
          )}
          <div className="col-sm-3 col-xs-12 m-b-15">
            <label className="popup-input-field-name">
              {this.state.languageData.clientprofile_provider}
            </label>
            <span className="healthTimelineDetail m-b-0">
              {this.props.hobj.doctor_name}
            </span>
          </div>
          <div className="col-sm-2 col-xs-12 m-b-15">
            <label className="popup-input-field-name">Location</label>
            <span className="healthTimelineDetail m-b-0">
              {this.props.hobj &&
                this.props.hobj.clinic &&
                this.props.hobj.clinic.clinic_name}
            </span>
          </div>
          <div className="col-sm-3 col-xs-12 m-b-15 text-right">
            <FilterCategorizationSelect 
              value={this.props.hobj.charting_category_id} 
              procedureId={this.props.hobj.id} 
              refetchProcedures= {this.props.refetchProcedures} 
            />
          </div>
          <div className="col-sm-3 col-xs-12 m-b-15 text-right">
            <LockProcedure
              refetchProcedures={this.props.refetchProcedures}
              lockUnlockby={this.props.hobj.locked_unlocked_by}
              isLocked={this.state.isProcedureLocked}
              updateLockState={this.updateLockState}
              procedureId={this.props.hobj.id}
            />
          </div>
        </div>
        {this.state.isTouchMd == 1 &&
          this.state.plan_selected == "practice_booking" ? (
          ""
        ) : (
          <div className="row p-b-30 p-t-15 cosmeticImages">
            <div className="col-lg-12 col-sm-12 col-xs-12">
              <div
                className="carousel slide"
                data-ride="carousel"
                data-interval="false"
              >
                <div
                  className="carousel-inner"
                  ref={(refCarouselInner) =>
                    (this.refCarouselInner = refCarouselInner)
                  }
                >
                  <div className="item active">
                    <img
                      title={carouselImages[0].caption}
                      className={`procedure-image-group ${this.state.isProcedureLocked
                          ? "lockSensitiveProcedureImages"
                          : ""
                        } procedure-image-first`}
                      onClick={(e) =>
                        !this.state.isProcedureLocked &&
                        this.props.openLightbox(0, e, carouselImages)
                      }
                      src={carouselImages[0].thumbnail}
                    />
                    <img
                      title={carouselImages[1].caption}
                      className={`procedure-image-group ${this.state.isProcedureLocked
                          ? "lockSensitiveProcedureImages"
                          : ""
                        } procedure-image-second`}
                      onClick={(e) =>
                        !this.state.isProcedureLocked &&
                        this.props.openLightbox(1, e, carouselImages)
                      }
                      src={carouselImages[1].thumbnail}
                    />
                    <img
                      title={carouselImages[2].caption}
                      className={`procedure-image-group ${this.state.isProcedureLocked
                          ? "lockSensitiveProcedureImages"
                          : ""
                        } procedure-image-third`}
                      onClick={(e) =>
                        !this.state.isProcedureLocked &&
                        this.props.openLightbox(2, e, carouselImages)
                      }
                      src={carouselImages[2].thumbnail}
                    />
                  </div>
                  {this.props.hobj.type !== "laser" && (
                    <div className="item">
                      <img
                        title={carouselImages[3].caption}
                        className={`procedure-image-group ${this.state.isProcedureLocked
                            ? "lockSensitiveProcedureImages"
                            : ""
                          } procedure-image-first`}
                        onClick={(e) =>
                          !this.state.isProcedureLocked &&
                          this.props.openLightbox(3, e, carouselImages)
                        }
                        src={carouselImages[3].thumbnail}
                      />
                      <img
                        title={carouselImages[1].caption}
                        className={`procedure-image-group ${this.state.isProcedureLocked
                            ? "lockSensitiveProcedureImages"
                            : ""
                          } procedure-image-second`}
                        onClick={(e) =>
                          !this.state.isProcedureLocked &&
                          this.props.openLightbox(1, e, carouselImages)
                        }
                        src={carouselImages[1].thumbnail}
                      />
                      <img
                        title={carouselImages[4].caption}
                        className={`procedure-image-group ${this.state.isProcedureLocked
                            ? "lockSensitiveProcedureImages"
                            : ""
                          } procedure-image-third`}
                        onClick={(e) =>
                          !this.state.isProcedureLocked &&
                          this.props.openLightbox(4, e, carouselImages)
                        }
                        src={carouselImages[4].thumbnail}
                      />
                    </div>
                  )}
                  {this.props.hobj.type === "coolsculpting" && (
                    <div className="item">
                      <img
                        title={carouselImages[5].caption}
                        className={`procedure-image-group ${this.state.isProcedureLocked
                            ? "lockSensitiveProcedureImages"
                            : ""
                          } procedure-image-first`}
                        onClick={(e) =>
                          !this.state.isProcedureLocked &&
                          this.props.openLightbox(5, e, carouselImages)
                        }
                        src={carouselImages[5].thumbnail}
                      />
                      <img
                        title={carouselImages[6].caption}
                        className={`procedure-image-group ${this.state.isProcedureLocked
                            ? "lockSensitiveProcedureImages"
                            : ""
                          } procedure-image-second`}
                        onClick={(e) =>
                          !this.state.isProcedureLocked &&
                          this.props.openLightbox(6, e, carouselImages)
                        }
                        src={carouselImages[6].thumbnail}
                      />
                      <img
                        title={carouselImages[7].caption}
                        className={`procedure-image-group ${this.state.isProcedureLocked
                            ? "lockSensitiveProcedureImages"
                            : ""
                          } procedure-image-third`}
                        onClick={(e) =>
                          !this.state.isProcedureLocked &&
                          this.props.openLightbox(7, e, carouselImages)
                        }
                        src={carouselImages[7].thumbnail}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.isTouchMd == 1 &&
          this.state.plan_selected == "practice_booking"
          ? ""
          : ((this.props.hobj?.injections &&
            this.props.hobj?.injections?.length > 0) ||
            ((this.props.hobj.type === "laser" ||
              this.props.hobj.type === "coolsculpting") &&
              this.props.hobj.procedure_information &&
              this.props.hobj.procedure_information?.length > 0)) && (
            <div className="row p-b-10 p-t-0">
              <div className="col-md-12">
                <div className="health-smry-outer auto-height m-b-25">
                  <h4 className="title-1 m-b-10">Treatment Summary</h4>
                  <ul className="treat-sumry profile-treat-sumry no-padding">
                    {this.props.hobj?.injections !== undefined &&
                      this.props.hobj?.injections?.map((injobj, injidx) => {
                        return (
                          <li key={injidx} className="treat-sumry-li">
                            <label>{injobj.product_name}</label>{" "}
                            <span>
                              {injobj.quantity
                                ? toFixed(Number(injobj.quantity), getPrecision(Number(injobj.quantity)) || 1)
                                : ""}{" "}
                              {injobj.unit}
                            </span>
                          </li>
                        );
                      })}

                    {(this.props.hobj.type === "laser" ||
                      this.props.hobj.type === "coolsculpting") &&
                      this.props.hobj.procedure_information !== undefined &&
                      this.props.hobj.procedure_information?.map(
                        (pojobj, poidx) => {
                          return (
                            <React.Fragment key={poidx}>
                              {this.props.hobj.type === "coolsculpting" &&
                                pojobj &&
                                pojobj.field == "Serial Number" &&
                                !pojobj.value ? (
                                ""
                              ) : (
                                <li
                                  key={`li_` + poidx}
                                  className="treat-sumry-li"
                                >
                                  <label>{pojobj.field}</label>{" "}
                                  <span>
                                    {pojobj.value && isNumber(pojobj.value)
                                      ? numberFormat(pojobj.value, "decimal")
                                      : pojobj.value}{" "}
                                    {pojobj.unit}
                                  </span>
                                </li>
                              )}
                            </React.Fragment>
                          );
                        },
                      )}
                    {this.props.hobj.type !== "laser" &&
                      this.props.hobj.type !== "coolsculpting" &&
                      this.props.hobj?.injections !== undefined &&
                      this.props.hobj?.injections?.length === 0 && (
                        <li className="treat-sumry-li">
                          <label className="sorry-no-record">
                            {
                              this.state.languageData
                                .clientprofile_no_rec_found
                            }{" "}
                          </label>
                        </li>
                      )}
                    {(this.props.hobj.type === "laser" ||
                      this.props.hobj.type === "coolsculpting") &&
                      this.props.hobj?.injections !== undefined &&
                      this.props.hobj?.injections?.length === 0 &&
                      this.props.hobj.procedure_information !== undefined &&
                      this.props.hobj.procedure_information?.length === 0 && (
                        <li className="treat-sumry-li">
                          <label className="sorry-no-record">
                            {
                              this.state.languageData
                                .clientprofile_no_rec_found
                            }{" "}
                          </label>
                        </li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          )}
        {this.props.hobj.scriptsure_prescription_medicine?.length > 0 && (
          <div className="row p-b-10 p-t-0">
            <div className="col-md-12">
              <div className="health-smry-outer auto-height m-b-25">
                {this.props.hobj.scriptsure_prescription_medicine?.length >
                  0 && <h4 className="title-1 m-b-10">Prescriptions</h4>}
                {this.props.hobj.scriptsure_prescription_medicine?.length >
                  0 && (
                    <ul className="treat-sumry profile-treat-sumry no-padding">
                      {this.props.hobj.scriptsure_prescription_medicine?.map(
                        (ppObj, ppIdx) => {
                          return (
                            <li
                              key={"procedure-health-ePrescribe" + ppIdx}
                              className="treat-sumry-li"
                            >
                              <label style={{ width: "100%" }}>
                                {ppObj.medicine}
                              </label>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  )}
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-xs-12">
            <div className="action-button-wrapper">
              <div className="action-button-inner">
                <div className="col-sm-3 col-xs-12 action-button-section">
                  <h3 className="action-button-title">Paperwork</h3>
                  <ul className="action-button-ul">
                    {this.props.hobj?.patient_questionnaires_count > 0 ? (
                      <li
                        className="action-button-li"
                        onClick={() =>
                          this.props.viewQuestionnaires(this.props.hobj.id)
                        }
                      >
                        Questionnaires
                        <div className="d-flex align-center gap-4 justify-end">
                          {this.props.hobj
                            ?.patient_questionnaires_not_filled_count > 0 && (
                              <div data-tip data-for="pendingQuestionnaires">
                                <i
                                  className="fa fa-pencil-alt"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            )}
                          <i
                            className="fa fa-check pull-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </li>
                    ) : (
                      <li className="action-button-li">
                        Questionnaires{" "}
                        <i
                          className="fa fa-times pull-right"
                          aria-hidden="true"
                        ></i>
                      </li>
                    )}
                    <Tooltip id="pendingQuestionnaires" aria-haspopup="true">
                      Some questionnaires are not filled
                    </Tooltip>
                    {this.props.hobj.patient_consents_count > 0 ? (
                      <li
                        className="action-button-li"
                        onClick={() =>
                          this.props.viewConsents(this.props.hobj.id)
                        }
                      >
                        Consent
                        <div className="d-flex align-center gap-4 justify-end">
                          {this.props.hobj?.patient_consents_not_signed_count > 0 && (
                            <div data-tip data-for="pendingWitnessSignature">
                              <i
                                className="fa fa-pencil-alt"
                                aria-hidden="true"
                              ></i>
                            </div>
                          )}
                          <i
                            className="fa fa-check"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </li>
                    ) : (
                      <li className="action-button-li">
                        Consent
                        <i
                          className="fa fa-times pull-right"
                          aria-hidden="true"
                        ></i>
                      </li>
                    )}
                    <Tooltip id="pendingWitnessSignature" aria-haspopup="true">
                      Some consents are awaiting for witness signature
                    </Tooltip>
                    {canSendPosInstructions ? (
                      <li
                        className="action-button-li"
                        onClick={() =>
                          this.props.showSendInstructionPopup(
                            this.props.hobj.appointment_id,
                            this.props.hobj.id,
                          )
                        }
                      >
                        {this.state.languageData.clientprofile_send_post}{" "}
                        <i
                          className="fa fa-check pull-right"
                          aria-hidden="true"
                        ></i>
                      </li>
                    ) : (
                      <li className="action-button-li">
                        <span className="action-button-li__text">
                          {this.state.languageData.clientprofile_send_post}{" "}
                        </span>
                        <i
                          className="fa fa-times pull-right"
                          aria-hidden="true"
                        ></i>
                      </li>
                    )}
                    {this.state.ePrescriptionSetup != null &&
                      this.state.ePrescriptionSetup.e_prescription_new && (
                        <li
                          className="action-button-li"
                          onClick={() =>
                            this.props.handleEprescribe(this.props.hobj)
                          }
                        >
                          e-Prescribe
                        </li>
                      )}{" "}
                  </ul>
                </div>
                {this.state.isTouchMd == 1 &&
                  this.state.plan_selected == "practice_booking" ? (
                  ""
                ) : (
                  <div className="col-sm-3 col-xs-12 action-button-section">
                    <h3 className="action-button-title">{"Photos & Videos"}</h3>
                    <ul className="action-button-ul">
                      <li
                        onClick={this.runCarousel.bind(this, totalImages)}
                        className="action-button-li"
                      >{`${this.state.languageData.clientprofile_more_photos} (${totalImages})`}</li>
                      {this.props.hobj?.ba_videos_count === 1 &&
                        getIsAcceleratorPlan() === true ? (
                        <li
                          className="action-button-li"
                          onClick={() =>
                            this.props.viewBAVideos(this.props.hobj.id)
                          }
                        >
                          {this.state.languageData.clientprofile_view_bavideos}{" "}
                          <i
                            className="fa fa-check pull-right"
                            aria-hidden="true"
                          ></i>
                        </li>
                      ) : (
                        <li className="action-button-li">
                          {this.state.languageData.clientprofile_view_bavideos}{" "}
                          <i
                            className="fa fa-times pull-right"
                            aria-hidden="true"
                          ></i>
                        </li>
                      )}
                      <li
                        className={`${this.state.isProcedureLocked ? "disable" : ""
                          } p-r-0 action-button-li`}
                        onClick={() =>
                          !this.state.isProcedureLocked &&
                          this.props.handleChildState({
                            procedureIdForPopup: this.props.hobj.id,
                            isShowCosmeticPhotoMarkingFlag: true,
                          })
                        }
                      >
                        Treatment Markings
                      </li>
                      {this.props.hobj?.extra_images?.length > 0 && (
                        <li
                          className="action-button-li p-r-0"
                          onClick={(e) =>
                            this.props.openLightbox(
                              0,
                              e,
                              this.formatOpenGalleryArray(),
                            )
                          }
                        >
                          View Gallery
                        </li>
                      )}
                    </ul>
                  </div>
                )}
                {this.state.isTouchMd == 1 &&
                  this.state.plan_selected == "practice_booking" ? (
                  ""
                ) : (
                  <div className="col-sm-3 col-xs-12 action-button-section">
                    <h3 className="action-button-title">Payment</h3>
                    <ul className="action-button-ul">
                      {this.invoiceStatusIcon}
                    </ul>
                  </div>
                )}
                {this.state.isTouchMd == 1 &&
                  this.state.plan_selected == "practice_booking" ? (
                  ""
                ) : (
                  <div className="col-sm-3 col-xs-12 action-button-section">
                    <h3 className="action-button-title">More</h3>
                    <ul className="action-button-ul">
                      {checkIfPermissionAllowed("manage-tracebility-info") && (
                        <li
                          className="action-button-li p-r-0"
                          onClick={() =>
                            this.props.viewTraceAbility(this.props.hobj.id)
                          }
                        >
                          {"Traceability"}
                        </li>
                      )}
                      <li
                        className="action-button-li p-r-0"
                        onClick={() => this.props.viewNotes(this.props.hobj.id)}
                      >
                        View Notes ({this.props.hobj.procedure_notes_count})
                      </li>
                      {this.props.hobj.survey_appointment_id && (
                        <li
                          className="action-button-li p-r-0"
                          onClick={() =>
                            this.props.viewFilledSurvey(
                              this.props.hobj.id,
                              this.props.hobj.survey_appointment_id,
                            )
                          }
                        >
                          {this.state.languageData.clientprofile_survey_filled}
                        </li>
                      )}{" "}
                      {checkIfPermissionAllowed("manage-procedures") && (
                        <li
                          className={`${this.state.isProcedureLocked ? "disable" : ""
                            } p-r-0 action-button-li`}
                          onClick={() =>
                            !this.state.isProcedureLocked &&
                            this.props.openProcedureDetail(
                              this.props.hobj.id,
                              this.props.hobj.procedure_type,
                            )
                          }
                        >
                          Edit Procedure
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCurrentAccountQuery(CosmeticTimeline);
