import React from "react";
import classes from "./Footer.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { useClientQuery } from "../../../../api/queries/useClientQuery";

export function Footer({ witness, patient }) {
  const { tCommon } = useAppTranslation.Common();
  const { data: patientQueryData } = useClientQuery({
    clientId: patient.id,
  });

  return (
    <div className={classes.root}>
      <div>
        {witness.signatureUrl && (
          <div className={classes.signatureBlock}>
            <img src={witness.signatureUrl} alt="witness-signature" />
            <span>{tCommon("label.witnessSignature")}</span>
            {witness.name}
          </div>
        )}
      </div>
      <div>
        {patient.signatureUrl && (
          <div className={classes.signatureBlock}>
            <img src={patient.signatureUrl} alt="patient-signature" />
            <span>{tCommon("label.patientSignature")}</span>
            {patient.name || patientQueryData.fullName}
          </div>
        )}
      </div>
    </div>
  );
}
