import {
  PROCEDURE_CREATED_FROM,
  PROCEDURE_TYPE,
  useProcedureCreateUpdateMutation,
} from "../../../../../../../../api/procedure/useProcedureCreateUpdateMutation";
import { useCurrentUserQuery } from "../../../../../../../../api/queries/useUserQuery";
import { history } from "../../../../../../../../history";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../../../../services/UINotificationService";
import { getHealthPathParams } from "../../../HealthCreateEdit.utils";

export function useSubmit() {
  const { tCommon } = useAppTranslation.Common();
  const { procedureId, clientId } = getHealthPathParams();
  const { data: user } = useCurrentUserQuery();

  const createMutation = useProcedureCreateUpdateMutation({
    onError: () => {
      uiNotification.error(tCommon("error.createProcedure"));
    },
    onSuccess: () => {
      uiNotification.success(tCommon("success.createProcedure"));
      history.goBack();
    },
  });

  const initiate = async (form) => {
    return createMutation.mutateAsync({
      id: procedureId,
      patientId: clientId,
      procedureType: PROCEDURE_TYPE.health,
      procedureName: form.chiefComplaint,
      notes: form.notes,
      clinicId: user.clinicId,
      providerId: user.id,
      procedureDate: new Date(),
      createdFrom: PROCEDURE_CREATED_FROM.ePrescribe,
    });
  };

  return {
    initiate,
    isLoading: createMutation.isLoading,
  };
}
