import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    name: yup.string().required(),
    categoryId: yup.number().nullable(),
    consents: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
        }),
      )
      .required(),
    questionnaires: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
        }),
      )
      .required(),
  }),
);

const composeResponse = (res) => {
  let result = [];
  if (res && Array.isArray(res.data.data)) {
    result = res.data.data.map((i) => ({
      id: i.id,
      name: i.name,
      categoryId: i.category_id || null,
      consents: i.service_consents?.map((c) => ({
        id: c.consent_id,
      })),
      questionnaires: i.service_questionnaires?.map((q) => ({
        id: q.questionnaire_id,
      })),
    }));
  }
  return result;
};

export function createServicesWithRelatedConsentsAndQuestionnairesQueryKey() {
  return [QUERY_KEYS.servicesWithRelatedConsentsAndQuestionnaires];
}

export function useServicesWithRelatedConsentsAndQuestionnairesQuery(
  options = {},
) {
  return useQuery(
    createServicesWithRelatedConsentsAndQuestionnairesQueryKey(),
    async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.getServicesWithRelatedConsentsAndQuestionnaires(),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
