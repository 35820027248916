import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { getPathParams } from "../PatientQuestionnaires.utils";
import { usePatientQuestionnairesQuery } from "../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnairesQuery";

export function usePatientQuestionnaires() {
  const { tCommon } = useAppTranslation.Common();
  const { entityId, entityType } = getPathParams();

  const { data, isLoading, isError, refetch } = usePatientQuestionnairesQuery({
    payload: {
      entityId,
      entityType,
    },
    options: {
      onError: () => {
        uiNotification.error(tCommon("error.fetchQuestionnaires"));
      },
    },
  });

  const isEmpty = !isLoading && data?.length === 0;

  return {
    data: data || [],
    refetch,
    isLoading,
    isEmpty,
    isError,
    isVisible: !isLoading && !isEmpty,
    patientId: data?.[0]?.patient_id,
    appointmentId: data?.[0]?.appointment_id,
    procedureId: data?.[0]?.procedure_id,
  };
}
