import * as yup from "yup";

export const simpleQuestionSchema = yup.object({
  id: yup.number().required(),
  order_by: yup.number().required(),
  question: yup.string().nullable(),
  question_type: yup.string().nullable(),
  questionnaire_id: yup.number().required(),
  status: yup.number().required(),
  question_choices: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        choice_type: yup.string().required(),
        display_labels: yup.number().required(),
        image: yup.string().nullable(),
        image_label: yup.string().nullable(),
        image_url: yup.string().nullable(),
        multiple_selection: yup.number().required(),
        order_by: yup.number().required(),
        question_id: yup.number().required(),
        status: yup.number().required(),
        text: yup.string().nullable(),
      }),
    )
    .required(),
});

export const multiQuestionSchema = yup.object({
  id: yup.number().required(),
  description: yup.number().required(),
  description_text: yup.string().nullable(),
  order_by: yup.number().required(),
  question: yup.string().nullable(),
  question_type: yup.string().required(),
  questionnaire_id: yup.number().required(),
  required: yup.number().required(),
  status: yup.number().required(),
  why_choose: yup.number().required(),
  procedure_template_question_option: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        order_by: yup.number().required(),
        procedure_template_question_id: yup.number().required(),
        question_option: yup.string().nullable(),
        questionnaire_id: yup.number().required(),
        status: yup.number().required(),
      }),
    )
    .required(),
  procedure_templates_logic: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        jump_to_question: yup.number().nullable(),
        procedure_question_id: yup.number().required(),
        procedure_question_option_id: yup.number().nullable(),
        questionnaire_id: yup.number().required(),
        type: yup.string().required(),
      }),
    )
    .required(),
});

export const questionnaireSchema = yup.object({
  id: yup.number().required(),
  type: yup.number().required(),
  title: yup.string().required(),
  order_by: yup.number().required(),
  simple_questions: yup.array().of(simpleQuestionSchema).required(),
  multi_questions: yup.array().of(multiQuestionSchema).required(),
});
