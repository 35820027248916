import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { passOr, removeNullishFromShape } from "../../utilities/general";

export const CREATE_EXAM_INVITE_ADDITION_PATIENT_FIELDS = {
  dateOfBirth: "dob",
  email: "email",
  phone: "phoneNumber",
  firstName: "firstname",
  lastName: "lastname",
  gender: "gender",
  address: "address_line_1",
  city: "city",
  state: "state",
  pincode: "pincode",
  shippingAddress: "shipping_address_line_1",
  shippingCity: "shipping_city",
  shippingState: "shipping_state",
  shippingZipcode: "shipping_zip_code",
};

const requestSchema = yup.object({
  clinicId: yup.number().required(),
  exams: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
        rx: yup.number().required(),
      }),
    )
    .required(),
  patient: yup
    .object({
      id: yup.number().required(),
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      email: yup.string().nullable(),
      phone: yup.string().nullable(),
      dateOfBirth: yup.string().nullable(),
      gender: yup.number().nullable(),
      address: yup.string().nullable(),
      city: yup.string().nullable(),
      state: yup.string().nullable(),
      pincode: yup.string().nullable(),
      shippingAddress: yup.string().nullable(),
      shippingCity: yup.string().nullable(),
      shippingState: yup.string().nullable(),
      shippingZipcode: yup.string().nullable(),
    })
    .required(),
  appointmentId: yup.number().nullable(),
  requestNew: yup.bool().nullable(),
  attachments: yup.array().of(yup.string()).nullable(),
});

export function useQualiphyCreateExamInviteMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(
      HTTP_ENDPOINTS.qualiphy.createExamInvite(),
      removeNullishFromShape({
        patient_id: req.patient.id,
        clinic_id: req.clinicId,
        exams: req.exams.map((exam) => ({
          id: exam.id,
          name: exam.name,
          rx: exam.rx,
        })),
        dob: req.patient.dateOfBirth,
        email: req.patient.email,
        phoneNumber: req.patient.phone,
        firstname: req.patient.firstName,
        lastname: req.patient.lastName,
        gender: req.patient.gender,
        address_line_1: req.patient.address,
        city: req.patient.city,
        state: req.patient.state,
        pincode: req.patient.pincode,
        shipping_address_line_1: req.patient.shippingAddress,
        shipping_city: req.patient.shippingCity,
        shipping_state: req.patient.shippingState,
        shipping_zip_code: req.patient.shippingZipcode,
        appointment_id: req.appointmentId,
        request_new: passOr(typeof req.requestNew === "boolean", null, () =>
          req.requestNew ? 1 : 0,
        ),
        attachments: req.attachments,
      }),
    );
  }, options);
}
