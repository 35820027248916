import React, { useMemo } from "react";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";
import { useMultiselectEntity } from "../../../../utilities/hooks/useMultiselectEntity";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { PROCEDURE_STATE_TYPES } from "../../../../consts/general";
import { unwrapOr } from "../../../../utilities/general";
import { CompleteIcon } from "../../components/CompleteIcon/CompleteIcon";
import { ProcedureTableInfo } from "../../components/ProcedureTableInfo/ProcedureTableInfo";
import { CompleteLink } from "../../components/CompleteLink/CompleteLink";
import {
  getProcedureRoutes,
  getProcedureStatuses,
} from "../../ProviderRoom.utils";
import { ProcedureTreatmentSummary } from "../../components/ProcedureTreatmentSummary/ProcedureTreatmentSummary";
import parentClasses from "../../sass/ProviderRoom.module.scss";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";

const type = PROCEDURE_STATE_TYPES.sentToMd;

export function useTableCosmetic({
  procedures = [],
  showHideActionButton,
  providerUserId,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const {
    selected,
    onToggleOne,
    onToggleAll,
    isSelected,
    isSelectedAll,
    isSelectedPartial,
    resetSelected,
  } = useMultiselectEntity({
    allEntities: procedures.map((p) => p.id),
    initialSelect: [],
    isSelectAllOnLoadMore: true,
  });

  const isScriptsureEnabled = unwrapOr(
    () => Boolean(user.account.integrations.isScriptsureEnabled),
    false,
  );

  const cols = [
    {
      data: (
        <Checkbox
          inverted
          size="small"
          isChecked={isSelectedAll()}
          isIntermediate={isSelectedPartial()}
          onChange={onToggleAll}
        />
      ),
      accessor: "selectCheck",
    },
    {
      data: tCommon("providerRoom.table.procedureInfo"),
      accessor: "info",
    },
    {
      data: tCommon("providerRoom.table.mdName"),
      accessor: "mdName",
    },
    {
      data: tCommon("providerRoom.table.patient"),
      accessor: "patientName",
    },
    {
      data: tCommon("providerRoom.table.clinic"),
      accessor: "clinicName",
    },
    {
      data: tCommon("providerRoom.table.treatmentSummary"),
      accessor: "treatmentSummary",
    },
    {
      data: tCommon("providerRoom.table.latestNote"),
      accessor: "hasLatestNote",
      headColClassName: parentClasses.tableHeadCentered,
    },
    {
      data: tCommon("providerRoom.table.consent"),
      accessor: "hasConsent",
      headColClassName: parentClasses.tableHeadCentered,
    },
    {
      data: tCommon("providerRoom.table.questionnaire"),
      accessor: "hasQuestionnaire",
      headColClassName: parentClasses.tableHeadCentered,
    },
    ...(isScriptsureEnabled
      ? [
          {
            data: tCommon("providerRoom.table.prescription"),
            accessor: "hasPrescription",
            headColClassName: parentClasses.tableHeadCentered,
          },
        ]
      : []),
    {
      data: tCommon("providerRoom.table.consultOnly"),
      accessor: "consultOnly",
      headColClassName: parentClasses.tableHeadCentered,
    },
  ];
  const data = useMemo(
    () =>
      procedures.map((p) => {
        const { hasNotes, hasConsent, hasQuestionnaire, hasPrescription } =
          getProcedureStatuses(p);

        const {
          infoRoute,
          notesRoute,
          consentRoute,
          questionnaireRoute,
          prescriptionRoute,
          viewQuestionnaireRoute,
        } = getProcedureRoutes({
          procedure: p,
          type,
        }).cosmetic;
        const routeWithState = {
          pathname: infoRoute,
          state: {
            chartUserId: providerUserId,
            isFromChartAuditReport: showHideActionButton,
          },
        };
        return {
          key: p.id,

          selectCheck: (
            <Checkbox
              size="small"
              isChecked={isSelected(p.id)}
              onChange={() => onToggleOne(p.id)}
              isDisabled={showHideActionButton}
            />
          ),

          info: (
            <ProcedureTableInfo
              title={p.name}
              rawDate={p.date}
              link={
                showHideActionButton || providerUserId
                  ? routeWithState
                  : infoRoute
              }
            />
          ),

          mdName: unwrapOr(() => p.mdUser.fullName, tCommon("symbol.longDash")),

          patientName: unwrapOr(
            () => p.patient.fullName,
            tCommon("symbol.longDash"),
          ),

          clinicName: unwrapOr(
            () => p.clinic.clinic_name,
            tCommon("symbol.longDash"),
          ),

          treatmentSummary: (
            <ProcedureTreatmentSummary
              injectionsList={p.injectionList}
              info={p.info}
            />
          ),

          hasLatestNote: (
            <CompleteLink
              isCompleted={hasNotes}
              to={notesRoute}
              isDisabled={showHideActionButton}
            >
              <CompleteIcon isCompleted={hasNotes} />
            </CompleteLink>
          ),

          hasConsent: hasConsent ? (
            <CompleteLink
              isCompleted={hasConsent}
              to={consentRoute}
              isDisabled={showHideActionButton}
            >
              <CompleteIcon isCompleted={hasConsent} />
            </CompleteLink>
          ) : (
            <CompleteIcon isCompleted={hasConsent} />
          ),

          hasQuestionnaire: (
            <CompleteLink
              isCompleted={hasQuestionnaire}
              to={
                hasQuestionnaire ? questionnaireRoute : viewQuestionnaireRoute
              }
              isDisabled={showHideActionButton}
            >
              <CompleteIcon isCompleted={hasQuestionnaire} />
            </CompleteLink>
          ),

          hasPrescription: hasPrescription ? (
            <CompleteLink
              isCompleted={hasPrescription}
              to={prescriptionRoute}
              isDisabled={showHideActionButton}
            >
              <CompleteIcon isCompleted={hasPrescription} />
            </CompleteLink>
          ) : (
            <CompleteIcon isCompleted={hasPrescription} />
          ),

          consultOnly: (
            <div className={parentClasses.tableHeadCentered}>
              {p.isConsult ? tCommon("label.yes") : tCommon("label.no")}
            </div>
          ),
        };
      }),
    [procedures, selected],
  );

  return {
    cols,
    data,
    selected,
    resetSelected,
  };
}
