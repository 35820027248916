import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {
  getClientDocuments,
  deleteClientDocument,
  exportEmptyData,
  getAgreementDetails,
  exportProcedureData,
  showFile,
  downloadPrescriptionPdf,
  exportMembershipDocument,
  exportConsentDocument, 
  downloadEfaxPdf
} from '../../../Actions/Clients/clientsAction.js';
import {
  showFormattedDate,
  positionFooterCorrectly,
  autoScrolling,
  checkIfPermissionAllowed,
} from "../../../Utils";
import calenLogo from '../../../_legacy/images/calender.svg';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { isEqual } from "lodash";
import './client-documents.scss';
import { withFilesToDownload } from "./withFilesToDownload.js";
import { DownloadFiles } from "./components/DownloadFiles.js";
import LockProcedure from "../Timeline/LockProcedure.js";
import { ROUTES } from "../../../consts/routes.js";
import { uiNotification } from "../../../services/UINotificationService.js";
import { SortAdornment } from "../../../shared/SortAdornment/SortAdornment.js";
import VIEW_ICON from "./icons/view.svg";
import TIMER_ICON from "./icons/TimerIcon.js";

const apiDateFormat = (date) => {
  return moment(date).format('YYYY-MM-DD');
}

class ClientDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;

    window.onscroll = () => {
      const scrollTop = parseInt(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
      if (document.documentElement.offsetHeight - (window.innerHeight + scrollTop) <= 5 && this.state.next_page_url != null) {
        this.loadMore();
      }
    };
  }
  get initialState() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    const rdfDateRange = localStorage.getItem('documentSearchDateRange');
    const valR = rdfDateRange ? JSON.parse(rdfDateRange) : '';
    const startDate = valR.startDate
    const endDate = valR.endDate
    return {
      clientsLang: languageData.clients,
      globalLang: languageData.global,
      settingLang: languageData.settings,
      showLoader: false,
      backURLType: (this.props.match.params.actionType) ? this.props.match.params.actionType : 'clients',
      clientID: this.props.match.params.clientID,
      actionType: (this.props.match.params.actionType) ? this.props.match.params.actionType : 'profile',
      isRender: false,
      isShowDeleteModal: false,
      deletedDocumentId: 0, // use for deletetion, updation

      documentData: {},
      documentList: [],
      clientName: '',
      showCalendar: false,
      startDate: new Date(),
      endDate: new Date(),
      clicked: 0,
      dateRangePicker: {
        selection: {
          startDate: (startDate) ? new Date(startDate) : moment().startOf('year'),
          endDate: (endDate) ? new Date(endDate) : moment().endOf('year'),
          key: 'selection',
        },
      },
      to_date: (endDate) ? moment(endDate).format('YYYY-MM-DD') : moment().endOf('year').format('YYYY-MM-DD'),
      from_date: (startDate) ? moment(startDate).format('YYYY-MM-DD') : moment().startOf('year').format('YYYY-MM-DD'),
      showAgreement: false,
      object_name: localStorage.getItem('object_name') ? localStorage.getItem('object_name') : '',
      term: localStorage.getItem('term') ? localStorage.getItem('term') : '',
      page: 1,
      pagesize: 20,
      next_page_url: '',
      isTouchMD: userData.account.account_preference.is_touch_md,
      plan_selected: userData.account.account_subscription.plan_code,
      isDownloadRequestTimeoutError: false,
      showExpirationModal: null,
      sort_column: "date",
      sort_order: "desc",
      expired: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ 'showLoader': true })
    let isRender = false;
    let clientID = this.props.match.params.clientID;
    if (clientID === undefined) {
      clientID = 0;
      isRender = true;
    }
    let actionType = this.props.match.params.type;
    if (actionType === undefined) {
      actionType = '';
    }
    this.setState({ clientID: clientID, actionType: actionType, isRender: isRender })
    if (clientID) {
      const formData = {
        params: {
          patient_id: clientID,
          from: this.state.from_date,
          to: this.state.to_date,
          filter: this.state.object_name ? this.state.object_name : '',
          term: this.state.term ? this.state.term : '',
          page: this.state.page,
          pagesize: this.state.pagesize,
          sort_column: this.state.sort_column,
          sort_order: this.state.sort_order,
          expired: this.state.expired,

        }
      }
      this.props.getClientDocuments(formData);
      document.addEventListener('click', this.handleClick, false);
      positionFooterCorrectly();
    }
  }

  refetchDocuments = () => {
    let clientID = this.props.match.params.clientID;
    if (clientID) {
      const formData = {
        params: {
          patient_id: clientID,
          from: this.state.from_date,
          to: this.state.to_date,
          filter: this.state.object_name ? this.state.object_name : '',
          term: this.state.term ? this.state.term : '',
          page: 1,
          pagesize: this.state.pagesize,
          sort_column: this.state.sort_column,
          sort_order: this.state.sort_order,
          expired: this.state.expired,
        }
      }
      this.setState({ documentList: [], page: 1, showLoader: true })
      this.props.getClientDocuments(formData);
    }
  }

  handleClick = (e) => {
    if (this.state.showCalendar === true && this.node.contains(e.target)) {
      return
    }
    this.toggleCalendar(e.target);
  }

  loadMore = () => {
    if (!autoScrolling()) {
      this.setState({ showLoader: true, showLoadingText: true });
      let formData = {
        params: {
          patient_id: this.props.match.params.clientID,
          from: this.state.from_date,
          to: this.state.to_date,
          filter: this.state.object_name ? this.state.object_name : '',
          term: this.state.term ? this.state.term : '',
          page: this.state.page,
          pagesize: this.state.pagesize,
          sort_column: this.state.sort_column,
          sort_order: this.state.sort_order,
          expired: this.state.expired,
        }
      }
      if (this.state.expired) {
        formData.params.expired = true;
      }
      autoScrolling(true);
      this.props.getClientDocuments(formData);
    }
  }

  toggleCalendar = (elem) => {
    if (elem.name !== 'calendar-input' && this.state.showCalendar === false) {
      return
    }

    let showCalendar

    showCalendar = this.state.showCalendar === false && elem.name !== undefined && elem.name === 'calendar-input';

    this.setState({ showCalendar: showCalendar })
  }

  handleRangeChange = (which, payload) => {
    if (payload) {
      let payloadValue = {
        startDate: payload.selection.startDate,
        endDate: payload.selection.endDate,
        key: 'selection',
      }
      localStorage.setItem("documentSearchDateRange", JSON.stringify(payloadValue));
    }
    let startDate = payload.selection.startDate
    let endDate = payload.selection.endDate
    startDate = apiDateFormat(startDate)
    endDate = apiDateFormat(endDate)

    let clicked = this.state.clicked + 1;

    let localPref = localStorage.getItem('focusedRange');
    let canBypass = !!(localPref && localPref === 'oneClick');

    if (canBypass) {
      clicked = 2;
    }

    let showCalendar = true;

    if (clicked % 2 === 0) {
      showCalendar = false;
    }

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
      showCalendar: showCalendar,
      from_date: startDate,
      to_date: endDate,
      clicked: clicked
    });

    if (clicked && clicked % 2 === 0) {
      this.setState({ showLoader: true })
      const formData = {
        params: {
          patient_id: this.props.match.params.clientID,
          filter: this.state.object_name ? this.state.object_name : '',
          from: startDate,
          to: endDate,
          term: this.state.term,
          page: 1,
          pagesize: this.state.pagesize,
          sort_column: this.state.sort_column,
          sort_order: this.state.sort_order,
          expired: this.state.expired,
        }
      }
      this.setState({ page: 1, documentList: [] })
      this.props.getClientDocuments(formData);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.documentData !== undefined && nextProps.documentData !== prevState.documentData && nextProps.documentDataTime !== prevState.documentDataTime) {
      nextProps.exportEmptyData()
      returnState.documentData = nextProps.documentData
      returnState.clientName = nextProps.documentData.patient ? nextProps.documentData.patient.firstname + ' ' + nextProps.documentData.patient.lastname : '';
      returnState.showLoader = false
      if (prevState.documentList.length === 0) {
        returnState.documentDataTime = nextProps.documentDataTime;
        returnState.showLoader = false;
        if (nextProps.documentData.next_page_url != null) {
          returnState.page = prevState.page + 1;
          returnState.next_page_url = '';
        } else {
          returnState.next_page_url = null;
        }
      } else {
        returnState.documentDataTime = nextProps.documentDataTime;
        returnState.showLoader = false;
        if (nextProps.documentData.next_page_url != null) {
          returnState.next_page_url = ''
          returnState.page = prevState.page + 1;
        } else {
          returnState.next_page_url = null;
        }
      }
      autoScrolling(false);
      nextProps.exportEmptyData()
    } else if (nextProps.showLoader !== undefined && nextProps.showLoader === false) {
      nextProps.exportEmptyData()
      returnState.showLoader = false
    }

    if (nextProps.agreementData !== undefined && nextProps.agreementDataTime !== prevState.agreementDataTime) {
      returnState.agreementText = nextProps.agreementData.agreement_text;
      returnState.agreementDataTime = nextProps.agreementDataTime;
      returnState.patient_signature_url = nextProps.agreementData.patient_signature_url;
      returnState.signed_on = nextProps.agreementData.signed_on;
      returnState.patient_name = nextProps.agreementData.patient ? nextProps.agreementData.patient.firstname + ' ' + nextProps.agreementData.patient.lastname : ''
    }

    if (nextProps.exportProcedureDetails !== undefined && nextProps.exportProcedureDataTime !== prevState.exportProcedureDataTime) {
      returnState.showLoader = false;
      returnState.exportProcedureDataTime = nextProps.exportProcedureDataTime;
    }

    if (nextProps.clientDocumentData !== undefined && nextProps.clientDocumentDataTime !== prevState.clientDocumentDataTime) {
      returnState.clientDocumentDataTime = nextProps.clientDocumentDataTime;
      returnState.showLoader = false;
    }

    if (nextProps.deleteData !== undefined && nextProps.deleteDataTime !== prevState.deleteDataTime) {
      let formData = {
        params: {
          patient_id: prevState.clientID,
          filter: prevState.object_name,
          from: prevState.from_date,
          to: prevState.to_date,
          term: prevState.term,
          page: 1,
          pagesize: prevState.pagesize,
          sort_column: prevState.sort_column,
          sort_order: prevState.sort_order,
          expired: prevState.expired,
        }
      }
      returnState.page = 1;
      nextProps.getClientDocuments(formData);
    }

    if (nextProps.exportMembershipData.status === 'loading') {
      returnState.showLoader = true;
    }

    if (nextProps.exportConsentData.status === 'loading') {
      returnState.showLoader = true;
    }

    return returnState
  }

  componentDidUpdate(prevProps) {
    const languageData = JSON.parse(localStorage.getItem('languageData'));

    if (prevProps.exportMembershipData.status !== "error" && this.props.exportMembershipData.status === "error") {
      uiNotification.error(languageData.global[this.props.exportMembershipData.error] || languageData.global["server_error"]);
      this.setState({ showLoader: false })
    }

    if (prevProps.exportMembershipData.status !== "success" && this.props.exportMembershipData.status === "success") {
      window.open(this.props.exportMembershipData.downloadLink);
      this.setState({ showLoader: false })
    }

    if (prevProps.exportConsentData.status !== "error" && this.props.exportConsentData.status === "error") {
      if (this.props.exportConsentData.error === "consent_not_found") {
        uiNotification.error("Consent not signed or doesn't exist");
        this.setState({ showLoader: false })
      } else {
        uiNotification.error(languageData.global[this.props.exportConsentData.error] || languageData.global["server_error"]);
        this.setState({ showLoader: false })
      }
    }

    if (prevProps.exportConsentData.status !== "success" && this.props.exportConsentData.status === "success") {
      window.open(this.props.exportConsentData.downloadLink);
      this.setState({ showLoader: false })
    }

    if (prevProps.exportProcedureDetails.status === 'loading' && this.props.exportProcedureDetails.status === 'success') {
      this.setState({ showLoader: false })
      window.open(this.props.exportProcedureDetails.data.data)
    }
    if (prevProps.clientDocumentData.status === 'loading' && this.props.clientDocumentData.status === 'success') {
      this.setState({ showLoader: false })
      window.open(this.props.clientDocumentData.data.data.document.download_path);
    }
    if (this.state.documentList.length === 0 && this.props.documentData) {
      this.setState({ documentList: [...this.props.documentData.data] })
    }
    if (this.state.documentList.length !== 0 && this.props.documentData) {
      if (!isEqual(this.state.documentList, this.props.documentData.data)) {
        this.setState({ documentList: [...this.state.documentList, ...this.props.documentData.data] })
      }
    }
  }

  AddClientDocuments = () => {
    return (
      <div>
        {this.props.history.push(`/clients/documents/add/${this.state.clientID}/profile`)}
      </div>
    )
  }

  EditClientDocuments = (id) => {
    return (
      <div>
        {this.props.history.push(`/clients/documents/edit/${id}/${this.state.clientID}/profile`)}
      </div>
    )
  }

  handleDeleteDocument = () => {
    this.toggleState('isShowDeleteModal')
    if (this.state.deletedDocumentId) {
      this.setState({ showLoader: true, documentList: [] })
      this.props.deleteClientDocument(this.state.deletedDocumentId)
    }
  }

  handleDownloadDocument = (obj) => {
    switch (obj.type) {
      case 'procedure': {
        this.setState({ showLoader: true })
        this.props.exportProcedureData(this.state.clientID, obj.parent_id);
        break;
      }
      case 'questionnaire': {
        this.props.history.push(ROUTES.patientQuestionnaires.one(obj.object_id));
        break;
      }
      case 'consent' : {
        if(obj.sub_type == 'appointment'){
          let consent_id = obj.object_id;
          this.props.history.push(ROUTES.patientConsents.one(consent_id));
        } else if(obj.sub_type == 'health'){
          let patient_consent_id = obj.object_id;
          this.props.history.push(ROUTES.patientConsents.one(patient_consent_id));
        } else {
          this.props.history.push(ROUTES.patientConsents.one(obj.object_id));
        }
        break;
      }
      case 'agreement': {
        this.setState({ showLoader: true, showAgreement: true });

        if (obj.sub_type && obj.sub_type === "expired_agreement") {
          this.props.getAgreementDetails(obj.parent_id, 'get-expired-agreement-data')
            .finally(() => this.setState({ showLoader: false }));
        } else {
          this.props.getAgreementDetails(obj.parent_id, 'get-agreement-data', obj.object_id)
            .finally(() => this.setState({ showLoader: false }));
        }

        break;

      }
      case 'file' : {
        this.setState({ showLoader : true })
        this.props.showFile(this.state.clientID, obj.object_id);
        break;
      };
      case "efax": 
        downloadEfaxPdf(obj.object_id, { params: { patient_id: this.state.clientID } } )
          .then(res => window.open(res.data.data.file, "_blank"));
      break;

      case "gfe": {
        this.setState({ showLoader: true })
        this.props.showFile(this.state.clientID, obj.object_id);
        break;
      }
      case 'ePrescription': {
        this.setState({ showLoader: true })
        this.props.downloadPrescriptionPdf(obj.object_id, { params: { patient_id: this.state.clientID } });
        break;
      }
      case 'health_questionnaries': {
        let appointment_id = obj.parent_id;
        let procedure_template_id = obj.object_id;
        this.setState({ showLoader: true });
        if (obj.sub_type === 'appointment') {
          this.props.history.push(`/clients/${this.state.clientID}/health-questionnaires/${procedure_template_id}/appointment/${appointment_id}`);
        } else {
          this.props.history.push(`/clients/${this.state.clientID}/health-questionnaires/${procedure_template_id}/procedure/${appointment_id}`);
        }
        break;
      }
      default:
        break;
    }
  }

  handleExportMembershipOrConsentDocument = (document) => {
    if (document.type === 'agreement') {
      this.props.exportMembershipDocument(document.parent_id, this.state.clientID, document.sub_type === "expired_agreement");
    } else if (document.type === 'consent') {
      this.props.exportConsentDocument(document.parent_id, document.object_id, this.state.clientID, document.sub_type);
    }
  }

  // key type of boolean
  toggleState = (key) => {
    this.setState({ [key]: !this.state[key] })
  }

  handleInputChange = (event) => {
    const name = event.target.name;
    this.setState({ [event.target.name]: event.target.value });
    if (name === 'object_name') {
      localStorage.setItem('object_name', event.target.value);
      this.setState({ showLoader: true, page: 1, documentList: [] })
      let formData = {
        params: {
          patient_id: this.props.match.params.clientID,
          filter: event.target.value,
          from: this.state.from_date,
          to: this.state.to_date,
          term: this.state.term,
          page: 1,
          pagesize: this.state.pagesize,
          sort_column: this.state.sort_column,
          sort_order: this.state.sort_order,
          expired: this.state.expired,
        }
      }
      this.props.getClientDocuments(formData);
    }
  }

  handleSearchSubmit = (event) => {
    event.preventDefault();
    this.setState({ showLoader: true });
    localStorage.setItem('term', this.state.term);
    let formData = {
      params: {
        patient_id: this.props.match.params.clientID,
        filter: this.state.object_name,
        from: this.state.from_date,
        to: this.state.to_date,
        term: this.state.term,
        page: 1,
        pagesize: this.state.pagesize,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        expired: this.state.expired,
      }
    }
    this.setState({ page: 1, documentList: [] })
    this.props.getClientDocuments(formData);
  }

  handleExpirationDates = (checked) => {
    let formData = {
      params: {
        patient_id: this.props.match.params.clientID,
        filter: this.state.object_name,
        from: this.state.from_date,
        to: this.state.to_date,
        term: this.state.term,
        page: 1,
        pagesize: this.state.pagesize,
        sort_column: checked ? "expiration_date" : "date",
        sort_order: checked ? "asc" : "desc",
        expired: checked
      }
    }
    this.setState({ page: 1, documentList: [], showLoader: true, expired: checked })
    this.props.getClientDocuments(formData);
  }

  handleAgreementClose = () => {
    this.setState({ showAgreement: false })
  }

  deleteDataOfClient = () => {
    localStorage.removeItem('documentSearchDateRange');
    localStorage.removeItem('object_name');
    localStorage.removeItem('term');
    this.props.history.push(`/clients/profile/${this.state.clientID}/1`);
  }

  addQuestionnairesPage = () => {
    this.props.history.push(`/documents/${this.props.match.params.clientID}/add/questionnaire`);
  }

  addConsentPage = () => {
    this.props.history.push(`/documents/${this.props.match.params.clientID}/add-consent`);
  }

  editClientQuestionnairesPage = (id) => {
    this.props.history.push(`/documents/${this.props.match.params.clientID}/edit/questionnaire/${id}`)
  }
 
  handleRefetchProcedure = () => {
    this.setState({ showLoader: true, page: 1, documentList: [] })
    this.props.getClientDocuments({
      params: {
        patient_id: this.props.match.params.clientID,
        from: this.state.from_date,
        to: this.state.to_date,
        filter: this.state.object_name ? this.state.object_name : '',
        term: this.state.term ? this.state.term : '',
        page: this.state.page,
        pagesize: this.state.pagesize,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        expired: this.state.expired,
      }
    })
  }
  handleTimerClick = (document) => {
    const { name, date, type, object_id: documentId, expiration_date: expirationDate, label, edited_by_firstname: editedByFirstName, edited_by_lastname: editedByLastName, updated_at: updateAt, parent_id: parentID, sub_type: subType, } = document;
    this.setState({ showExpirationModal: { name, date, type, documentId, expirationDate, label, editedByFirstName, editedByLastName, updateAt, parentID, subType } });
  }

  handleExpirationModalClose = () => {
    this.setState({ showExpirationModal: null })
  }

  getDaysUntilExpiration = (expirationDate) => {
    return moment(expirationDate).diff(moment(), "days");
  }

  handleSort = (column) => {
    this.setState(
      {
        sort_column: column.sort,
        sort_order: this.state.sort_order === "asc" ? "desc" : "asc",
      },
      () => this.refetchDocuments(),
    );
  };

  getEditedBy = (document) => {
    if(document.edited_by_firstname) {
      return `${document.edited_by_firstname} ${document.edited_by_lastname}`;
    } else if (document.added_by_firstname) {
      return `${document.added_by_firstname} ${document.added_by_lastname}`;
    } else {
      "N/A"
    }
  }

  get sortableColumns() {
    return [
      {
        title: "File Name",
        sort: "name",
      },
      {
        title: "File Type",
        sort: "label",
      },
      {
        title: "Origination Date and Time",
        sort: "date",
      },
      {
        title: "Expiration Date",
        sort: "expiration_date",
      },
      {
        title: "Editor",
        sort: "added_by_firstname",
      },
    ];
  }

  render() {
    const filesToDownload = this.props.filesToDownload;

    return (
      <div id="content" className="content-client-documents">
        <div className="container-fluid content setting-wrapper">
          <div className="setting-setion full-width m-t-15 m-b-15  auto-height">
            <div className="membership-title">
              <span className="cursor-pointer">Files - {this.state.clientName}</span>
              <a onClick={() => this.deleteDataOfClient()} className="pull-right">
                <img className="pull-right m-t-5" src="/images/close.png" alt=""/>
              </a>
            </div>
          </div>
          <div className="juvly-section full-width m-t-0">
            <div className="setting-search-outer documentSearch col-xs-12 col-lg-12">
              <form name="submitForm" className="nobottommargin" onSubmit={this.handleSearchSubmit}>
                <div className="search-bg new-search col-xs-3 col-lg-3">
                  <i className="fa fa-search"></i>
                  <input className="setting-search-input search-key" onChange={this.handleInputChange} placeholder="Search by name" name="term" autoComplete="off" value={this.state.term} />
                </div>
              </form>
              <div className='pull-left'>
                <div className="filter-type">
                  <span className="search-text">Document Type:</span>
                  <div className="header-select">
                    <select value={(this.state.object_name) ? this.state.object_name : ""} onChange={this.handleInputChange} name="object_name">
                      <option value=''>{this.state.globalLang.label_all}</option>
                      <option value='file'>{this.state.clientsLang.label_files}</option>
                      <option value='questionnaire'>Questionnaires</option>
                      <option value='consent'>{this.state.settingLang.consents_header}</option>
                      {this.state.isTouchMD === 1 && this.state.plan_selected === 'practice_booking' ? '' : <option value='agreement'>{this.state.settingLang.membership_agreement}</option>}
                      {this.state.isTouchMD === 1 && this.state.plan_selected === 'practice_booking' ? '' : <option value='procedure'>{this.state.clientsLang.label_procedure}</option>}
                      <option value='ePrescription'>ePrescription</option>
                      <option value="gfe">GFE</option>
                      <option value="efax">eFax</option>
                    </select>
                    <i className="fas fa-angle-down"></i>
                  </div>
                </div>
              </div>
              <div className='pull-left fileDatePicker'>
                <div className="search-bg new-calender pull-left" ref={node => { this.node = node }}>
                  <img src={calenLogo} alt="logo" />
                  {this.state.showCalendar && (
                    <DateRangePicker
                      className={'CalendarPreviewArea'}
                      ranges={[this.state.dateRangePicker.selection]}
                      onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
                      maxDate={new Date()}
                      dragSelectionEnabled={false}
                    />
                  )}
                  <input type="text" className="input-cal setting-search-input p-b-5" name="calendar-input" value={(this.state.from_date) ? showFormattedDate(this.state.from_date, false) + `-` + showFormattedDate(this.state.to_date, false) : ""} autoComplete="off" onChange={this.handleInputChange} />
                </div>
              </div>
              <div className="add-file-btn pull-right">

                {checkIfPermissionAllowed('add-update-documents') && (
                  <button
                    className="new-blue-btn pull-right"
                    onClick={() => this.AddClientDocuments()}
                  >
                    Add File
                  </button>
                )}

                <div className="d-flex width130 m-r-10 m-t-4">
                  <input
                    id="expiration_dates"
                    className="d-flex m-t-2 m-r-4"
                    type="checkbox"
                    checked={this.state.expired}
                    onChange={(event) => this.handleExpirationDates(event.target.checked)}
                  />
                  <label htmlFor="expiration_dates" className="setting-switch pull-right text-nowrap">
                    Expiring Soon
                  </label>
                </div>

                {filesToDownload.value.length > 0 && (
                  <DownloadFiles
                    filesToDownload={filesToDownload.value}
                    clear={filesToDownload.clear}
                    patientId={parseInt(this.state.clientID)}
                    onRequestStart={() => this.setState({
                      isDownloadRequestTimeoutError: false,
                    })}
                    onRequestTimeoutError={() => this.setState({
                      isDownloadRequestTimeoutError: true,
                    })}
                    refetchProcedures={this.handleRefetchProcedure}
                  />
                )}
              </div>
            </div>
            {this.state.isDownloadRequestTimeoutError && (
              <div className="m-b-15 text-right p-h-20 text-red">
                <i className="fa fa-exclamation-circle m-r-5" aria-hidden="true"></i>
                Your download request couldn't process, try to select fewer files and try again
              </div>
            )}
            <div className="table-responsive documentTable">
              <table className="table-updated juvly-table min-w-1000 no-hover flex-wrap">
                <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-1 table-updated-th selectAllTh selectCell">
                      <input
                        type="checkbox"
                        checked={filesToDownload.isAllSelected(this.state.documentList)}
                        onChange={() => filesToDownload.toggleAll(this.state.documentList)}
                      />
                    </th>
                    {this.sortableColumns.map((column, index) => (
                      <th key={column.id || index} className={`col-xs-2 table-updated-th`}>
                        <SortAdornment
                          value={this.state.sort_column === column.sort ? this.state.sort_order : undefined}
                          onToggle={() => this.handleSort(column)}
                        >
                          {column.title}
                        </SortAdornment>
                      </th>
                    ))}
                    <th className="col-xs-1 table-updated-th text-center actionsCell">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.documentList && this.state.documentList.length > 0 ? this.state.documentList.map((obj, idx) => {
                    return (
                      <tr key={'documentList' + idx} className={`table-updated-tr ${moment(obj.expiration_date).isBefore(moment()) ? "expiredDocument" : ""}`}>
                        <td className="col-xs-1 table-updated-td selectCell">
                          <input
                            type="checkbox"
                            checked={filesToDownload.isSelected(obj)}
                            onChange={() => filesToDownload.toggleOne(obj)}
                          />
                        </td>
                        <td className="col-xs-2 table-updated-td word-break">{obj.name}</td>
                        <td className="col-xs-2 table-updated-td">
                          {obj.label}
                        </td>
                        <td className="col-xs-2 table-updated-td">
                          {obj.date ? showFormattedDate(obj.date, true) : 'N/A'}
                        </td>
                        <td className="col-xs-2 table-updated-td">
                          {obj.expiration_date ? showFormattedDate(obj.expiration_date) : 'N/A'}
                        </td>
                        <td className="col-xs-2 table-updated-td text-capitalize">
                          {this.getEditedBy(obj)}
                        </td>
                        <td className="col-xs-1 table-updated-td documentActions text-center actionsCell">
                          {(obj.type === 'file' && (checkIfPermissionAllowed('add-update-documents') || checkIfPermissionAllowed('view-documents'))) && (
                            <ul className="flex justify-center no-list gap-8">
                              {checkIfPermissionAllowed('view-documents') && (
                                !obj.is_lock ?
                                  <>
                                    <li className="min-w-16">
                                      <a
                                        className="header-unselect-btn modal-link"
                                        onClick={() => this.handleDownloadDocument(obj)}
                                      >
                                        <img
                                          src={VIEW_ICON}
                                          className="cursor-pointer"
                                          title="View"
                                          width={16}
                                          height={16}
                                          alt=""
                                        />
                                      </a>
                                    </li>
                                    <li className="min-w-16">
                                      <a onClick={() => this.handleTimerClick(obj)} title="Expiration Timer">
                                        <TIMER_ICON width="16px" height="22px" color={`${this.getDaysUntilExpiration(obj.expiration_date) < 30 ? "red" : "#217acd"}`} />
                                      </a>
                                    </li>
                                  </> :
                                  <>
                                    <li className="min-w-16">
                                      <a>
                                        <img
                                          src={VIEW_ICON}
                                          className="cursor-not-allowed"
                                          title="View"
                                          width={16}
                                          height={16}
                                          alt=""
                                        />
                                      </a>
                                    </li>
                                    <li className="min-w-16">
                                      <a onClick={() => this.handleTimerClick(obj)} title="Expiration Timer">
                                        <TIMER_ICON width="16px" height="22px" color={`${this.getDaysUntilExpiration(obj.expiration_date) < 30 ? "red" : "#217acd"}`} />
                                      </a>
                                    </li>

                                    <LockProcedure
                                      isdocuments
                                      isLocked={obj.is_lock}
                                      procedureId={obj.parent_id}
                                      refetchProcedures={this.handleRefetchProcedure}
                                    />
                                  </>
                              )}
                              {obj.type === "file" && checkIfPermissionAllowed('add-update-documents') && (
                                <li>
                                  <a
                                    className="header-unselect-btn setting modal-link color-dark-blue"
                                    onClick={() => this.EditClientDocuments(obj.object_id)}
                                    title="Edit"
                                  >
                                    <i className="fa fa-pencil-alt m-r-5" />
                                  </a>
                                </li>
                              )}
                              {checkIfPermissionAllowed('add-update-documents') && (
                                <li>
                                  <a
                                    className="header-unselect-btn setting modal-link"
                                    onClick={() => {
                                      this.setState({
                                        isShowDeleteModal: !this.state.isShowDeleteModal,
                                        deletedDocumentId: obj.object_id,
                                      })
                                    }}
                                    title="Delete"
                                  >
                                    <i className="fa fa-trash-alt m-r-5 m-t-2 color-dark-blue" />
                                  </a>
                                </li>
                              )}
                            </ul>
                          )}
                          {obj.type !== 'file' && obj.type !== "agreement" && obj.type !== "consent" && (checkIfPermissionAllowed('view-documents') ? (
                            !obj.is_lock ? (
                              <ul className="flex justify-center no-list gap-8">
                                <li className="min-w-16">
                                  <a
                                    className="header-unselect-btn modal-link"
                                    onClick={() => this.handleDownloadDocument(obj)}
                                  >
                                    <img
                                      src={VIEW_ICON}
                                      title="View"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />

                                  </a>
                                </li>
                                <li className="min-w-16">
                                  <a onClick={() => this.handleTimerClick(obj)} title="Expiration Timer">
                                    <TIMER_ICON width="16px" height="22px" color={`${this.getDaysUntilExpiration(obj.expiration_date) < 30 ? "red" : "#217acd"}`} />
                                  </a>
                                </li>
                              </ul>) :
                              <>
                                <LockProcedure
                                  isdocuments
                                  isLocked={obj.is_lock}
                                  procedureId={obj.parent_id}
                                  refetchProcedures={this.handleRefetchProcedure}
                                />
                              </>
                          ) : (
                            <ul className="flex justify-center no-list gap-8">
                              <li className="min-w-16">
                                <a className="header-unselect-btn modal-link">
                                  <img
                                    src={VIEW_ICON}
                                    className="cursor-not-allowed"
                                    title="View"
                                    width={16}
                                    height={16}
                                    alt=""
                                  />
                                </a>
                              </li>
                              <li className="min-w-16">
                                <a onClick={() => this.handleTimerClick(obj)} title="Expiration Timer">
                                  <TIMER_ICON width="16px" height="22px" color={`${this.getDaysUntilExpiration(obj.expiration_date) < 30 ? "red" : "#217acd"}`} />
                                </a>
                              </li>
                            </ul>
                          ))}
                          {(['agreement', 'consent'].includes(obj.type) && (checkIfPermissionAllowed('add-update-documents') || checkIfPermissionAllowed('view-documents'))) && (
                            !obj.is_lock ?
                              <ul className="flex justify-center no-list gap-8">
                                <li className="min-w-16">
                                  <a className="header-unselect-btn modal-link"
                                    onClick={() => this.handleDownloadDocument(obj)}>
                                    <img
                                      src={VIEW_ICON}
                                      title="View"
                                      width={16}
                                      height={16}
                                      alt=""
                                    />
                                  </a>
                                </li>
                                <li className="min-w-16">
                                  <a onClick={() => this.handleTimerClick(obj)} title="Expiration Timer">
                                    <TIMER_ICON width="16px" height="22px" color={`${this.getDaysUntilExpiration(obj.expiration_date) < 30 ? "red" : "#217acd"}`} />
                                  </a>
                                </li>
                              </ul>
                              :
                              <>
                                <LockProcedure
                                  isdocuments
                                  isLocked={obj.is_lock}
                                  procedureId={obj.parent_id}
                                  refetchProcedures={this.handleRefetchProcedure}
                                />
                              </>
                          )}
                        </td>
                      </tr>
                    )
                  }) : (
                    <tr className="table-updated-tr">
                      <td className="col-xs-12 table-updated-td text-center" colSpan={5}>{this.state.globalLang.sorry_no_record_found}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* Delete Document Modal - START */}
            <div className={(this.state.isShowDeleteModal === true) ? 'overlay' : ''}></div>
            <div id="filterModal" role="dialog" className={(this.state.isShowDeleteModal === true) ? 'modal fade in displayBlock' : 'modal fade no-display'}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal" onClick={this.toggleState.bind(this, 'isShowDeleteModal')}>×</button>
                    <h4 className="modal-title">{this.state.globalLang.delete_confirmation}</h4>
                  </div>
                  <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                    {this.state.clientsLang.client_delete_document_confirmation_msg}
                  </div>
                  <div className="modal-footer" >
                    <div className="col-md-12 text-left">
                      <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.toggleState.bind(this, 'isShowDeleteModal')}>{this.state.globalLang.label_no}</button>
                      <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this.handleDeleteDocument}>{this.state.globalLang.label_yes}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Document Modal - END */}
            {/* Membership modal starts */}
            <div className={(this.state.showAgreement ? 'blackOverlay' : 'no-display')}>
              <div className="vert-middle">
                <div id="filterModal" role="dialog" className={(this.state.showAgreement ? 'model loyaltyMembership large-popup clientMembershipPopup no-padding  fade in displayBlock' : 'modal fade')}>
                  <div className="modal-header">
                    <button type="button" className="close agreementClose" data-dismiss="modal" onClick={this.handleAgreementClose}>×</button>
                    <h4 className="modal-title" id="model_title">{"Membership Agreement"}</h4>
                  </div>
                  <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                    <Scrollbars style={{ height: '400px' }} className="juvly-Agreement-content">
                      <div className="agreementContent">
                        <div dangerouslySetInnerHTML={{ __html: this.state.agreementText }} />
                      </div>
                      <div className='memberAgreemenSign m-r-20'>
                        <img className='consent-signature imp-patient-signature pull-right m-t-0' src={this.state.patient_signature_url} alt=""/>
                        <p>{this.state.patient_name ? '(' + this.state.patient_name + ')' : ''}</p>
                        <p>{this.state.signed_on ? showFormattedDate(this.state.signed_on, true) : ''}</p>
                      </div>
                    </Scrollbars>
                  </div>
                  <div className="modal-footer p-t-10 p-b-10" >
                    <div className="col-md-12 text-left no-padding" id="footer-btn">
                      <button type="button" className="new-blue-btn pull-right" data-dismiss="modal" onClick={this.handleAgreementClose}>Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Membership Modal ends */}
          </div>
          <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock clientLoader' : 'new-loader text-left'}>
            <div className="loader-outer">
              <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" alt=""/>
              <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.Please_Wait}</div>
            </div>
          </div>
        </div>
        {this.state.showExpirationModal &&
          <ExpirationModal
            isOpen
            document={this.state.showExpirationModal}
            onClose={this.handleExpirationModalClose}
            patientId={parseInt(this.state.clientID)}
            patientName={this.state.clientName}
            refetchDocuments={this.refetchDocuments}
          />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.ClientsReducer.action === "CLIENT_DOCUMENT_LIST") {
    if(state.ClientsReducer.data.status !== 200) {
      uiNotification.clear()
      uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.documentData = state.ClientsReducer.data.data;
      returnState.documentDataTime = new Date();
    }
  } else if (state.ClientsReducer.action === "CLIENT_DOCUMENT_DELETE") {
    if(state.ClientsReducer.data.status !== 200) {
      uiNotification.clear()
      uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.deleteData = state.ClientsReducer.data.data;
      returnState.deleteDataTime = new Date();
    }
  }
  if(state.ClientsReducer.action === 'GET_AGREEMENT_DATA'){
    if(state.ClientsReducer.data.status !== 200){
      uiNotification.clear();
      uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      returnState.agreementData = state.ClientsReducer.data.data;
      returnState.agreementDataTime = new Date();
    }
  }

  if(state.ClientsReducer.action === 'EXPORT_PROCEDURE_DATA'){
    if(state.ClientsReducer.data.status !== 200){
      uiNotification.clear();
      uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      returnState.exportProcedureDataTime = new Date();
    }
  }
  if(state.ClientsReducer.action === 'GET_CLIENT_DOCUMENT'){
    if(state.ClientsReducer.data.status !== 200){
      uiNotification.clear();
      uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      returnState.clientDocumentDataTime = new Date();
    }
  }

  if(state.ClientsReducer.action === 'DOWNLOAD_PRESCRIPTION_PDF'){
    if(state.ClientsReducer.data.status !== 200){
      uiNotification.clear();
      uiNotification.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      returnState.clientDocumentDataTime = new Date();
    }
  }

  returnState.exportMembershipData = state.ClientsReducer.exportMembershipDocument;
  returnState.exportConsentData = state.ClientsReducer.exportConsentDocument;
  returnState.exportProcedureDetails = state.ClientsReducer.exportProcedureDetails;
  returnState.clientDocumentData = state.ClientsReducer.clientDocumentData;

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getClientDocuments,
    deleteClientDocument,
    exportEmptyData,
    getAgreementDetails,
    exportProcedureData,
    showFile,
    downloadPrescriptionPdf,
    exportMembershipDocument,
    exportConsentDocument,
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(
  withFilesToDownload(ClientDocuments)
));
