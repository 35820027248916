import React from "react";
import cx from "clsx";
import { format } from "date-fns";
import { numberFormat } from "../../../../../Utils";
import ClientCreditCards from "../../../components/ClientCreditCards/ClientCreditCards";
import styles from "./ClientFinanceInfo.module.scss";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { useCurrentAccountQuery } from "../../../../../api/queries/useAccountQuery";
import { getPathParams } from "../../utils/utils";

export function ClientFinanceInfo() {
  const { clientId } = getPathParams();
  const { data: clientData } = useClientQuery({ clientId });
  const { data: account } = useCurrentAccountQuery();
  const { data: currentUser } = useCurrentUserQuery();

  return (
    <div className={cx(styles.membershipContainer, "col-xs-12")}>
      {Boolean(clientData?.lastProcedureDate) && (
        <div>
          <div className={styles.membershipItem}>Last Visit</div>
          <div className={styles.itemText}>
            {format(
              clientData?.lastProcedureDate,
              currentUser?.previewDateFormat,
            ) || "Never"}
          </div>
        </div>
      )}
      {[clientData?.membershipBenefits, clientData?.patientMembershipSubscription?.length].some(Boolean) && (
        <div className={styles.subscriptions}>
          <div>
            <div className={styles.membershipItem}>Membership Program</div>
            {clientData.patientMembershipSubscription.map(subscription => (
              <div key={subscription?.id} className={styles.itemText}>{subscription?.membership_tier?.tier_name}</div>
            ))}
          </div>
        </div>
      )}
      <div>
        <div className={styles.membershipItem}>Total Sales Relationship</div>
        <div className={styles.itemText}>
          {numberFormat(clientData?.total_sale_relationship || 0.00, "currency")}
        </div>
      </div>
      {Boolean(account?.globalSettings?.isPosEnabled) && (
        <div>
          <div className={styles.membershipItem}>Payments on file</div>
          <div className="setting items-center">
            <ClientCreditCards
              clientId={clientId}
              clientClinicId={clientData?.clinicId}
            />
          </div>
        </div>
      )}
    </div>
  );
}
