import React from "react";
import StripeDocumentInput from "./StripeDocumentInput";
import StripeDocumentSelect from "./StripeDocumentSelect";
import styles from "../scss/addDocument.module.scss";
import { useReport } from "../hooks/useReport";
import { InputError } from "../../../../../../shared/InputError/InputError";

const FormContainer = ({
  fileName,
  fileType,
  clinic,
  handleChange,
  index,
  uploadedFile,
  errors,
}) => {
  const { headerForm } = useReport();

  return (
    <div className={styles.formContainer}>
      <div>
        <input
          type="file"
          id={`uploadFile-${index}`}
          className={styles.uploadInput}
          onChange={(event) => {
            handleChange(index, "file", event.target.files[0]);
          }}
        />
        <label htmlFor={`uploadFile-${index}`} className={styles.fileContainer}>
          {uploadedFile ? (
            <span className={styles.selectFileText}>{uploadedFile?.name}</span>
          ) : (
            <div>
              <img
                src="/images/upload.png"
                alt="upload"
                className={styles.selectFileImage}
              />
              {errors?.[index]?.file && (
                <InputError>{errors?.[index]?.file}</InputError>
              )}
            </div>
          )}
          <div className={styles.selectFileText}>Upload</div>
        </label>
      </div>
      <div className={styles.fullWidth}>
        <div className={styles.addDocumentForm}>
          <div>
            <StripeDocumentInput
              id={`fileName-${index}`}
              label="File Name"
              value={fileName}
              onChange={(event) => {
                handleChange(index, "name", event.target.value);
              }}
            />
            {errors?.[index]?.name && (
              <InputError>{errors?.[index]?.name}</InputError>
            )}
          </div>
          <div>
            <StripeDocumentSelect
              id={`fileType-${index}`}
              label="File Type"
              value={fileType}
              onChange={(event) =>
                handleChange(index, "type", event.target.value)
              }
              options={[
                { label: "Select", value: "" },
                ...headerForm.type.options,
              ]}
            />
            {errors?.[index]?.type && (
              <InputError>{errors?.[index]?.type}</InputError>
            )}
          </div>
          <div>
            <StripeDocumentSelect
              id={`clinic-${index}`}
              label="Clinic"
              value={clinic}
              onChange={(event) =>
                handleChange(index, "clinic", event.target.value)
              }
              options={[
                { label: "Select", value: "" },
                ...headerForm.clinic.options,
              ]}
            />
            {errors?.[index]?.clinic && (
              <InputError>{errors?.[index]?.clinic}</InputError>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
