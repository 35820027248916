/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { uiNotification } from "../../../../services/UINotificationService";
import { useCurrentAccountQuery } from "../../../../api/queries/useAccountQuery";
import { CLIENT_GLOBAL_NAME } from "../../../../consts/api";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { LoadMoreObserver } from "../../../../shared/LoadMoreObserver/LoadMoreObserver";
import LoaderLow from "../../../Common/LoaderLow";
import { useDailySalesReportQuery } from "../../../../api/queries/useDailySalesReportQuery";
import { useDailySalesReportDetailsQuery } from "../../../../api/queries/useDailySalesReportDetailsQuery";
import { BI_ROUTES } from "../../../../consts/routes";
import { withGlobalSettings } from "../../../../hocs/withGlobalSettings";

const SalesSummary = ({
  clinicIds,
  fromDate,
  toDate,
  onDownloadXls,
  setReportData,
  globalSettings,
}) => {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();
  const accountQuery = useCurrentAccountQuery();

  const { data: details, isLoading: isDetailsLoading } =
    useDailySalesReportDetailsQuery({
      payload: {
        clinicIds,
        fromDate,
        toDate,
      },
      options: {
        staleTime: 0,
        onSuccess: (data) => {
          setReportData(data);
        },
        onError: () => {
          uiNotification.error(tBi("salesSummaryReport.error.fetchData"));
        },
      },
    });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useDailySalesReportQuery({
      payload: {
        pageSize: 15,
        clinicIds,
        fromDate,
        toDate,
      },
      options: {
        staleTime: 0,
        onError: () => {
          uiNotification.error(tBi("salesSummaryReport.error.fetchData"));
        },
      },
    });

  const reports = useMemo(() => {
    if (data && data.pages) {
      return data.pages
        .map((p) => p.reports)
        .reduce((r, i) => [...r, ...i], []);
    }
    return [];
  }, [data]);

  const goToReport = (redirectTo) => {
    window.open(redirectTo);
  };

  const goToInvoice = (report) => {
    window.open(`/sales/invoice/${report.invoiceId}/${report.clientId}`);
  };

  const isRepeatMdAvailable = Boolean(
    globalSettings?.["repeat-md"]?.isAvailable,
  );

  return (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <div className="setting-setion m-b-15">
            <div className="table-responsive">
              <table className="records-table">
                <thead>
                  <tr>
                    <th>{tBi("salesSummaryReport.totalSales")}</th>
                    <th className="text-right">
                      ({tCommon("label.allSources")})
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        title={tCommon("label.clickForDetails")}
                        className="openNewTab"
                        onClick={() =>
                          goToReport("/business-insight/sales/gross_sales")
                        }
                      >
                        {tBi(
                          "salesSummaryReport.staticData.procedureAndRetailSales",
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      {details?.totalSales.grossSales}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        title={tCommon("label.clickForDetails")}
                        className="openNewTab"
                        onClick={() =>
                          goToReport(BI_ROUTES.sales.membershipOverviewReport())
                        }
                      >
                        {tBi("membershipOverviewReport.label")}
                      </span>
                    </td>
                    <td className="text-right">
                      {details?.totalSales.membershipRevenue}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {tBi("salesSummaryReport.staticData.prePaidProducts")}
                    </td>
                    <td className="text-right">
                      {details?.totalSales.prePaidProducts}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        title={tCommon("label.clickForDetails")}
                        className="openNewTab"
                        onClick={() =>
                          goToReport(
                            "/business-insight/sales/egift_cards_revenue",
                          )
                        }
                      >
                        {tBi("salesSummaryReport.staticData.giftCardPurchases")}
                      </span>
                    </td>
                    <td className="text-right">
                      {details?.totalSales.giftCardPurchases}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {tBi(
                        "salesSummaryReport.staticData.cancellationFeeCollected",
                      )}
                    </td>
                    <td className="text-right">
                      {details?.totalSales.cancellationFeeCollected}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ padding: "0px", paddingLeft: "20px" }}
                    >
                      <table className="sub-record-table">
                        <tbody>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.walletDebits",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.totalSales.walletDebits}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.giftCardRedemptions",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.totalSales.giftCardRedemptions}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.totalRefunds",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.totalSales.refunds}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              <span
                                title={tCommon("label.clickForDetails")}
                                className="openNewTab"
                                onClick={() =>
                                  goToReport(
                                    "/business-insight/sales/tip-report",
                                  )
                                }
                              >
                                {tBi("salesSummaryReport.staticData.tips")}
                              </span>
                            </td>
                            <td className="text-right">
                              {details?.totalSales.tips}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              - {tBi("salesSummaryReport.staticData.tax")}
                            </td>
                            <td className="text-right">
                              {details?.totalSales.tax}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.processingAndBusinessFees",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.totalSales.processingAndBusinessFees}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.discountComplementary",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.totalSales.discountComplementary}
                            </td>
                          </tr>
                          {details?.totalSales.membershipCoupons && (
                            <tr>
                              <td>
                                -{" "}
                                {tBi(
                                  "salesSummaryReport.staticData.membershipCoupons",
                                )}
                              </td>
                              <td className="text-right">
                                {details?.totalSales.membershipCoupons}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr className="bold">
                    <td>
                      {tBi("salesSummaryReport.staticData.totalNetSales")}
                    </td>
                    <td className="text-right">
                      {details?.totalSales.totalNetSales}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="setting-setion m-b-15">
            <div className="table-responsive">
              <table className="records-table">
                <thead>
                  <tr>
                    <th>{tBi("salesSummaryReport.procedureTotalSales")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        title={tCommon("label.clickForDetails")}
                        className="openNewTab"
                        onClick={() =>
                          goToReport("/business-insight/sales/gross_sales")
                        }
                      >
                        {tBi(
                          "salesSummaryReport.staticData.salesFromProcedures",
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      {details?.procedureTotalSales.grossSales}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ padding: "0px", paddingLeft: "20px" }}
                    >
                      <table className="sub-record-table">
                        <tbody>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.walletDebits",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.procedureTotalSales.walletDebits}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.giftCardRedemptions",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.procedureTotalSales.giftCardRedemptions}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              - {tBi("salesSummaryReport.staticData.refunds")}
                            </td>
                            <td className="text-right">
                              {details?.procedureTotalSales.refunds}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              <span
                                title={tCommon("label.clickForDetails")}
                                className="openNewTab"
                                onClick={() =>
                                  goToReport(
                                    "/business-insight/sales/tip-report",
                                  )
                                }
                              >
                                {tBi("salesSummaryReport.staticData.tips")}
                              </span>
                            </td>
                            <td className="text-right">
                              {details?.procedureTotalSales.tips}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              - {tBi("salesSummaryReport.staticData.tax")}
                            </td>
                            <td className="text-right">
                              {details?.procedureTotalSales.tax}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.processingAndBusinessFees",
                              )}
                            </td>
                            <td className="text-right">
                              {
                                details?.procedureTotalSales
                                  .processingAndBusinessFees
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.discountComplementary",
                              )}
                            </td>
                            <td className="text-right">
                              {
                                details?.procedureTotalSales
                                  .discountComplementary
                              }
                            </td>
                          </tr>
                          {details?.procedureTotalSales.membershipCoupons && (
                            <tr>
                              <td>
                                -{" "}
                                {tBi(
                                  "salesSummaryReport.staticData.membershipCoupons",
                                )}
                              </td>
                              <td className="text-right">
                                {details?.procedureTotalSales.membershipCoupons}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr className="bold">
                    <td>
                      {tBi("salesSummaryReport.staticData.procedureNetSales")}
                    </td>
                    <td className="text-right">
                      {details?.procedureTotalSales.totalNetSales}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-sm-6 memberProgram-right">
          <div className="setting-setion m-b-15">
            <div className="table-responsive">
              <table className="records-table">
                <thead>
                  <tr>
                    <th>{tBi("salesSummaryReport.depositSlip")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span
                        title={tCommon("label.clickForDetails")}
                        className="openNewTab"
                        onClick={() =>
                          goToReport("/business-insight/sales/gross_sales")
                        }
                      >
                        {tBi(
                          "salesSummaryReport.staticData.procedureAndRetailSales",
                        )}
                      </span>
                    </td>
                    <td className="text-right">
                      {details?.depositSlip.grossSales}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        title={tCommon("label.clickForDetails")}
                        className="openNewTab"
                        onClick={() =>
                          goToReport(BI_ROUTES.sales.membershipOverviewReport())
                        }
                      >
                        {tBi("membershipOverviewReport.label")}
                      </span>
                    </td>
                    <td className="text-right">
                      {details?.depositSlip.membershipRevenue}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {tBi("salesSummaryReport.staticData.prePaidProducts")}
                    </td>
                    <td className="text-right">
                      {details?.depositSlip.prePaidProducts}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        title={tCommon("label.clickForDetails")}
                        className="openNewTab"
                        onClick={() =>
                          goToReport(
                            "/business-insight/sales/egift_cards_revenue",
                          )
                        }
                      >
                        {tBi("salesSummaryReport.staticData.giftCardPurchases")}
                      </span>
                    </td>
                    <td className="text-right">
                      {details?.depositSlip.giftCardPurchases}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {tBi(
                        "salesSummaryReport.staticData.cancellationFeeCollected",
                      )}
                    </td>
                    <td className="text-right">
                      {details?.depositSlip.cancellationFeeCollected}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ padding: "0px", paddingLeft: "20px" }}
                    >
                      <table className="sub-record-table">
                        <tbody>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.walletDebits",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip.walletDebits}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.discountComplementary",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip.discountComplementary}
                            </td>
                          </tr>
                          {details?.depositSlip.membershipCoupons && (
                            <tr>
                              <td>
                                -{" "}
                                {tBi(
                                  "salesSummaryReport.staticData.membershipCoupons",
                                )}
                              </td>
                              <td className="text-right">
                                {details?.depositSlip.membershipCoupons}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.aspireCoupons",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip.aspireCoupons}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              - {tBi("salesSummaryReport.staticData.bdCoupons")}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip.bdCoupons}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.xperienceCoupons",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip?.xperienceCoupons}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.evolusRewards",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip.evolusCoupons}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.giftCardRedemptions",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip.giftCardDeposits}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              -{" "}
                              {tBi("salesSummaryReport.staticData.careCredit")}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip.careCreditDeposits}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              - {tBi("salesSummaryReport.staticData.refunds")}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip.refunds}
                            </td>
                          </tr>
                          {isRepeatMdAvailable && (
                            <tr>
                              <td>
                                -{" "}
                                {tBi(
                                  "salesSummaryReport.staticData.repeatMdTransferPayments",
                                )}
                              </td>
                              <td className="text-right">
                                {details?.depositSlip.repeatMdRedemptions}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              -{" "}
                              {tBi(
                                "salesSummaryReport.staticData.processingAndBusinessFees",
                              )}
                            </td>
                            <td className="text-right">
                              {details?.depositSlip.processingAndBusinessFees}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr className="bold">
                    <td>
                      {tBi("salesSummaryReport.staticData.depositAmount")}
                    </td>
                    <td className="text-right">
                      {details?.depositSlip.totalNetSales}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="setting-setion m-b-15">
            <div className="table-responsive">
              <table className="records-table">
                <thead>
                  <tr>
                    <th>{tBi("salesSummaryReport.paymentMethod")}</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{tBi("salesSummaryReport.staticData.cash")}</td>
                    <td className="text-right">
                      {details?.paymentMethod.cash}
                    </td>
                  </tr>
                  <tr>
                    <td>{tBi("salesSummaryReport.staticData.check")}</td>
                    <td className="text-right">
                      {details?.paymentMethod.check}
                    </td>
                  </tr>
                  <tr>
                    <td>{tBi("salesSummaryReport.staticData.credit")}</td>
                    <td className="text-right">
                      {details?.paymentMethod.credit}
                    </td>
                  </tr>
                  <tr>
                    <td>{tBi("salesSummaryReport.staticData.cherry")}</td>
                    <td className="text-right">
                      {details?.paymentMethod.cherry}
                    </td>
                  </tr>
                  <tr>
                    <td>{tBi("salesSummaryReport.staticData.greenSky")}</td>
                    <td className="text-right">
                      {details?.paymentMethod.greenSky}
                    </td>
                  </tr>
                  <tr>
                    <td>{tBi("salesSummaryReport.staticData.careCredit")}</td>
                    <td className="text-right">
                      {details?.paymentMethod.careCredit}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {tBi("salesSummaryReport.staticData.aspireCoupons")}
                    </td>
                    <td className="text-right">
                      {details?.paymentMethod.aspireCoupons}
                    </td>
                  </tr>
                  <tr>
                    <td>{tBi("salesSummaryReport.staticData.bdCoupons")}</td>
                    <td className="text-right">
                      {details?.paymentMethod.bdCoupons}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {tBi("salesSummaryReport.staticData.xperienceCoupons")}
                    </td>
                    <td className="text-right">
                      {details?.paymentMethod?.xperienceCoupons}
                    </td>
                  </tr>
                  <tr>
                    <td>{tBi("salesSummaryReport.staticData.link")}</td>
                    <td className="text-right">
                      {details?.paymentMethod.stripe_link}
                    </td>
                  </tr>
                  <tr>
                    <td>{tBi("salesSummaryReport.staticData.klarna")}</td>
                    <td className="text-right">
                      {details?.paymentMethod.klarna}
                    </td>
                  </tr>
                  {isRepeatMdAvailable && (
                    <tr>
                      <td>
                        {tBi(
                          "salesSummaryReport.staticData.repeatMdTransferPayments",
                        )}
                      </td>
                      <td className="text-right">
                        {details?.paymentMethod.repeatMdRedemptions}
                      </td>
                    </tr>
                  )}
                  <tr className="bold">
                    <td>
                      {tBi("salesSummaryReport.staticData.totalPayments")}
                    </td>
                    <td className="text-right">
                      {details?.paymentMethod.totalNetSales}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="setting-setion m-b-15">
            <div className="mainSectionTitle">
              <b>{tBi("salesSummaryReport.table.label")}</b>
              <div className="mainRightActions downloadDiscountReport">
                <a className="easy-link no-padding" onClick={onDownloadXls}>
                  <i className="fa fa-download"></i>{" "}
                  {tCommon("label.downloadExcel")}
                </a>
              </div>
            </div>
            <div className="table-responsive">
              <table className="records-table no-hover table-min-width">
                <thead className="table-updated-thead">
                  <tr className="bold">
                    <th>
                      {accountQuery.data?.preferences?.clientGlobalName ||
                        CLIENT_GLOBAL_NAME.client}
                    </th>
                    <th>{tCommon("label.discountType")}</th>
                    <th>{tCommon("label.provider")}</th>
                    <th>{tBi("salesSummaryReport.table.checkoutPerson")}</th>
                    <th>{tCommon("label.discountAmount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.length > 0 ? (
                    reports.map((report, index) => {
                      return (
                        <tr
                          onClick={() => goToInvoice(report)}
                          key={index}
                          className="table-updated-tr"
                        >
                          <td>{report.clientName}</td>
                          <td>{report.discountType}</td>
                          <td>{report.providerName}</td>
                          <td>{report.checkoutPerson}</td>
                          <td>{report.amount}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center">
                      <td colSpan="5">{tCommon("label.noRecordFound")}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {hasNextPage && (
        <LoadMoreObserver
          noPaddingBottom
          loadMore={fetchNextPage}
          isLoading={isFetchingNextPage}
        />
      )}
      {(isLoading || isDetailsLoading) && (
        <LoaderLow showLoader isFullWidth={true} />
      )}
    </div>
  );
};

SalesSummary.propTypes = {
  clinicIds: PropTypes.arrayOf(PropTypes.number),
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string.isRequired,
  onDownloadXls: PropTypes.func.isRequired,
  globalSettings: PropTypes.object,
};

SalesSummary.defaultProps = {
  clinicIds: [],
};

export default withGlobalSettings(SalesSummary);
