import * as yup from "yup";
import { questionnaireSchema } from "../schemas";

export const patientQuestionnaireSchema = yup.object({
  id: yup.number().required(),
  patient_id: yup.number().required(),
  appointment_id: yup.number().nullable(),
  procedure_id: yup.number().nullable(),
  status: yup.number().required(),
  answers: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        patient_questionnaire_id: yup.number().required(),
        question_id: yup.number().required(),
        question_type: yup.string().nullable(),
        score: yup.number().nullable(),
        answer: yup.array().of(yup.string()).required(),
        comment: yup.string().nullable(),
      }),
    )
    .required(),
  questionnaire: questionnaireSchema.required(),
});
