import { useQualiphyExamsQuery } from "../../../api/qualiphy/useQualiphyExamsQuery";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";

export function useExams({ clinicId }) {
  const { tCommon } = useAppTranslation.Common();

  const examsQuery = useQualiphyExamsQuery({
    payload: {
      clinicId: Number(clinicId),
    },
    options: {
      enabled: Boolean(clinicId),
      onError: () => {
        uiNotification.error(tCommon("error.qualiphy.getExams"));
      },
    },
  });

  const examOptions =
    examsQuery.data?.map((exam) => ({
      label: exam.name,
      value: exam.id,
      isAttachmentsRequired: exam.isAttachmentsRequired,
      rx: exam.rx,
    })) || [];

  return {
    examOptions,
    isExamsFetching: examsQuery.isFetching,
  };
}
