import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./Consent.module.scss";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { EyeIcon } from "../../../../../assets/Icons/EyeIcon";
import { Param } from "./shared/Param/Param";
import { PenLineIcon } from "../../../../../assets/Icons/PenLineIcon";
import { ModalPatientConsentSign } from "../../../../../widgets/ModalPatientConsentSign/ModalPatientConsentSign";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { Modal } from "../../../../../shared/Modal/Modal";
import { PatientConsentPreview } from "../../../../../widgets/PatientConsentPreview/PatientConsentPreview";
import { getIsPatientConsentNeedsSign } from "../../../../../utilities/api";

export function Consent({ patientConsent, refetchConsents }) {
  const { tCommon } = useAppTranslation.Common();
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);
  const [isViewerModalOpen, setIsViewerModalOpen] = useState(false);

  const { data: patient } = useClientQuery({
    clientId: patientConsent.patient_id,
  });

  const isPatientSigned = !!patientConsent.signature_image;
  const isWitnessSigned = !!patientConsent.witness_signature;
  const isWitnessSignatureRequired = !!patientConsent.consent.witness_signature;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <h3>{patientConsent.consent.consent_name}</h3>
          <div className={classes.params}>
            <Param isCompleted={isPatientSigned}>
              {tCommon("patientConsents.patientSigned")}:
            </Param>
            {isWitnessSignatureRequired && (
              <Param isCompleted={isWitnessSigned}>
                {tCommon("patientConsents.witnessSigned")}:
              </Param>
            )}
          </div>
        </div>
        <div className={classes.footer}>
          <button onClick={() => setIsViewerModalOpen(true)}>
            <EyeIcon width="16px" />
            {tCommon("label.view")}
          </button>
          {getIsPatientConsentNeedsSign(patientConsent) && (
            <button onClick={() => setIsSignModalOpen(true)}>
              <PenLineIcon width="16px" />
              {tCommon("label.sign")}
            </button>
          )}
        </div>
      </div>
      {isViewerModalOpen && (
        <Modal size="large" isOpen onClose={() => setIsViewerModalOpen(false)}>
          <PatientConsentPreview
            consent={{
              name: patientConsent.consent.consent_name,
              description: patientConsent.consent.consent_large_description,
              witnessSignatureUrl: patientConsent.witness_signature_image_url,
              witnessName: patientConsent.witness_name,
              patientSignatureUrl: patientConsent.signature_image_url,
              patientName: patientConsent.initials,
            }}
            patient={{
              id: patientConsent.patient_id,
            }}
          />
        </Modal>
      )}
      {isSignModalOpen && (
        <ModalPatientConsentSign
          isOpen
          onClose={() => setIsSignModalOpen(false)}
          onSuccess={() => {
            setIsSignModalOpen(false);
            refetchConsents();
          }}
          consent={{
            id: patientConsent.id,
            name: patientConsent.consent.consent_name,
            patientSignatureUrl: patientConsent.signature_image_url,
            witnessSignatureUrl: patientConsent.witness_signature_image_url,
            witnessName: patientConsent.witness_name,
            isWitnessSignatureRequired,
            patientName: patient?.fullName,
          }}
        />
      )}
    </>
  );
}

Consent.propTypes = {
  patientConsent: PropTypes.object.isRequired,
  refetchConsents: PropTypes.func,
};
