import React from "react";
import cx from "clsx";
import styles from "./ClientEmergencyInfo.module.scss";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { getPathParams } from "../../utils/utils";

export function ClientEmergencyInfo() {
  const { clientId } = getPathParams();
  const { data: clientData } = useClientQuery({ clientId });

  return (
    <div className={cx(styles.infoWrapper, "col-xs-12")}>
      {Boolean(clientData?.emergencyContactName) && (
        <div>
          <div className={styles.itemTitle}>Emergency contact</div>
          <div className={styles.itemText}>
            {clientData?.emergencyContactName}
          </div>
        </div>
      )}
      {Boolean(clientData?.emergencyContactNumber) && (
        <div>
          <div className={styles.itemTitle}>Emergency contact phone</div>
          <div className={styles.itemText}>
            {clientData?.emergencyContactNumber}
          </div>
        </div>
      )}
    </div>
  );
}
