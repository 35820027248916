import { useMemo } from "react";
import {
  choiceToSelectOption,
  filterOptionByLabelPredicate,
  getAlreadySelectedChoiceIndexes,
  getAlreadySelectedQuestionIndexes,
  questionToSelectOptions,
} from "../LogicJumps.utils";
import { isNullish } from "../../../../../../utilities/general";

export function useOptions({
  nodes,
  choices,
  questions,
  preloadedQuestionIndex,
}) {
  const choiceOptions = useMemo(() => {
    return choices
      .map(choiceToSelectOption)
      .filter(filterOptionByLabelPredicate);
  }, [choices]);

  const availableChoiceOptions = useMemo(() => {
    const alreadySelected = getAlreadySelectedChoiceIndexes(nodes);
    return choiceOptions.filter((c) => !alreadySelected.includes(c.value));
  }, [choiceOptions, nodes]);

  const questionOptions = useMemo(() => {
    const questionOptions = questions.map(questionToSelectOptions);

    if (!isNullish(preloadedQuestionIndex)) {
      const withoutCurrent = [
        ...questionOptions.slice(0, preloadedQuestionIndex),
        ...questionOptions.slice(preloadedQuestionIndex + 1),
      ];
      return withoutCurrent.filter(filterOptionByLabelPredicate);
    }

    return questionOptions.filter(filterOptionByLabelPredicate);
  }, [questions]);

  const availableQuestionOptions = useMemo(() => {
    const alreadySelected = getAlreadySelectedQuestionIndexes(nodes);
    return questionOptions.filter((c) => !alreadySelected.includes(c.value));
  }, [questionOptions, nodes]);

  return {
    choices: {
      all: choiceOptions,
      available: availableChoiceOptions,
    },
    questions: {
      all: questionOptions,
      available: availableQuestionOptions,
    },
  };
}
