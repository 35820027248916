import React from "react";
import cx from "clsx";
import { LayoutMain } from "../../../boxes/LayoutMain/LayoutMain";
import { usePatientConsents } from "./hooks/usePatientConsents";
import { Box } from "../../../shared/Box/Box";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { getPathParams } from "./PatientConsents.utils";
import { Empty } from "../../../shared/Empty/Empty";
import classes from "./PatientConsents.module.scss";
import { Consent } from "./shared/Consent/Consent";
import { Skeleton } from "../../../shared/Skeleton/Skeleton";
import { PageHeader } from "../../../boxes/PageHeader/PageHeader";
import { PATIENT_CONSENTS_ENTITY_TYPES } from "../../../api/consents/patientConsents/usePatientConsentsQuery";
import { PatientDocumentInfo } from "../shared/PatientDocumentInfo/PatientDocumentInfo";
import { history } from "../../../history";

export function PatientConsents() {
  const { isEntityTypeValid, entityType } = getPathParams();
  const { tCommon } = useAppTranslation.Common();

  const {
    data,
    isLoading,
    isVisible,
    isEmpty,
    refetch,
    patientId,
    appointmentId,
    procedureId,
  } = usePatientConsents();

  const titleByEntityType = {
    [PATIENT_CONSENTS_ENTITY_TYPES.appointment]: tCommon(
      "patientConsents.appointmentTitle",
    ),
    [PATIENT_CONSENTS_ENTITY_TYPES.patient]: tCommon("patientConsents.title"),
    [PATIENT_CONSENTS_ENTITY_TYPES.procedure]: tCommon(
      "patientConsents.procedureTitle",
    ),
  };

  return (
    <LayoutMain>
      <Box className={classes.root}>
        <PageHeader
          onBack={() => history.goBack()}
          title={titleByEntityType[entityType]}
          rightAdornment={
            !isLoading ? (
              <PatientDocumentInfo
                patientId={patientId}
                appointmentId={appointmentId}
                procedureId={procedureId}
              />
            ) : undefined
          }
        />
        {isEntityTypeValid ? (
          <div
            className={cx(classes.consentsList, {
              [classes.consentsListEmpty]: isEmpty,
            })}
          >
            {isLoading &&
              Array.from(Array(12).keys()).map((k) => (
                <Skeleton key={k} count={1} height={138} />
              ))}
            {isVisible &&
              data.map((patientConsent) => (
                <Consent
                  key={patientConsent.id}
                  patientConsent={patientConsent}
                  refetchConsents={refetch}
                />
              ))}
            {isEmpty && (
              <Empty position="center">
                {tCommon("patientConsents.notFound")}
              </Empty>
            )}
          </div>
        ) : (
          <Empty position="center">{tCommon("patientConsents.notFound")}</Empty>
        )}
      </Box>
    </LayoutMain>
  );
}
