import * as yup from "yup";
import { consentSchema } from "../schema";

export const patientConsentSchema = yup.object({
  id: yup.number().required(),
  appointment_id: yup.number().nullable(),
  consent_id: yup.number().nullable(),
  initials: yup.string().nullable(),
  patient_id: yup.number().required(),
  procedure_id: yup.number().nullable(),
  signature_image: yup.string().nullable(),
  signature_image_url: yup.string().nullable(),
  witness_name: yup.string().nullable(),
  witness_signature: yup.string().nullable(),
  witness_signature_image_url: yup.string().nullable(),
  consent: consentSchema.required(),
  status: yup.number().required(),
});
