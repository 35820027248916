import * as yup from "yup";

const documentSchema = yup.object().shape({
  name: yup
    .string()
    .required("File name is required")
    .min(1, "File name cannot be empty"),

  type: yup
    .string()
    .required("File type is required")
    .min(1, "File type cannot be empty"),

  clinic: yup
    .string()
    .required("Clinic is required")
    .min(1, "Clinic cannot be empty"),

  file: yup
    .mixed()
    .required("File is required")
    .test("fileNotNull", "File cannot be null", (value) => value !== null)
    .test(
      "fileNotEmpty",
      "File cannot be empty",
      (value) => value && value.size > 0,
    ),
});

export const schema = yup.object().shape({
  documents: yup
    .array()
    .of(documentSchema)
    .min(1, "At least one document is required")
    .required("Documents are required"),
});
