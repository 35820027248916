import { MULTI_QUESTION_LOGIC_JUMP_TYPES } from "../../api/questionnaires/config";
import { QUESTIONNAIRE_TYPES } from "../../consts/api";
import { Maybe } from "../../utilities/fp";
import { squashSpaces } from "../../utilities/general";

/**
 * Main entry point for patientQuestionnaire schema.
 * Adapts questions for further processing. All other entities
 * should use abstraction that resulted from this function.
 */
export function questionsAdapter(patientQuestionnaire) {
  const dedupAnswersByText = (answers = []) =>
    answers.reduce((carry, answer) => {
      const text = Maybe.of(answer.text).map(squashSpaces).orElse(null).value();

      if (
        typeof text !== "string" ||
        !carry.find((x) => squashSpaces(x.text) === text) ||
        answer.imageUrl
      ) {
        return [...carry, answer];
      }

      return carry;
    }, []);

  const adapterByType = {
    [QUESTIONNAIRE_TYPES.simple]: (question) =>
      question.simple_questions?.map((q) => ({
        patientQuestionnaireId: patientQuestionnaire.id,
        questionnaireType: QUESTIONNAIRE_TYPES.simple,
        id: q.id,
        order: q.order_by,
        question: q.question,
        type: q.question_type,
        isImageLabelsVisible: Boolean(q.question_choices?.[0]?.display_labels),
        isMultiSelection: Boolean(q.question_choices?.[0]?.multiple_selection),
        isRequired: true,
        choices: dedupAnswersByText(
          q.question_choices
            ?.map((c) => ({
              id: c.id,
              order: c.order_by,
              imageUrl: c.image_url,
              imageLabel: c.image_label,
              text: c.text,
            }))
            ?.sort((a, b) => a.order - b.order) || [],
        ),
      })) || [],

    [QUESTIONNAIRE_TYPES.multi]: (question) =>
      question.multi_questions?.map((q) => ({
        patientQuestionnaireId: patientQuestionnaire.id,
        questionnaireType: QUESTIONNAIRE_TYPES.multi,
        id: q.id,
        order: q.order_by,
        question: q.question,
        type: q.question_type,
        isDescriptionVisible: Boolean(q.description),
        description: q.description_text,
        isRequired: Boolean(q.required),
        isCommentsOnScaleAllowed: Boolean(q.why_choose),
        choices: dedupAnswersByText(
          q.procedure_template_question_option
            ?.map((c) => ({
              id: c.id,
              order: c.order_by,
              text: c.question_option,
            }))
            ?.sort((a, b) => a.order - b.order) || [],
        ),
        logic:
          q.procedure_templates_logic?.map((l) => ({
            type: l.type,
            ifSelectedChoiceId: l.procedure_question_option_id,
            goToQuestionId: question.multi_questions?.[l.jump_to_question]?.id,
          })) || [],
      })) || [],
  };

  const questionnaire = patientQuestionnaire?.questionnaire;

  if (questionnaire) {
    return adapterByType[questionnaire.type](questionnaire);
  }

  return [];
}

/**
 * Creates a hash map that has a question ID as key and
 * question schema as value. Used for quick question access
 * by its ID since we have procedure_templates_logic for
 * Multi questionnaire that determines the next question
 * by its ID.
 *
 * @template {Base} Q
 * @param {Array<Q>} adaptedQuestions
 * @returns {{
 *    [key:number]: Q
 * }}
 */
export function buildIdToAdaptedQuestionMap(adaptedQuestions = []) {
  return adaptedQuestions.reduce(
    (carry, question) => ({
      ...carry,
      [question.id]: question,
    }),
    {},
  );
}

/**
 * Creates a hash map that has a question ID as key and
 * another hash map as value that has a data to determine
 * the next question based on order or procedure_templates_logic.
 * Function designed to produce a result that is an intersection
 * data structure for both Simple and Multi question schemas.
 * Each path[n].next should try to contain a value from
 * procedure_templates_logic.jump_to_question that has an `else`
 * type and then fallback to next question ID that sorted by order
 *
 * @template {Base} Q
 * @param {Q} adaptedQuestions
 *
 * @returns {{
 *    initialQuestionId?: number;
 *    path: {
 *      [key:number]: {
 *        next?: number;
 *        nextLogic: {
 *          [key: number]: number;
 *        } | null;
 *      }
 *    }
 * }}
 */
export function buildPath(adaptedQuestions) {
  const nextQuestions = [...adaptedQuestions];
  nextQuestions.sort((a, b) => a.order - b.order);

  return {
    initialQuestionId: nextQuestions[0]?.id,
    path: nextQuestions.reduce((carry, question, index) => {
      const nextQuestion = nextQuestions[index + 1];

      const elseNode = question.logic?.find(
        (l) => l.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.else,
      );

      const jumpNodes = question.logic?.filter(
        (l) => l.type === MULTI_QUESTION_LOGIC_JUMP_TYPES.jump,
      );

      const nextLogic =
        jumpNodes?.reduce(
          (c, j) => ({
            ...c,
            [j.ifSelectedChoiceId]: j.goToQuestionId,
          }),
          {},
        ) || {};

      return {
        ...carry,
        [question.id]: {
          next: elseNode?.goToQuestionId || nextQuestion?.id,
          nextLogic: Object.keys(nextLogic).length > 0 ? nextLogic : null,
        },
      };
    }, {}),
  };
}
