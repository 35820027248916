import * as yup from "yup";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { CREATE_EXAM_INVITE_ADDITION_PATIENT_FIELDS as ADDITION_FIELDS } from "../../../api/qualiphy/useQualiphyCreateExamInviteMutation";
import { phoneUtil } from "../../../utilities/phone";
import { useExtendedFormik } from "../../../hooks/useExtendedFormik";

export function useForm({ onSubmit, additionPatientFields, initialValues }) {
  const { tCommon } = useAppTranslation.Common();

  const additionPatientFieldSchemasByType = {
    [ADDITION_FIELDS.dateOfBirth]: yup.object({
      dateOfBirth: yup
        .string()
        .required(
          tCommon("createQualifyProcedure.formError.dateOfBirthRequired"),
        ),
    }),
    [ADDITION_FIELDS.email]: yup.object({
      email: yup
        .string()
        .email(tCommon("formError.emailInvalid"))
        .required(tCommon("createQualifyProcedure.formError.emailRequired")),
    }),
    [ADDITION_FIELDS.firstName]: yup.object({
      firstName: yup
        .string()
        .required(
          tCommon("createQualifyProcedure.formError.firstNameRequired"),
        ),
    }),
    [ADDITION_FIELDS.lastName]: yup.object({
      lastName: yup
        .string()
        .required(tCommon("createQualifyProcedure.formError.lastNameRequired")),
    }),
    [ADDITION_FIELDS.phone]: yup.object({
      phone: yup
        .string()
        .test({
          test: (value) =>
            !value || phoneUtil.isPossible(phoneUtil.compose(value)),
          message: tCommon("formError.phoneInvalid"),
        })
        .required(tCommon("createQualifyProcedure.formError.phoneRequired")),
    }),
    [ADDITION_FIELDS.gender]: yup.object({
      gender: yup
        .number()
        .required(tCommon("createQualifyProcedure.formError.genderRequired")),
    }),
    [ADDITION_FIELDS.address]: yup.object({
      address: yup
        .string()
        .required(tCommon("createQualifyProcedure.formError.addressRequired")),
    }),
    [ADDITION_FIELDS.city]: yup.object({
      city: yup
        .string()
        .required(tCommon("createQualifyProcedure.formError.cityRequired")),
    }),
    [ADDITION_FIELDS.state]: yup.object({
      state: yup
        .string()
        .required(tCommon("createQualifyProcedure.formError.stateRequired")),
    }),
    [ADDITION_FIELDS.pincode]: yup.object({
      pincode: yup
        .string()
        .required(tCommon("createQualifyProcedure.formError.pincodeRequired")),
    }),
    [ADDITION_FIELDS.shippingAddress]: yup.object({
      shippingAddress: yup
        .string()
        .required(
          tCommon("createQualifyProcedure.formError.shippingAddressRequired"),
        ),
    }),
    [ADDITION_FIELDS.shippingCity]: yup.object({
      shippingCity: yup
        .string()
        .required(
          tCommon("createQualifyProcedure.formError.shippingCityRequired"),
        ),
    }),
    [ADDITION_FIELDS.shippingState]: yup.object({
      shippingState: yup
        .string()
        .required(
          tCommon("createQualifyProcedure.formError.shippingStateRequired"),
        ),
    }),
    [ADDITION_FIELDS.shippingZipcode]: yup.object({
      shippingZipcode: yup
        .string()
        .required(
          tCommon("createQualifyProcedure.formError.shippingZipcodeRequired"),
        ),
    }),
  };

  const getSchema = () => {
    const schema = yup.object({
      clinicId: yup
        .number()
        .required(tCommon("createQualifyProcedure.formError.clinicRequired")),

      exams: yup
        .array()
        .of(
          yup.object({
            value: yup.number(),
            label: yup.string(),
            isAttachmentsRequired: yup.bool(),
            rx: yup.number(),
          }),
        )
        .min(1, tCommon("createQualifyProcedure.formError.examsMin1"))
        .required(tCommon("createQualifyProcedure.formError.examsRequired")),

      attachments: yup.lazy((_, { context }) => {
        const schema = yup.array().of(yup.object());
        if (context.exams.some((e) => e.isAttachmentsRequired)) {
          return schema.min(1);
        }
        return schema;
      }),
    });

    const schemaToConcat = Object.values(ADDITION_FIELDS)
      .filter((f) => (additionPatientFields || []).includes(f))
      .reduce(
        (res, f) => res.concat(additionPatientFieldSchemasByType[f]),
        yup.object({}),
      );

    return schema.concat(schemaToConcat);
  };

  const { values, getError, setFieldValue, handleSubmit, isValid } =
    useExtendedFormik({
      validationSchema: getSchema(),
      enableReinitialize: true,
      onSubmit,
      initialValues: {
        clinicId: "",
        exams: [],
        attachments: [],
        ...initialValues,
      },
    });

  return {
    form: values,
    getError,
    isValid,
    setFormValue: setFieldValue,
    submit: handleSubmit,
  };
}
