import { useEffect, useState } from "react";
import { removeNullishFromShape } from "../../../utilities/general";
import { QUESTIONNAIRE_TYPES } from "../../../consts/api";

const ANSWERS_INITIAL_VALUE = null;

export function useAnswers(patientQuestionnaire) {
  const [answers, setAnswers] = useState(ANSWERS_INITIAL_VALUE);

  const updateAnswer = (key, { choices, reason }) => {
    const nextAnswers = {
      ...answers,
      [key]: {
        choices,
        reason,
      },
    };
    setAnswers(nextAnswers);
    return nextAnswers;
  };

  const getUpdateHandler = (key) => {
    return ({ choices, reason }) => {
      return updateAnswer(key, { choices, reason });
    };
  };

  useEffect(() => {
    if (patientQuestionnaire && answers === ANSWERS_INITIAL_VALUE) {
      const questionnaireType = patientQuestionnaire.data?.questionnaire?.type;

      if (
        !Object.values(QUESTIONNAIRE_TYPES).includes(questionnaireType) ||
        patientQuestionnaire.isLoading
      ) {
        return setAnswers({});
      }

      const answers = patientQuestionnaire?.answers || [];

      setAnswers(
        answers.reduce((carry, answer) => {
          if (answer.question_id) {
            return removeNullishFromShape({
              ...carry,
              [answer.question_id]: {
                choices: answer.answer || [],
                reason: answer.comment || null,
              },
            });
          }
          return carry;
        }, {}),
      );
    }
  }, [patientQuestionnaire.isLoading, patientQuestionnaire.data, answers]);

  return [answers, getUpdateHandler];
}
