import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../shared/Modal/Modal";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { useForm } from "./hooks/useForm";
import { InputLabel } from "../../shared/InputLabel/InputLabel";
import { Select } from "../../shared/Select/Select";
import { InputError } from "../../shared/InputError/InputError";
import { Button } from "../../shared/Button/Button";
import classes from "./ModalCreateQualiphyProcedure.module.scss";
import { useEnrolledClinics } from "./hooks/useEnrolledClinics";
import { useExams } from "./hooks/useExams";
import {
  getAlreadyRequestedFormExams,
  useCreateInvite,
} from "./hooks/useCreateInvite";
import { CREATE_EXAM_INVITE_ADDITION_PATIENT_FIELDS as ADDITION_FIELDS } from "../../api/qualiphy/useQualiphyCreateExamInviteMutation";
import { passOr } from "../../utilities/general";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";
import { AlreadyRequestedBox } from "./shared/AlreadyRequestedBox/AlreadyRequestedBox";
import { Attachments } from "./shared/Attachments/Attachments";
import { useQualiphyMessageQuery } from "../../api/qualiphy/useQualiphyMessageQuery";
import { InputText } from "./shared/InputText/InputText";
import { InputPhone } from "./shared/InputPhone/InputPhone";
import { DobPicker } from "./shared/DobPicker/DobPicker";
import { Dropdown } from "./shared/Dropdown/Dropdown";
import { GENDER_OPTIONS, STATE_OPTIONS } from "./config";

export function ModalCreateQualiphyProcedure({
  isOpen,
  onClose,
  onSuccess,
  patientId,
  title,
  initialValues,
  appointmentId,
}) {
  const { tCommon } = useAppTranslation.Common();
  const createInvite = useCreateInvite({ onSuccess, patientId, appointmentId });
  const { clinicOptions, isClinicsFetching } = useEnrolledClinics();
  const { data: message } = useQualiphyMessageQuery();

  const { form, getError, setFormValue, isValid, submit } = useForm({
    onSubmit: (values) => createInvite.initiate(values),
    additionPatientFields: createInvite.additionPatientFields,
    initialValues: initialValues || {},
  });

  const { examOptions, isExamsFetching } = useExams({
    clinicId: form.clinicId,
  });

  const alreadyRequestedExams = getAlreadyRequestedFormExams(
    form.exams,
    createInvite.alreadyRequestedExams,
  );

  const isAttachmentsRequired = form.exams.some(
    (exam) => exam.isAttachmentsRequired,
  );

  return (
    <Modal
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      shouldCloseOnOverlayClick={false}
      header={
        <Modal.Title>
          {title || tCommon("createQualifyProcedure.title")}
        </Modal.Title>
      }
      footer={
        <div className={classes.footer}>
          <Button variant="outlined" onClick={onClose}>
            {tCommon("label.cancel")}
          </Button>
          <Button
            onClick={submit}
            isDisabled={!isValid || createInvite.isLoading}
            leftAdornment={passOr(createInvite.isLoading, undefined, () => (
              <CircularProgress size="small" color="white" />
            ))}
          >
            {tCommon("label.create")}
          </Button>
        </div>
      }
    >
      <Dropdown
        label={tCommon("label.clinic")}
        options={clinicOptions}
        value={form.clinicId}
        isLoading={isClinicsFetching}
        error={getError("clinicId")}
        onChange={(value) => {
          setFormValue("clinicId", value);
          if (form.exams.length > 0 && value !== form.clinicId) {
            setFormValue("exams", []);
          }
        }}
      />
      {form.clinicId && (
        <div>
          <InputLabel>{tCommon("label.exams")}</InputLabel>
          <Select
            isMulti
            isSearchable
            closeMenuOnSelect={false}
            options={examOptions}
            isLoading={isExamsFetching}
            isError={!!getError("exams")}
            onChange={(options) => setFormValue("exams", options)}
            value={form.exams}
          />
          {!!getError("exams") && <InputError>{getError("exams")}</InputError>}
        </div>
      )}
      {isAttachmentsRequired && (
        <Attachments
          message={message}
          value={form.attachments}
          onChange={(next) => setFormValue("attachments", next)}
        />
      )}
      {createInvite.additionPatientFields.some((f) =>
        Object.values(ADDITION_FIELDS).includes(f),
      ) && (
        <div className={classes.additionData}>
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.firstName,
          ) && (
            <InputText
              label={tCommon("createQualifyProcedure.formLabel.firstName")}
              value={form.firstName}
              placeholder={tCommon("label.firstName")}
              onChange={(next) => setFormValue("firstName", next)}
              error={getError("firstName")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.lastName,
          ) && (
            <InputText
              label={tCommon("createQualifyProcedure.formLabel.lastName")}
              value={form.lastName}
              placeholder={tCommon("label.lastName")}
              onChange={(next) => setFormValue("lastName", next)}
              error={getError("lastName")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.email,
          ) && (
            <InputText
              label={tCommon("createQualifyProcedure.formLabel.email")}
              value={form.email}
              placeholder={tCommon("formPlaceholder.email")}
              onChange={(next) => setFormValue("email", next)}
              error={getError("email")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.phone,
          ) && (
            <InputPhone
              value={form.phone}
              onChange={(phone) => setFormValue("phone", phone)}
              error={getError("phone")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.dateOfBirth,
          ) && (
            <DobPicker
              value={form.dateOfBirth}
              onChange={(next) => setFormValue("dateOfBirth", next)}
              error={getError("dateOfBirth")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.gender,
          ) && (
            <Dropdown
              label={tCommon("createQualifyProcedure.formLabel.gender")}
              options={GENDER_OPTIONS}
              value={form.gender}
              onChange={(next) => setFormValue("gender", next)}
              error={getError("gender")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.address,
          ) && (
            <InputText
              label={tCommon("createQualifyProcedure.formLabel.address")}
              value={form.address}
              placeholder={tCommon("createQualifyProcedure.formLabel.address")}
              onChange={(next) => setFormValue("address", next)}
              error={getError("address")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.city,
          ) && (
            <InputText
              label={tCommon("createQualifyProcedure.formLabel.city")}
              value={form.city}
              placeholder={tCommon("createQualifyProcedure.formLabel.city")}
              onChange={(next) => setFormValue("city", next)}
              error={getError("city")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.state,
          ) && (
            <Dropdown
              label={tCommon("createQualifyProcedure.formLabel.state")}
              options={STATE_OPTIONS}
              value={form.state}
              onChange={(next) => setFormValue("state", next)}
              error={getError("state")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.pincode,
          ) && (
            <InputText
              label={tCommon("createQualifyProcedure.formLabel.pincode")}
              value={form.pincode}
              placeholder={tCommon("createQualifyProcedure.formLabel.pincode")}
              onChange={(next) => setFormValue("pincode", next)}
              error={getError("pincode")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.shippingAddress,
          ) && (
            <InputText
              label={tCommon(
                "createQualifyProcedure.formLabel.shippingAddress",
              )}
              value={form.shippingAddress}
              placeholder={tCommon(
                "createQualifyProcedure.formLabel.shippingAddress",
              )}
              onChange={(next) => setFormValue("shippingAddress", next)}
              error={getError("shippingAddress")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.shippingCity,
          ) && (
            <InputText
              label={tCommon("createQualifyProcedure.formLabel.shippingCity")}
              value={form.shippingCity}
              placeholder={tCommon(
                "createQualifyProcedure.formLabel.shippingCity",
              )}
              onChange={(next) => setFormValue("shippingCity", next)}
              error={getError("shippingCity")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.shippingState,
          ) && (
            <Dropdown
              label={tCommon("createQualifyProcedure.formLabel.shippingState")}
              options={STATE_OPTIONS}
              value={form.shippingState}
              onChange={(next) => setFormValue("shippingState", next)}
              error={getError("shippingState")}
            />
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.shippingZipcode,
          ) && (
            <InputText
              label={tCommon(
                "createQualifyProcedure.formLabel.shippingZipcode",
              )}
              value={form.shippingZipcode}
              placeholder={tCommon(
                "createQualifyProcedure.formLabel.shippingZipcode",
              )}
              onChange={(next) => setFormValue("shippingZipcode", next)}
              error={getError("shippingZipcode")}
            />
          )}
        </div>
      )}
      {alreadyRequestedExams.length > 0 && (
        <AlreadyRequestedBox exams={alreadyRequestedExams} />
      )}
    </Modal>
  );
}

ModalCreateQualiphyProcedure.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  title: PropTypes.string,
  appointmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialValues: PropTypes.object,
};

ModalCreateQualiphyProcedure.defaultProps = {
  onSuccess: () => {},
  title: null,
  appointmentId: null,
  initialValues: null,
};
