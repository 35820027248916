import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const procedureInstance = axios.create();

procedureInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

procedureInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

procedureInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function getProcedureData(procedureID) {
  return (dispatch) => {
    procedureInstance
      .get(process.env.REACT_APP_API_URL + `get-procedure/${procedureID}`)
      .then((response) => {
        dispatch({ type: "GET_PROCEDURE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PROCEDURE_DATA", payload: error.response.data });
      });
  };
}

export function getApptConsultData(formData) {
  return (dispatch) => {
    procedureInstance
      .post(
        process.env.REACT_APP_API_URL + "get-appointment-consultaion-data",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "GET_APPT_CONSULT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_APPT_CONSULT_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function saveProcedure(formData) {
  return (dispatch) => {
    procedureInstance
      .post(
        process.env.REACT_APP_API_URL + "create-procedure",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "CREATE_PROCEDURE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_PROCEDURE", payload: error.response.data });
      });
  };
}

export function updateProcedure(formData, procedureID) {
  return async (dispatch) => {
    return await procedureInstance
      .patch(
        process.env.REACT_APP_API_URL + "update-procedure/" + procedureID,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "UPDATE_PROCEDURE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_PROCEDURE", payload: error.response.data });
        return error.response.data;
      });
  };
}

export function getAssociatedClinics(providerID) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL + "get-associated-clinics/" + providerID,
      )
      .then((response) => {
        dispatch({ type: "GET_ASSOCIATED_CLINICS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_ASSOCIATED_CLINICS",
          payload: error.response.data,
        });
      });
  };
}

export function deleteProcedure(patientID, procedureID) {
  return (dispatch) => {
    procedureInstance
      .delete(
        process.env.REACT_APP_API_URL +
          "delete-procedure/" +
          patientID +
          "/" +
          procedureID,
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "DELETE_PROCEDURE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_PROCEDURE", payload: error.response.data });
      });
  };
}

export function getHealthProcedureData(clientID, procedureID) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL +
          "clients/procedure-health-timeline/" +
          clientID +
          "/" +
          procedureID,
      )
      .then((response) => {
        dispatch({ type: "GET_HEALTH_PROCEDURE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function saveHealthProcedureData(clientID, procedureId, formData) {
  return (dispatch) => {
    procedureInstance
      .post(
        process.env.REACT_APP_API_URL +
          "clients/procedure-health-timeline/" +
          clientID +
          "/" +
          procedureId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "SAVE_HEALTH_PROCEDURE_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_HEALTH_PROCEDURE_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function vieweHealthProcedureData(formData, procedureId) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL +
          "clients/health-procedure-view/" +
          procedureId,
        formData,
      )
      .then((response) => {
        dispatch({
          type: "VIEW_HEALTH_PROCEDURE_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "VIEW_HEALTH_PROCEDURE_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function getProcedureTemplateData(procedureTemplateId) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL +
          "clients/procedure-template-view/" +
          procedureTemplateId,
      )
      .then((response) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_TEMPLATE_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_TEMPLATE_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function getProcedurePrescription(procedureId) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL + "clients/prescription/" + procedureId,
      )
      .then((response) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: error.response.data,
        });
      });
  };
}

export function saveProcedurePrescription(procedureId, formData) {
  return (dispatch) => {
    procedureInstance
      .post(
        process.env.REACT_APP_API_URL + "clients/prescription/" + procedureId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "SAVE_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: response.data,
        });
        dispatch({
          type: "EMPTY_DATA",
          payload: { data: "", status: 200, message: "" },
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: error.response.data,
        });
      });
  };
}

export function deleteProcedurePrescription(procedureId) {
  return (dispatch) => {
    procedureInstance
      .delete(
        process.env.REACT_APP_API_URL + "clients/prescription/" + procedureId,
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "DELETE_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: error.response.data,
        });
      });
  };
}

export function getProcedureConsent(procedureId) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL +
          "clients/health-procedure-consents/" +
          procedureId,
      )
      .then((response) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_CONSENT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_HEALTH_PROCEDURE_CONSENT",
          payload: error.response.data,
        });
      });
  };
}

export function updateProcedureConsent(procedureId, formData) {
  return (dispatch) => {
    procedureInstance
      .put(
        process.env.REACT_APP_API_URL +
          "clients/health-procedure-consents/" +
          procedureId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "UPDATE_HEALTH_PROCEDURE_CONSENT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_HEALTH_PROCEDURE_CONSENT",
          payload: error.response.data,
        });
      });
  };
}

export function sendProcedurePrescription(procedureId, clientID) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL +
          "clients/send-prescription/" +
          procedureId +
          "/" +
          clientID,
      )
      .then((response) => {
        dispatch({
          type: "SEND_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SEND_HEALTH_PROCEDURE_PRESCRIPTION",
          payload: error.response.data,
        });
      });
  };
}

export function emptyProcedureReducer() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_PROCEDURE_REDUCER",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function fetchSelectMD() {
  return (dispatch) => {
    procedureInstance
      .get(process.env.REACT_APP_API_URL + "user/get-all-mds")
      .then((response) => {
        dispatch({ type: "PRO_MDS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PRO_MDS_LIST", payload: error.response.data });
      });
  };
}

const serialize = function (obj) {
  var str = [];

  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }

  return str.join("&");
};

export function signProcedure(formData, fetchRows, listData, roomType) {
  fetchRows = fetchRows || false;
  listData = listData || { params: {} };

  return (dispatch) => {
    procedureInstance
      .post(
        process.env.REACT_APP_API_URL +
          roomType +
          "/sign-consents?" +
          serialize(listData.params),
        formData,
      )
      .then((response) => {
        response.data.status = 201;
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "SIGN_HEALTH_PROCEDURE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SIGN_HEALTH_PROCEDURE",
          payload: error.response.data,
        });
      });
  };
}

export function getServiceQuestionaaries(procedureID, appointmentID) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL +
          "service-onchange/" +
          procedureID +
          "/" +
          appointmentID,
      )
      .then((response) => {
        dispatch({ type: "SERVICE_ON_CHANGE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SERVICE_ON_CHANGE", payload: error.response.data });
      });
  };
}

export function getProcedureQuestionnaireList(formData) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL +
          `patient-questionnaires/procedure/${formData.params.procedure_id}`,
        formData,
      )
      .then((response) => {
        dispatch({
          type: "PROCEDURE_QUESTIONNAIRE_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PROCEDURE_QUESTIONNAIRE_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getProcedureQuestionnaireData(formData) {
  return (dispatch) => {
    procedureInstance
      .get(
        process.env.REACT_APP_API_URL + "get-procedure-questionnaire-detail",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "PROCEDURE_QUESTIONNAIRE_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PROCEDURE_QUESTIONNAIRE_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function saveProcedureQuestionnaireHealth(formData) {
  return (dispatch) => {
    procedureInstance
      .post(
        process.env.REACT_APP_API_URL + "save-healthtimeline-answers",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "SAVE_PROCEDURE_QUESTIONNAIRE_HEALTH",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_PROCEDURE_QUESTIONNAIRE_HEALTH",
          payload: error.response.data,
        });
      });
  };
}

export function addEditProceudreQuestionnaireIds(formData) {
  return (dispatch) => {
    procedureInstance
      .post(
        process.env.REACT_APP_API_URL +
          "clients/add-edit-procedure-questionnaire-ids",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "ADD_EDIT_PROCEDURE_QUESTIONNAIRE_IDS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_EDIT_PROCEDURE_QUESTIONNAIRE_IDS",
          payload: error.response.data,
        });
      });
  };
}
