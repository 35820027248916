import React from "react";
import cx from "clsx";
import { LayoutMain } from "../../../boxes/LayoutMain/LayoutMain";
import { usePatientQuestionnaires } from "./hooks/usePatientQuestionnaires";
import { Box } from "../../../shared/Box/Box";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { getPathParams } from "./PatientQuestionnaires.utils";
import { Empty } from "../../../shared/Empty/Empty";
import classes from "./PatientQuestionnaires.module.scss";
import { Skeleton } from "../../../shared/Skeleton/Skeleton";
import { Questionnaire } from "./shared/Questionnaire/Questionnaire";
import { PageHeader } from "../../../boxes/PageHeader/PageHeader";
import { PATIENT_QUESTIONNAIRE_ENTITY_TYPES } from "../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnairesQuery";
import { PatientDocumentInfo } from "../shared/PatientDocumentInfo/PatientDocumentInfo";
import { history } from "../../../history";
import { ROUTES } from "../../../consts/routes";

export function PatientQuestionnaires() {
  const { isEntityTypeValid, entityType } = getPathParams();
  const { tCommon } = useAppTranslation.Common();

  const {
    data,
    isLoading,
    isVisible,
    isEmpty,
    isError,
    refetch,
    patientId,
    appointmentId,
    procedureId,
  } = usePatientQuestionnaires();

  const titleByEntityType = {
    [PATIENT_QUESTIONNAIRE_ENTITY_TYPES.appointment]: tCommon(
      "patientQuestionnaires.appointmentTitle",
    ),
    [PATIENT_QUESTIONNAIRE_ENTITY_TYPES.patient]: tCommon(
      "patientQuestionnaires.title",
    ),
    [PATIENT_QUESTIONNAIRE_ENTITY_TYPES.procedure]: tCommon(
      "patientQuestionnaires.procedureTitle",
    ),
  };

  return (
    <LayoutMain>
      <Box className={classes.root}>
        <PageHeader
          onBack={() => {
            if (history.location.state?.backToProfile) {
              history.push(ROUTES.patients.profile(patientId));
            } else {
              history.goBack();
            }
          }}
          title={titleByEntityType[entityType]}
          rightAdornment={
            !isLoading && !isError ? (
              <PatientDocumentInfo
                patientId={patientId}
                appointmentId={appointmentId}
                procedureId={procedureId}
              />
            ) : undefined
          }
        />
        {isEntityTypeValid ? (
          <div
            className={cx(classes.questionnairesList, {
              [classes.questionnaireListEmpty]: isEmpty || isError,
            })}
          >
            {isLoading &&
              Array.from(Array(12).keys()).map((k) => (
                <Skeleton key={k} count={1} height={112} />
              ))}
            {isVisible &&
              data.map((patientQuestionnaire) => (
                <Questionnaire
                  key={patientQuestionnaire.id}
                  patientQuestionnaire={patientQuestionnaire}
                  refetchQuestionnaires={refetch}
                />
              ))}
            {(isEmpty || isError) && (
              <Empty position="center">
                {tCommon("patientQuestionnaires.notFound")}
              </Empty>
            )}
          </div>
        ) : (
          <Empty position="center">
            {tCommon("patientQuestionnaires.notFound")}
          </Empty>
        )}
      </Box>
    </LayoutMain>
  );
}
