import { DEFAULT_MEMBERSHIP } from "./config";

export const isDefaultMembership = (option) =>
  option?.value === DEFAULT_MEMBERSHIP.value;

/**
 * @param {Object} param0
 *
 * @param {{
 *    perUnit: number;
 *    member: number;
 *    perClinic: {
 *      [clinicId: string]: {
 *        perUnit: number;
 *        member: number;
 *        membershipId: number;
 *      };
 *    };
 * }} param0.prices
 *
 * @param {{
 *    perUnit: number;
 *    member: number;
 *    perClinic: {
 *      [clinicId: string]: {
 *        perUnit: number;
 *        member: number;
 *        membershipId: number;
 *      };
 *    };
 * }} param0.initialPrices
 *
 * @param {boolean} param0.forAllClinics
 *
 * @returns {boolean}
 */
export const isPricesChanged = ({ prices, initialPrices, forAllClinics }) => {
  if (forAllClinics) {
    return (
      String(prices.perUnit) !== String(initialPrices.perUnit) ||
      String(prices.member) !== String(initialPrices.member)
    );
  }

  const createSnapshot = (prices) => {
    return Object.entries(prices).reduce((acc, [clinicId, price]) => {
      return (acc += `#${clinicId}/${price.perUnit}/#${price.member}`);
    }, "");
  };

  return (
    createSnapshot(prices.perClinic) !== createSnapshot(initialPrices.perClinic)
  );
};

/**
 * @param {Object} param0
 * @param {Array<{
 *    clinic_id: number;
 *    membership_tier_id: number;
 *    localtion_price_per_unit: number;
 *    location_price_per_member: number;
 * }>} param0.requestPrices
 * @param {{
 *    perUnit: number | string;
 *    member: number | string;
 * }} param0.changedPrices
 * @param {boolean} param0.forAllClinics
 */
export const validateChangedPricesForRequest = ({
  requestChangedPrices,
  changedPrices,
  forAllClinics,
}) => {
  if (forAllClinics) {
    return {
      perUnitError: Number.isNaN(parseFloat(changedPrices.perUnit)),
      perClinicErrors: [],
    };
  }

  return {
    perUnitError: false,
    perClinicErrors: requestChangedPrices
      .map(({ clinic_id, location_price_per_unit }) => {
        if (Number.isNaN(parseFloat(location_price_per_unit))) {
          return clinic_id;
        }

        return undefined;
      })
      .filter(Boolean),
  };
};
