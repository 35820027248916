import { ACTION_TYPES } from "./config";

export const actions = {
  /**
   * @param {boolean} status
   */
  setForAllClinics: (status) => ({
    type: ACTION_TYPES.setForAllClinics,
    payload: {
      status,
    },
  }),

  /**
   * @param {boolean} status
   */
  setForAllMemberships: (status) => ({
    type: ACTION_TYPES.setForAllMemberships,
    payload: {
      status,
    },
  }),

  /**
   * @param {{
   *    label: string;
   *    value: number;
   * }} membership
   */
  selectMembership: (membership) => ({
    type: ACTION_TYPES.selectMembership,
    payload: {
      membership,
    },
  }),

  /**
   * @param {Array<{
   *    label: string;
   *    value: string;
   * }>} memberships
   */
  setMemberships: (memberships = []) => ({
    type: ACTION_TYPES.setMemberships,
    payload: {
      memberships,
    },
  }),

  /**
   * @param {{
   *    [clinicId: string]: {
   *      perUnit: number;
   *      member: number;
   *      membershipId: number;
   *    }
   * }} prices
   */
  setPrices: (prices = {}) => ({
    type: ACTION_TYPES.setPrices,
    payload: {
      prices,
    },
  }),

  /**
   * @param {{
   *    type: string;
   *    value: number | string;
   *    clinicId: string | number;
   * }} param0
   */
  changePrice: ({ type, value, clinicId }) => ({
    type: ACTION_TYPES.changePrice,
    payload: {
      type,
      value,
      clinicId,
    },
  }),

  /**
   * @param {boolean} status
   */
  setInitialized: (status) => ({
    type: ACTION_TYPES.setInitialized,
    payload: {
      status,
    },
  }),

  /**
   * @param {Array<{
   *    id: number;
   *    name: string;
   * }>} clinics
   */
  setClinics: (clinics) => ({
    type: ACTION_TYPES.setClinics,
    payload: {
      clinics,
    },
  }),

  revertChangedPrices: () => ({
    type: ACTION_TYPES.revertChangedPrices,
  }),

  setOriginalToChangedPrices: () => ({
    type: ACTION_TYPES.setOriginalToChangedPrices,
  }),
};
