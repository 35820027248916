import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";
import { uiNotification } from "../../../../../../services/UINotificationService";

export const useDownloadStripeDocument = (options = {}) => {
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const mutation = useMutation(
    (dto) => http.post(HTTP_ENDPOINTS.downloadStripeDocument(), dto),
    {
      onSuccess: (data) => window.open(data.data.data.download_url, "_blank"),
      onError: (error) => uiNotification.error(error.response.data.message),
      ...options,
    },
  );

  const handleSelectDocument = (documentId) => {
    if (selectedDocuments.includes(documentId)) {
      setSelectedDocuments(selectedDocuments.filter((id) => id !== documentId));
    } else {
      setSelectedDocuments([...selectedDocuments, documentId]);
    }
  };

  const handleSelectAllDocuments = (documents) => {
    if (selectedDocuments.length === 0) {
      setSelectedDocuments(documents.map((document) => document.id));
    } else {
      setSelectedDocuments([]);
    }
  };

  return {
    mutation,
    selectedDocuments: {
      value: selectedDocuments,
      update: setSelectedDocuments,
      handleSelectDocument,
      handleSelectAllDocuments,
    },
  };
};
