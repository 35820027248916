import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Modal } from "../../shared/Modal/Modal";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { Header } from "./shared/Header/Header";
import classes from "./styles.module.scss";
import { Empty } from "../../shared/Empty/Empty";
import { Question } from "./boxes/Question/Question";
import { useSubmit } from "./hooks/useSubmit";
import { usePatientQuestionnaire } from "./hooks/usePatientQuestionnaire";
import {
  buildIdToAdaptedQuestionMap,
  buildPath,
  questionsAdapter,
} from "./utilities";
import { useNavigator } from "./hooks/useNavigator";
import { useAnswers } from "./hooks/useAnswers";

export function ModalPatientQuestionnaireFill({
  isOpen,
  onClose,
  patientQuestionnaireId,
  preloadedPatientQuestionnaire,
  onSuccess,
}) {
  const { tCommon } = useAppTranslation.Common();
  const submit = useSubmit();

  const patientQuestionnaire = usePatientQuestionnaire({
    patientQuestionnaireId,
    preloadedPatientQuestionnaire,
  });

  const adaptedQuestions = useMemo(
    () => questionsAdapter(patientQuestionnaire.data),
    [patientQuestionnaire.data],
  );

  const idToAdaptedQuestionMap = useMemo(
    () => buildIdToAdaptedQuestionMap(adaptedQuestions),
    [adaptedQuestions],
  );

  const navigator = useNavigator(buildPath(adaptedQuestions));
  const currentQuestion = idToAdaptedQuestionMap[navigator.head];
  const [answers, getUpdateHandler] = useAnswers(patientQuestionnaire);

  const onNext = (nextAnswers) => {
    const headAnswer = nextAnswers[navigator.head];

    const nextLogicKey = idToAdaptedQuestionMap?.[
      navigator.head
    ]?.choices?.find((x) => x.text === headAnswer?.choices?.[0])?.id;

    if (navigator.canGoForward) {
      navigator.goForward(nextLogicKey);
    } else {
      submit.initiate(nextAnswers, idToAdaptedQuestionMap).then(() => {
        onSuccess?.();
      });
    }
  };

  return (
    <Modal
      size="large"
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      header={<Header />}
    >
      {patientQuestionnaireId || preloadedPatientQuestionnaire ? (
        <>
          {patientQuestionnaire.isLoading ? (
            <Empty position="center">{tCommon("label.loading")}...</Empty>
          ) : currentQuestion ? (
            <Question
              key={currentQuestion.id}
              question={currentQuestion}
              answer={answers?.[currentQuestion.id]}
              updateAnswer={getUpdateHandler(currentQuestion.id)}
              onNext={onNext}
              onPrev={navigator.goBackward}
              canGoNext={navigator.canGoForward}
              canGoPrev={navigator.canGoBackward}
              isSubmitting={submit.isLoading}
            />
          ) : null}
        </>
      ) : (
        <Empty position="center">
          {tCommon("fillPatientQuestionnaire.notFound")}
        </Empty>
      )}
    </Modal>
  );
}

ModalPatientQuestionnaireFill.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  patientQuestionnaireId: PropTypes.number,
  preloadedPatientQuestionnaire: PropTypes.object,
  onSuccess: PropTypes.func,
};
