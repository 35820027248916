import { ACTION_TYPES, DEFAULT_MEMBERSHIP } from "./config";

const initialState = {
  initialized: false,
  forAllClinics: false,
  forAllMemberships: true,
  membership: DEFAULT_MEMBERSHIP,
  memberships: [],
  clinics: [],
  prices: {
    original: {
      perUnit: 0,
      member: 0,
      perClinic: {},
    },
    changed: {
      perUnit: 0,
      member: 0,
      perClinic: {},
    },
  },
};

function setForAllClinics(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    forAllClinics: status || false,
  };
}

function setForAllMemberships(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    forAllMemberships: status || false,
    membership: status
      ? DEFAULT_MEMBERSHIP
      : state.memberships?.[1] || state.membership,
  };
}

function selectMembership(state, action) {
  const { membership } = action.payload;

  return {
    ...state,
    membership,
    forAllMemberships:
      String(membership.value) === String(DEFAULT_MEMBERSHIP.value),
  };
}

function setMemberships(state, action) {
  const { memberships } = action.payload;

  return {
    ...state,
    memberships,
  };
}

function setPrices(state, action) {
  const { prices } = action.payload;

  const isForAllMemberships = Object.values(prices).every(
    (x) => x.membershipId === DEFAULT_MEMBERSHIP.value,
  );

  const isForAllClinics = (() => {
    const perUnit = new Set(Object.values(prices).map((x) => x.perUnit));
    const member = new Set(Object.values(prices).map((x) => x.member));

    return perUnit.size === 1 && member.size === 1;
  })();

  const perUnit = isForAllClinics
    ? Object.values(prices || {})[0]?.perUnit || ""
    : "";

  const member = isForAllClinics
    ? Object.values(prices || {})[0]?.member || ""
    : "";

  return {
    ...state,

    forAllMemberships: isForAllMemberships,

    forAllClinics: isForAllClinics,

    membership: isForAllMemberships
      ? DEFAULT_MEMBERSHIP
      : state.memberships?.find((x) => x.value === prices?.[0]?.membershipId) ||
        state.membership,

    prices: {
      original: {
        perUnit,
        member,
        perClinic: {
          ...prices,
        },
      },
      changed: {
        perUnit,
        member,
        perClinic: {
          ...prices,
        },
      },
    },
  };
}

function changePrice(state, action) {
  const { type, value, clinicId } = action.payload;
  const nextChanged = state.prices.changed || {};

  if (clinicId) {
    nextChanged.perClinic[clinicId] = {
      ...nextChanged.perClinic[clinicId],
      [type]: value,
    };
  } else {
    nextChanged[type] = value;
  }

  return {
    ...state,
    prices: {
      ...state.prices,
      changed: nextChanged,
    },
  };
}

function setInitialized(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    initialized: status || false,
  };
}

function setClinics(state, action) {
  const { clinics } = action.payload;

  return {
    ...state,
    clinics,
  };
}

function revertChangedPrices(state) {
  return {
    ...state,
    prices: {
      ...state.prices,
      changed: {
        ...state.prices.original,
      },
    },
  };
}

function setOriginalToChangedPrices(state) {
  return {
    ...state,
    prices: {
      ...state.prices,
      original: {
        ...state.prices.changed,
      },
    },
  };
}

export function reducer(state = initialState, action) {
  const actionByType = {
    [ACTION_TYPES.setForAllClinics]: setForAllClinics,
    [ACTION_TYPES.setForAllMemberships]: setForAllMemberships,
    [ACTION_TYPES.selectMembership]: selectMembership,
    [ACTION_TYPES.setMemberships]: setMemberships,
    [ACTION_TYPES.setPrices]: setPrices,
    [ACTION_TYPES.changePrice]: changePrice,
    [ACTION_TYPES.setInitialized]: setInitialized,
    [ACTION_TYPES.setClinics]: setClinics,
    [ACTION_TYPES.revertChangedPrices]: revertChangedPrices,
    [ACTION_TYPES.setOriginalToChangedPrices]: setOriginalToChangedPrices,
  };

  return actionByType?.[action.type]?.(state, action) || state;
}
