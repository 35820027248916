import React from "react";
import * as yup from "yup";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import classes from "./AnswerChoices.module.scss";
import { Textarea } from "../../../../../../shared/Textarea/Textarea";
import { QUESTIONNAIRE_YES_NO_ANSWERS } from "../../../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnaireAnswersMutation";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

export function AnswerChoices({
  isMulti,
  isRequired,
  initialValues,
  options,
  renderFooter,
  onSubmit,
}) {
  const { tCommon } = useAppTranslation.Common();

  const validationSchema = yup.object({
    choices: yup.lazy(() => {
      const schema = yup.array().of(yup.string());
      if (isRequired) {
        return schema.min(1);
      }
      return schema;
    }),
    reason: yup.string(),
  });

  const { values, setFieldValue, handleSubmit, errors, touched, handleChange } =
    useFormik({
      validationSchema,
      onSubmit,
      enableReinitialize: true,
      initialValues: {
        choices: initialValues.choices || [],
        reason: initialValues.reason || "",
      },
    });

  const onSelect = (nextOption) => {
    const add = (o) => {
      if (isMulti) {
        setFieldValue("choices", [...values.choices, o]);
      } else {
        setFieldValue("choices", [o]);
      }
    };

    const remove = (o) => {
      setFieldValue(
        "choices",
        values.choices.filter((i) => i !== o),
      );
    };

    if (values.choices.includes(nextOption)) {
      remove(nextOption);
    } else {
      add(nextOption);
    }
  };

  const isError = touched.choices && errors.choices;

  return (
    <div className={classes.root}>
      <div className={classes.options}>
        {options.map((option, index) => {
          const isSelected = values.choices.includes(option.value);
          return (
            <button
              key={index}
              onClick={() => onSelect(option.value)}
              className={isSelected ? classes.selected : undefined}
            >
              {option.slot || option.renderSlot({ isSelected })}
            </button>
          );
        })}
      </div>
      {values.choices[0] === QUESTIONNAIRE_YES_NO_ANSWERS.yes && (
        <Textarea
          name="reason"
          value={values.reason}
          placeholder={tCommon("fillPatientQuestionnaire.comment")}
          onChange={handleChange}
        />
      )}
      {renderFooter(handleSubmit, isError)}
    </div>
  );
}

AnswerChoices.propTypes = {
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    choices: PropTypes.arrayOf(PropTypes.string),
    reason: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      slot: PropTypes.node,
      renderSlot: PropTypes.func,
    }),
  ),
  renderFooter: PropTypes.func.isRequired,
};
