import React from "react";
import { useCurrentAccountQuery } from "../../../../../api/queries/useAccountQuery";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { USER_PAYMENT_SYSTEMS } from "../../../../../consts/api";
import styles from "./ClearentDisclaimer.module.scss"

const URL = "https://learn.aestheticrecord.com/en/articles/9243258-chargebacks-or-disputes-with-clearent";

export function ClearentDisclaimer() {
  const { data: user } = useCurrentAccountQuery();
  const isClearent = Boolean(user?.paymentSystem === USER_PAYMENT_SYSTEMS.clearent);

  if (isClearent) {
    return (
      <AlertBox className={styles.root}>
        <div className={styles.inner}>
          <b>Disclaimer:</b>
          <p>
            As a Clearent POS user, please note that all information regarding disputes can only be accessed
            through
            the Merchant Home (Compass). Dispute details will not appear in the AR dispute folder.
          </p>
          <p> Please read the article for better understanding of how to set up Merchant Portal (Compass) and
            handle
            disputes when using Clearent
            - <a href={URL} target="_blank" rel="noreferrer noopener">{URL}</a>
          </p>
        </div>
      </AlertBox>
    );
  }

  return null;
}
