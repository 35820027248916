import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";
import { http } from "../../../services/HttpService";
import { patientQuestionnaireSchema } from "./schema";

export const PATIENT_QUESTIONNAIRE_ENTITY_TYPES = {
  patient: "patient",
  appointment: "appointment",
  procedure: "procedure",
};

const requestPayload = yup.object({
  entityId: yup.number().required(),
  entityType: yup.string().required(),
  serviceId: yup.number().nullable(),
});

const responseSchema = yup.array().of(patientQuestionnaireSchema);

const composeResponse = (res) => {
  if (Array.isArray(res?.data?.data)) {
    return res.data.data;
  }
  return [];
};

export function createPatientQuestionnairesQueryKey(
  entityId,
  entityType,
  serviceId,
) {
  return [
    QUERY_KEYS.patientQuestionnaires.getByEntity,
    entityId,
    entityType,
    serviceId,
  ];
}

export function usePatientQuestionnairesQuery({ payload, options = {} } = {}) {
  return useQuery(
    createPatientQuestionnairesQueryKey(
      payload.entityId,
      payload.entityType,
      payload.serviceId,
    ),
    async () => {
      const req = requestPayload.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.patientQuestionnaires.getByEntity(
          req.entityId,
          req.entityType,
        ),
        req.serviceId ? { params: { service_id: req.serviceId } } : {},
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
