import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import classes from "./Footer.module.scss";
import { Button } from "../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { CircularProgress } from "../../../../../shared/CircularProgress/CircularProgress";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import { InputError } from "../../../../../shared/InputError/InputError";

function Footer({
  onDelete,
  onSubmit,
  isDeleting,
  isSubmitting,
  history,
  isFormError,
  isLoading,
  isEditMode,
  submitLabel,
  additionButtons,
  isSubmitDisabled,
}) {
  const { tCommon } = useAppTranslation.Common();

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  return (
    <div className={classes.root}>
      <div>
        {isEditMode && (
          <Button
            color="error"
            onClick={() => setIsConfirmDeleteModalOpen(true)}
            isDisabled={isDeleting || isLoading}
            leftAdornment={
              isDeleting ? (
                <CircularProgress color="white" size="small" />
              ) : undefined
            }
          >
            {tCommon("label.delete")}
          </Button>
        )}
      </div>
      <div className={classes.right}>
        <div className={classes.buttons}>
          <Button variant="outlined" onClick={() => history.goBack()}>
            {tCommon("label.cancel")}
          </Button>
          {additionButtons?.map((b, i) => (
            <Button
              key={`${b.label} ${i}`}
              isDisabled={b.isLoading || b.isDisabled || isLoading}
              onClick={b.onClick}
              leftAdornment={
                b.isLoading ? (
                  <CircularProgress color="white" size="small" />
                ) : undefined
              }
            >
              {b.label}
            </Button>
          ))}
          <Button
            onClick={onSubmit}
            isDisabled={isSubmitting || isLoading || isSubmitDisabled}
            leftAdornment={
              isSubmitting ? (
                <CircularProgress color="white" size="small" />
              ) : undefined
            }
          >
            {isEditMode
              ? tCommon("label.save")
              : submitLabel || tCommon("label.add")}
          </Button>
        </div>
        {isFormError && <InputError>{tCommon("error.form")}</InputError>}
      </div>
      {isConfirmDeleteModalOpen && (
        <ConfirmModal
          isOpen
          onCancel={() => setIsConfirmDeleteModalOpen(false)}
          onClose={() => setIsConfirmDeleteModalOpen(false)}
          onConfirm={() => {
            setIsConfirmDeleteModalOpen(false);
            onDelete();
          }}
        >
          {tCommon("confirm.deleteProcedure")}
        </ConfirmModal>
      )}
    </div>
  );
}

Footer.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isFormError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isEditMode: PropTypes.bool,
  submitLabel: PropTypes.string,
  additionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      isDisabled: PropTypes.bool,
      isLoading: PropTypes.bool,
    }),
  ),
  isSubmitDisabled: PropTypes.bool,
};

export default withRouter(Footer);
