import React from "react";
import classes from "./Footer.module.scss";
import { Button } from "../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { CheckIcon } from "../../../../../../assets/Icons/CheckIcon";
import { ChevronLeftIcon } from "../../../../../../assets/Icons/ChevronLeftIcon";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import { AlertBox } from "../../../../../../shared/AlertBox/AlertBox";

export function Footer({
  onPrev,
  onNext,
  isPrevDisabled,
  isSubmitVisible,
  isSubmitting,
  isQuestionNotFilled,
}) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <Button
          variant="outlined"
          onClick={onPrev}
          isDisabled={isPrevDisabled}
          leftAdornment={<ChevronLeftIcon />}
        >
          {tCommon("label.previous")}
        </Button>
        {isSubmitVisible ? (
          <Button
            color="success"
            onClick={onNext}
            isDisabled={isSubmitting}
            rightAdornment={
              isSubmitting ? (
                <CircularProgress color="white" size="small" />
              ) : (
                <CheckIcon width="20px" />
              )
            }
          >
            {tCommon("label.submit")}
          </Button>
        ) : (
          <Button
            onClick={onNext}
            rightAdornment={<ChevronLeftIcon className={classes.nextIcon} />}
          >
            {tCommon("label.next")}
          </Button>
        )}
      </div>
      {isQuestionNotFilled && (
        <AlertBox variant="error" size="small">
          {tCommon("fillPatientQuestionnaire.error.requiredQuestion")}
        </AlertBox>
      )}
    </div>
  );
}
