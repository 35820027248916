import React from "react";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import classes from "./MultiQuestionBuilderModal.module.scss";
import { useForm } from "./hooks/useForm";
import { Input } from "../../../../../../../shared/Input/Input";
import { Select } from "../../../../../../../shared/Select/Select";
import { MULTI_QUESTION_TYPES } from "../../../../../../../api/questionnaires/config";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { ModalQuestionBuilder } from "../../../../shared/ModalQuestionBuilder/ModalQuestionBuilder";
import { Textarea } from "../../../../../../../shared/Textarea/Textarea";
import { Checkbox } from "../../../../../../../shared/Checkbox/Checkbox";
import { LogicJumps } from "../../../../shared/LogicJumps/LogicJumps";
import {
  QUESTION_TYPES_WITH_CHOICES,
  QUESTION_TYPES_WITH_LOGIC_JUMPS,
} from "../../hooks/useForm";
import { getIsSomeLogicJumpsFilled } from "../../MultiQuestionnaireCreateEdit.utils";
import { QuestionChoices } from "../../../../boxes/QuestionChoices/QuestionChoices";
import { passOr } from "../../../../../../../utilities/general";

export function MultiQuestionBuilderModal({
  isOpen,
  onAdd,
  onUpdateByIndex,
  preloadedQuestion,
  onClose,
  questions,
}) {
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();

  const typeOptions = Object.values(MULTI_QUESTION_TYPES).map((type) => ({
    label: tSettings(`questionnaires.multi.types.${type}`),
    value: type,
  }));

  const form = useForm({
    preloadedQuestion,
    onSubmit: (formValues) => {
      if (preloadedQuestion) {
        onUpdateByIndex(formValues, preloadedQuestion.index);
      } else {
        onAdd(formValues);
      }
    },
  });

  return (
    <ModalQuestionBuilder
      isOpen={isOpen}
      onClose={onClose}
      isEditMode={Boolean(preloadedQuestion)}
      onSubmit={form.handleSubmit}
      onReset={form.dirty ? form.resetForm : undefined}
      error={form.getError("logicJumps") || form.getError("choices")}
    >
      <div className={classes.row}>
        <div>
          <InputLabel>{tCommon("label.question")}</InputLabel>
          <Input
            name="question"
            value={form.values.question}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            isError={Boolean(form.getError("question"))}
          />
          {Boolean(form.getError("question")) && (
            <InputError>{form.getError("question")}</InputError>
          )}
        </div>
        <div>
          <InputLabel>{tCommon("label.questionType")}</InputLabel>
          <Select
            value={typeOptions.find((o) => o.value === form.questionType.value)}
            options={typeOptions}
            onChange={(next) => form.questionType.onChange(next.value)}
            isError={Boolean(form.getError("questionType"))}
          />
          {Boolean(form.getError("questionType")) && (
            <InputError>{form.getError("questionType")}</InputError>
          )}
        </div>
      </div>
      <Checkbox
        label={tCommon("label.required")}
        isChecked={form.values.isRequired}
        onChange={() =>
          form.setFieldValue("isRequired", !form.values.isRequired)
        }
      />
      {form.questionType.value === MULTI_QUESTION_TYPES.opinionScale && (
        <Checkbox
          label={tSettings(
            "questionnaires.questionBuilder.allowCommentsOnScale",
          )}
          isChecked={form.values.allowCommentsOnScale}
          onChange={() =>
            form.setFieldValue(
              "allowCommentsOnScale",
              !form.values.allowCommentsOnScale,
            )
          }
        />
      )}
      <div>
        <InputLabel>{tCommon("label.description")}</InputLabel>
        <Textarea
          name="description"
          value={form.values.description}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
      </div>
      {QUESTION_TYPES_WITH_CHOICES.includes(form.questionType.value) && (
        <QuestionChoices
          choices={form.choices.value}
          onAdd={form.choices.add}
          onSortEnd={form.choices.replace}
        >
          {(choice, index) => (
            <QuestionChoices.Text
              key={index}
              index={index}
              value={choice.value}
              onChange={(e) =>
                form.choices.changeByIndex(e.target.value, index)
              }
              onRemove={passOr(
                index !== 0,
                undefined,
                () => () => form.choices.deleteByIndex(index),
              )}
            />
          )}
        </QuestionChoices>
      )}
      {QUESTION_TYPES_WITH_LOGIC_JUMPS.includes(form.questionType.value) && (
        <LogicJumps
          nodes={form.logicJumps.value}
          onAddNode={form.logicJumps.insert}
          onRemoveNode={form.logicJumps.removeByIndex}
          onChangeNodeValue={form.logicJumps.changeByIndex}
          choices={form.values.choices}
          questions={questions}
          preloadedQuestionIndex={preloadedQuestion?.index}
          isInitialOpen={getIsSomeLogicJumpsFilled(form.logicJumps.value)}
        />
      )}
    </ModalQuestionBuilder>
  );
}
