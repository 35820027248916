import React, { useState } from "react";
import cx from "clsx";
import { useChangePatientAccessStatusMutation } from "../../../../../api/patient/useChangePatientAccessStatusMutation";
import styles from "./ClientAccess.module.scss";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import { useClientQuery } from "../../../../../api/queries/useClientQuery";
import { uiNotification } from "../../../../../services/UINotificationService";
import { useVerifyPatientPortalEmailMutation } from "../../../../../api/patient/useVerifyPatientPortalEmailMutation";
import { usePatientWelcomeEmailMutation } from "../../../../../api/patient/usePatientWelcomeEmailMutation";
import { getPathParams } from "../../utils/utils";
import { useClientInfo } from "../../ClientInfo";
import { ResetPasswordModal } from "./ResetPasswordModal/ResetPasswordModal";

export function ClientAccess() {
  const { clientId } = getPathParams();
  const { showLoader, hideLoader } = useClientInfo();

  const [isOpen, setIsOpen] = useState({ confirm: false, reset: false });

  const { data: clientData } = useClientQuery({ clientId });

  const isEmailVerified = clientData?.patientAccount?.patient_user.status;

  const onChangeAccessStatus = useChangePatientAccessStatusMutation({
    onSuccess: () => {
      setIsOpen({ confirm: false, reset: false });
    },
    onError: () => {
      uiNotification.error("Unable to change status. Try again later");
      setIsOpen({ confirm: false, reset: false });
    },
  });

  const onVerifyEmail = useVerifyPatientPortalEmailMutation({
    onSuccess: () => {
      uiNotification.success("Patient email verified");
    },
    onError: () => {
      uiNotification.error("Unable to save your settings. Try again later");
    },
    onMutate: () => {
      showLoader();
    },
    onSettled: () => {
      hideLoader();
    }
  });

  const onResendEmail = usePatientWelcomeEmailMutation({
    onSuccess: () => {
      uiNotification.success("Email has been resend");
    },
    onError: () => {
      uiNotification.error("Unable to verify email. Try again later");
    },
    onMutate: () => {
      showLoader();
    },
    onSettled: () => {
      hideLoader();
    }
  });

  const onClose = () => {
    setIsOpen({ confirm: false, reset: false });
  };

  const resendEmail = () => {
     onResendEmail.mutate({ clientId });
  };

  const verifyEmail =  () => {
    const userEmail = clientData?.emails[0];
    if (userEmail) {
       onVerifyEmail.mutate({ portalEmail: userEmail });
    }
  };

  const changeAccessStatus = () => {
    onChangeAccessStatus.mutate({ clientId });
  };

  return (
    <div>
      {clientData?.emails[0] &&
        clientData?.accountPreferences?.patient_sign_up !== 0 && (
          <div className={cx(styles.portalSection, "col-xs-12")}>
            <label htmlFor="accessSwitchContainer" className={styles.infoItem}>Access Patient Portal</label>
            <div id="accessSwitchContainer" className={styles.portalContainer}>
              <div className={styles.portalTable}>
                <span className={styles.portalLabel}>Invited </span>
                <label className={cx(styles.switch, "setting-switch")}>
                  <input
                    type="checkbox"
                    name="access_portal"
                    checked={Boolean(clientData?.accessPortal)}
                    className="setting-custom-switch-input"
                    onChange={() => setIsOpen({ confirm: true, reset: false })}
                  />
                  <span className="setting-slider"></span>
                </label>
                {clientData?.patientAccount && (
                  <button
                    onClick={() => setIsOpen({ confirm: false, reset: true })}
                    className="line-btn pull-right restPasPtn"
                    type="button"
                    id="reset_portal_btn"
                  >
                    Reset Password
                  </button>
                )}
              </div>
              {clientData?.accessPortal === 1 && (
                <div className={cx(styles.portalTable, "acc_class")}>
                  <span className={styles.portalLabel}>Accepted</span>
                  <span className={cx(styles.portalContent, "pull-i")}>
                    {clientData?.patientAccount ? "Yes" : "No"}
                  </span>
                  <span className={styles.portalContent}>
                    {!clientData?.patientAccount && (
                      <button
                        onClick={() => resendEmail()}
                        className="line-btn pull-right"
                        type="button"
                      >
                        Resend Email
                      </button>
                    )}
                  </span>
                </div>
              )}
              {clientData?.patientAccount && (
                <div className={styles.portalTable}>
                  <span className={styles.portalLabel}>Email Verified</span>
                  <span className={cx(styles.portalContent, "pull-i")}>
                    {isEmailVerified === 0 ? "Yes" : "No"}
                  </span>
                  <span className={styles.portalContent}>
                    {clientData?.patientAccount && isEmailVerified === 1 && (
                      <button
                        onClick={() => verifyEmail()}
                        className="line-btn pull-right"
                        type="button"
                      >
                        Verify Email
                      </button>
                    )}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      {isOpen.reset && (
        <ResetPasswordModal
          isOpen={isOpen.reset}
          onClose={onClose}
          email={clientData?.patientAccount?.patient_user?.email}
          clientId={clientData?.patientAccount?.patient_user_id}
        />
      )}
      {isOpen.confirm && (
        <ConfirmModal
          isConfirming={onChangeAccessStatus.isLoading}
          isOpen={isOpen.confirm}
          onConfirm={changeAccessStatus}
          onCancel={onClose}
          onClose={onClose}
        >
          <div>
            <h4>Are you sure you want to change this access?</h4>
          </div>
        </ConfirmModal>
      )}
    </div>
  );
}
