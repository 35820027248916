import * as yup from "yup";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { API_GENDERS_MAP, HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clientId: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),

  fullName: yup.string().required(),

  dateOfBirth: yup.string().nullable(),

  emails: yup.array().of(yup.string()).required(),

  phones: yup.array().of(yup.string()).required(),

  pinCode: yup.string().nullable(),

  gender: yup.string().nullable(),

  documentsExpirationDate: yup.string().nullable(),

  canAddCreditCard: yup.bool().required(),

  state: yup.string().nullable(),

  referralSource: yup.string().nullable(),

  emergencyContactName: yup.string().nullable(),

  emergencyContactNumber: yup.string().nullable(),

  referralSourceSubcategory: yup.string().nullable(),

  country: yup.string().nullable(),

  city: yup.string().nullable(),

  address1: yup.string().nullable(),

  membershipBenefits: yup.number().nullable(),

  imageUrl: yup.string().nullable(),

  address: yup.array().of(yup.string()).required(),

  brilliantAccNumber: yup.string().nullable(),

  aspireAccEmail: yup.string().nullable(),

  evolus: yup.string().nullable(),

  xperienceUsername: yup.string().nullable(),

  patientMembershipSubscription: yup.array().of(
    yup.object({
      id: yup.number(),
      membership_tier_id: yup.number(),
      last_invoice: yup
        .object({
          payment_status: yup.string().nullable(),
        })
        .nullable(),
      membership_tier: yup.object({
        membership_color: yup.string(),
        tier_name: yup.string(),
      }),
    }),
  ),

  accountPreferences: yup.object({
    patient_sign_up: yup.number(),
    membership_color: yup.string(),
  }),

  patientAccount: yup
    .object({
      patient_user: yup
        .object({
          id: yup.number(),
          email: yup.string(),
          status: yup.number(),
        })
        .nullable(),
    })
    .nullable(),

  allergies: yup.array().of(yup.string()).required(),

  lastProcedureDate: yup.string().nullable(),

  clinicId: yup.number().nullable(),

  patientsSkinScore: yup
    .object({
      created: yup.string(),
      id: yup.number(),
      points: yup.number(),
      skin_discription: yup.string(),
      skin_type: yup.string,
    })
    .nullable(),

  hasAllergy: yup.boolean(),

  accessPortal: yup.number(),

  doNotCall: yup.boolean().nullable(),

  doNotEmail: yup.boolean().nullable(),

  doNotSms: yup.boolean().nullable(),

  isFired: yup.boolean().nullable(),

  clinics: yup
    .array()
    .of(
      yup.object({
        clinic_name: yup.string(),
        id: yup.number(),
      }),
    )
    .nullable(),

  creditCards: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        cardNumber: yup.string().required(),
        billingZip: yup.string().nullable(),
      }),
    )
    .required(),
});

const composeResponse = (res) => {
  const client = res?.data?.data || {};
  const cards = client?.card_on_files || [];
  return {
    id: client.id,

    fullName: client.full_name,

    dateOfBirth: client.date_of_birth || null,

    evolus: client?.evolus || null,

    xperienceUsername: client?.xperience_username || null,

    emails: [
      client.email,
      client.email_2,
      client.email_3,
      client.email_4,
    ].filter(Boolean),

    phones: [
      client.phoneNumber,
      client.phoneNumber_2,
      client.phoneNumber_3,
      client.phoneNumber_4,
    ].filter(Boolean),

    pinCode: client?.pincode || null,

    canAddCreditCard: Boolean(client.can_add_card),

    documentsExpirationDate: client?.documentsExpirationDate,

    emergencyContactName: client?.emergency_contact_name || null,

    emergencyContactNumber: client?.emergency_contact_number || null,

    gender: API_GENDERS_MAP[client.gender] || null,

    creditCards: cards?.map((card) => ({
      id: card.id,
      cardNumber: card.card_number,
      billingZip: card.billing_zip || null,
    })),

    accountPreferences: client?.account_prefrences || {},

    patientMembershipSubscription:
      client?.patient_membership_subscription || [],

    accessPortal: client?.access_portal,

    referralSource: client?.referral_source || null,

    referralSourceSubcategory: client?.referral_source_subcategory || null,

    lastProcedureDate: client?.last_procedure_date || null,

    address1: client?.address_line_1 || null,

    allergies: client?.allergies?.filter(Boolean) || [],

    patientAccount: client?.patient_account || null,

    hasAllergy: Boolean(client?.is_have_allergy) || false,

    clinicId: client?.clinic_id,

    country: client?.country || null,

    city: client?.city || null,

    patientsSkinScore: client?.patients_skin_score || null,

    membershipBenefits: client?.membership_benefits_this_year || null,

    doNotCall: Boolean(client?.do_not_call),

    doNotEmail: Boolean(client?.do_not_email),

    doNotSms: Boolean(client?.do_not_sms),

    state: client?.state || null,

    brilliantAccNumber: client?.brilliant_acc_number || null,

    aspireAccEmail: client?.aspire_acc_email || null,

    address: [
      client?.address_line_1,
      client?.city,
      client?.state,
      client?.pincode,
      client?.country,
    ].filter(Boolean),

    imageUrl: client?.user_image_url || null,

    clinics: client?.clinics || null,

    isFired: Boolean(client?.is_fired),
  };
};

function createClientQueryKey({ clientId }) {
  return [QUERY_KEYS.client, clientId];
}

export function useClientQuery(payload, options = {}) {
  return useQuery(
    createClientQueryKey({
      clientId: payload?.clientId,
    }),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.getClient(req.clientId));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}

export const withClientRefetch = (Component) => (props) => {
  const client = useQueryClient();
  const refetchClientData = () => {
    client.invalidateQueries({
      queryKey: [QUERY_KEYS.client],
    });
  };

  return <Component {...props} refetchClient={() => refetchClientData()} />;
};
